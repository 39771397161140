


#o-evento{
    position: relative;


    .header-linear-mobile{
        height: 62px;
        background-image:  url('../../src/assets/imagens/o-evento/linear-mobile-evento.webp');
        background-size: cover;
        position: relative;

        display: none;

        @include d(sm){
            display: block;
        }

        .alien-mobile{
            max-width: 69px;
            position: absolute;
            right: 16px;
            bottom: -10px;
        }
        .livro-rosa{
            position: absolute;
            right: 95px;
            bottom: 0;
            max-width: 83px;
        }
    }

    .vertical-wrapper{
        position: absolute;
        height: 100%;
        left: 1rem;
        max-width: 200px;
        width: 100%;
        top: 0;
        bottom: 0;

        @include d(sm){
            display: none;
        }

        @include d(lg){
            left: -13px;
        }

        .linear{
            
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                max-width: 130px;
                margin: 0 auto;
                object-fit: cover;
            }
        }
        .vertical-items-wrapper{
            padding-top: 4.375rem;
            position: absolute;
            top: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            z-index: 3;


            .alienigena-orna{
                max-width: 105px;
                margin-left: auto;
            }
            .menina-voando{
                margin-left: auto;
            }
            .peixe-wrapper{
                max-width: 140px;
            }
            .menina-livro{
                margin-left: auto;
            }
        }
        // .vertical-item{
        //     position: absolute;
        // }
    }


    .for-space{
        @media (max-width: 1800px){
            margin-left: 200px;
        }
        @include d(lg){
            margin-left: 190px;
        }
        @include d(sm){
            margin-left: 0;
        }
    }
    .mao-wrapper{
        position: absolute;
        right: 0;
        top: 6rem; 

        @media (max-width:1800px){
            display: none;
        }
    }

    .container-o-evento{
        max-width: calc(1300px + 3rem);
        margin: 0 auto;
    }

    // .grid-for-orna{
    //     display: grid;

    //     grid-template-columns: 100px 1fr;
    // }

    .header-wrapper{
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      gap: 1rem;
      margin-bottom: 4.5rem;

      padding-top: 6rem;
      @include d(md){
        grid-template-columns: 1fr;
      }
      @include d(sm){
        padding-top: 0;
        margin-top: -9px;
      }

      .info-evento{
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.4;
            /* or 144% */

            letter-spacing: -0.02em;

            /* Neutral/70 */

            color: #36363B;

            p{
                text-align: justify;
                margin-bottom: 1rem;
            }

        }
    }
    


    .info-list-grid{

        display: grid;
        grid-template-columns: 1fr 1fr;
        
        gap: 1rem;
        padding-bottom: 8.75rem;

        @include d(md){
            grid-template-columns: 1fr;
          }

        .col-1{
            max-width: 512px;
            margin-right: 3rem;
            @include d(md){
                margin-right: 0rem;
                max-width: unset !important;
            }

            @include d(md2){
                
                max-width: unset !important;
            }
            @include d(sm){
                margin-right:0;
            }
            .title{
                font-style: normal;
                font-weight: 700;
                font-size: 2rem;
                line-height: 1.06;
                /* identical to box height, or 106% */

                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* Black */

                color: #000000;
                margin-bottom: 1rem;
            }
            .sub-title{
                text-align: justify;
                font-size: 1.125rem;
                line-height: 1.4;
                /* or 144% */

                letter-spacing: -0.02em;

                /* Neutral/70 */

                color: #36363B;
            }
            
            .info-list-wrapper{
                margin-top: 2rem;

                li{
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 1rem;
                    svg{
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;
                    }
                    p{
                      
                        font-size: 1rem;
                        line-height: 1.5;
                        /* identical to box height, or 150% */

                        letter-spacing: -0.02em;

                        /* Neutral/100 */

                        color: #0F0F12;
                    }
                }
            }
        }

        .col-2{

            @include d(md){
                grid-row-start: 1;
            }
    
            .container-img{
                position: relative;
                // max-width: 655px;
               
                margin-left: auto;
                margin-right: 25px;
                padding-left: 70px;
                @include d(sm){
                    padding-left: 0;
                    margin-right: 0;
                }

                .gatinho-orna{
                    position: absolute;
                    // left: -95px;
                    left: -27px;
                    // transform: rotate(331deg);
                    @include d(lg){
                        transform: scale(0.7);
                        transform-origin: top;
                    }
                    @include d(sm){
                        transform: rotate(30deg);
                        top: -32px;
                        left: 5px;
                        img{
                            max-width: 47px;
                        }
                    }
                }
                .orna-chaves{
                    position: absolute;

                    z-index: 3;
                    right: -26px;
                    bottom: 4.375rem;
                    @include d(lg){
                        transform: scale(0.7);
                        transform-origin: center;
                    }

                    @include d(sm){
                        bottom: 1rem;
                        right: 0;
                        transform: scale(0.6);
                        transform-origin: right bottom;
                    }
                }
                
            }
            .img-wrapper{
                position: relative;
                z-index: 2;
                background-image: url('../assets/imagens/o-evento/linear-o-evento-img.webp') ; 
                background-repeat: no-repeat;
                // background-size: cover;
                background-size: calc(100% - 55px);
                padding: 1rem 0 1rem 1rem;
                img{ 
                  width: 100%;
                }
            }

        }
        .sub-title-2{
            grid-column: span 2;
            text-align: justify;
            font-size: 1.125rem;
            line-height: 1.4;
            /* or 144% */

            letter-spacing: -0.02em;

            /* Neutral/70 */

            color: #36363B;
            @include d(md){
                grid-column: 1;
                
            }
        }
    }
}
   

#numeros{
    background: #FEF3E9;
    border-top: 1px solid  rgba(15, 15, 18, 0.4);;
    border-bottom: 1px solid  rgba(15, 15, 18, 0.4);;

    // margin-bottom: 1rem;

    .swiper-wrapper{
        transition-timing-function: linear;
    }
    .swiper-slide{
        max-width: max-content;
    }
    .numero-item{
        display: flex;
        align-items: center;
        padding: 2.5rem;
        border-right: 1px solid  rgba(15, 15, 18, 0.4);

        @include d(md2){
            padding: 2rem 1.5rem;
        }
        .badge-cor{
            font-style: normal;
            font-weight: 600;
            font-size: 3.5rem;
            line-height: 1.14;
            /* identical to box height, or 114% */

            letter-spacing: -0.02em;
            text-transform: uppercase;
            /* White */
            padding: 4px 2rem;
            border-radius: 36px;
            @include d(md2){
                font-size: 2rem;  
            }

            &-1{
                background-color: $bienal-rosa;
                color: #fff;
            }
            &-2{
                background-color: $bienal-amarelo;
                color: #000;
            }
            &-3{
                background-color: #56C5D0;
                color: #000;
            }
            &-4{
                background-color: #2E3192;
                color: #fff;
            }
            &-5{
                background-color: #F58220;
                color: #fff;
            }
        }

        p{
            font-weight: 500;
            font-size: 2rem;
            line-height: 1.06;
            /* identical to box height, or 106% */
            margin-left: 1rem;
            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/100 */ 
 
            color: #0C1428;
            // min-width: max-content;
       
            @include d(md2){
                font-size: 1.5rem;
            }
        }
    }
}



#cta-programacao{

    padding: 10rem 0;

    @include d(md2){
        padding: 4rem 0;
    }

    .container{
        max-width:calc(1328px + 3rem);
        position: relative;
    }

    .flor-dali{
        position: absolute;
        left: -80px;
        top: -10px;
        @include d(md2){
            display: none;
        }
        img{
            max-width: 68px;
        }
    }

    .title{
        font-style: normal;
        font-weight: 800;
        font-size: 3.5rem;
        line-height: 1.14;
        /* or 114% */

        letter-spacing: -0.02em;
        text-transform: uppercase;

        /* Black */

        color: #000000;
        margin-bottom: 3.5rem;

        @include d(md){
            font-size: 3rem ;
        }
        @include d(sm){
            font-size: 20px ;
        }
    }

    .buttons-cta{

        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        position: relative;
        @include d(md){
            grid-template-columns: 1fr;
        }
        .gatinho-orna{
            position: absolute;
            right: 0;
            top: -65px;

            @include d(md){
                display: none;
            }
            img{
                max-width: 73px;
            }
        }


        .button-wrapper{
            position: relative;
            z-index: 2;
            .button{
                background: #FBFCE9;
                padding: 1.5rem;
                border: 1px solid #0C1428;
                display: flex;
                align-items: center;
                justify-content: space-between;


                font-weight: 600;
                font-size: 1.5rem;
                line-height: 1.33;
                /* identical to box height, or 133% */

           
                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* Black */

                color: #000000;

                @include d(sm){
                    font-size: 16px ;
                }

                

                .icon-wrapper{
                    background-color: $bienal-rosa;
                    border-radius: 50%;
                    width: 3.5rem;
                    height: 3.5rem;
                    display: grid;
                    place-content: center;
                    @include d(sm){
                        width: 32px;
                        height: 32px;
                    }
                    
                    svg{
                        width: 24px;       
                        height: 24px;
                        filter: brightness(0) invert(1);
                        
                    }
                }
            }
        }
    }
}


#faq{

    position: relative;
    color: #fff;
    margin-bottom: 3.25rem;
    .grid-faq{
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media (max-width:1200px){
          display: block;
        }
    }

    .col-1{
        position: relative;
        z-index: 2;
        padding-top: 120px;
        padding-left: 6vw;
        min-height: 710px;
        @media (max-width:1200px){
             min-height: unset;
             padding-bottom: 150px;
             padding-right: 1.5rem;
             padding-top: 64px;
        }
        .img-wrapper-mulher{
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            max-width: 50vw;
            width: 100%;

            @media (max-width:1200px){
             max-width: 100%;
             
            }
    
            img{
                max-width: 50vw;
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: bottom right;
             

                @media (max-width:1200px){
                    max-width: 100%;
                    object-position: bottom right;
                   }
            }
        }

        .section-title {
            max-width: 511px;
            position: relative;
            margin-right: 4.375rem;
            @media (max-width:1200px){
                margin-right: 0;
              }

            .alien-orna{
                position: absolute;
                top: 0;
                right: -75px;
                @include d(md){
                    display: none;
                }
            }
            h2{
                color: #fff;
                max-width: 15ch;
            }
            .section-description{
                color: #fff;
                margin-top: 2rem;
                margin-bottom: 1.5rem;
            }
            a{
                color: #fff;
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 1.33;
                /* identical to box height, or 133% */

                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* White */

                color: #FFFFFF;

                @include d(md2){
                    font-size: 1.25rem;
                }
            }

            .button{
             
                    @include BotaoMain(#D6DF21);
            
                    svg{
                        height: 24px;
                        width: 24px;
                        margin-right: 0;
                        margin-left: 5px;
                    }
                    // @include BotaoTeste(red, blue);
                    // 
                    
              
            }
        }
    }

    .col-2{
        position: relative;
        background-color: $bienal-rosa;
        padding-top: 120px;
        padding-left: 7.5rem;
        padding-right: 1.5rem;

        @media (min-width: 1800px){
            padding-right: 7.5rem;
           
        }
        @media (max-width:1200px){
            padding-left: 6vw;
            padding-top: 64px;
            padding-bottom:64px;
        }
        .robo-wrapper-faq{
            position: absolute;
            top: 0;
            // left: -32px;;
            left: -72px;

            @media (max-width:1200px){
                top: -100px;
                z-index: 3;
                max-width: 105px;
                top: -55px;
                left: -15px;
            }

            img{
                transform: rotate(-1deg);
            }
        }
        .content-faq{
            max-width: 697px;
        }

        .section-title{
            position: relative;
            overflow: hidden;
            margin-bottom: 6.25rem;
            @media (max-width:1200px){
                margin-bottom: 2rem;
            }
            
            &::after{
                content: '';
                width: 100%;
                height: 1px;
                background-color:  rgba(255, 255, 255, 0.2);
                display: block;
                position: absolute;
                left: 129px;
                right: 0;
                top: 50%;
            }
            .badge-wrapper{
                margin-bottom: 0;
            }
        }

       

        .item-faq{
            border: 1px solid #FFFFFF;
            border-radius: 3px;
            padding: 1rem;
            margin-bottom: 1rem;
            position: relative;
            overflow: hidden;
            cursor: pointer;

                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 4px;
                    background-color: $bienal-amarelo;
                    width: 0;
                    transition: width 0.2s ease-out;
                    transition-delay: 0.1s;
                }

            .header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;

                .titulo{
                    font-weight: 700;
                    font-size: 1.125rem;
                    line-height: 1.4;
                    /* identical to box height, or 144% */

                    letter-spacing: -0.02em;
                    text-transform: uppercase;

                    /* white */

                    color: #FFFFFF;
                }

                .icon-wrapper{
                    background-color: $bienal-amarelo;
                    width: 40px;
                    height: 40px;
                    display: grid;
                    place-content: center;
                    border-radius: 50%;
                    flex-shrink: 0;

                    svg{
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }
            }

            .faq-content{
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 1.44;
                /* identical to box height, or 144% */

                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* White */

                color: #FFFFFF;

                

                -webkit-transition: max-height 0.3s ease-in-out; 
                -moz-transition: max-height 0.3s ease-in-out; 
                -ms-transition: max-height 0.3s ease-in-out; 
                -o-transition: max-height 0.3s ease-in-out; 
            
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.3s ease-in-out;  
            }

            &.active{

                .faq-content{
                    max-height: 300px;
                    padding-top: 11px;
                 
                }
                .icon-wrapper{
                    transform: rotate(180deg);
                    transition: 0.2s ease;
                }

                &::after{
                    width: 100%;
                }
            }
        }
    }
    
    
}

#banner-patrocinio{


    .balao-wrapper{
        left: 1.5rem;
        right: unset;
    }
}