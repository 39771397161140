#modal-certificado {
  position: fixed;
  z-index: 9999;
  background-color: #fff;
  color: #1b1d21;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 625px;
  max-width: 100%;

  max-height: 420px;
  height: 100%;
  border-radius: 6px;
  // overflow: hidden;

  // @media (min-width: 1024px) {
  //   max-height: 98%;
  // }

  @include d(dt){
    max-height: 470px;
  }

  &.closed {
    display: none;
  }

  .modal-header {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    margin-top: -1px;
    border-radius: 3px 3px 0 0;
  }
  #close-button {
    background: #fff;
    height: 56px;
    padding: 1.5rem;
    border-radius: 3px;
    border: 1px solid rgba(27, 29, 33, 0.10);
    cursor: pointer;
    @include d(md2) {
      margin-right: 0;
    }
    svg {
      filter: brightness(0) invert(1);
    }
  }

  .titulo {
    color: #0d4da1;
    font-size: 1.5rem;
    font-family: Causten;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.24px;
    text-transform: uppercase;
    margin-bottom: 2rem;

    max-width: 36ch;
  }
  .texto-politica {
    font-size: 14px;
    margin-bottom: 2.5rem;
    margin-top: -1rem;
  }

  .modal-guts {
    /* other stuff we already covered */

    /* cover the modal */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /* spacing as needed */
    // padding: 45px 50px 20px 0;
    padding: 2rem;

    /* let it scroll */
    overflow: auto;
  }
}

#modal-overlay {
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  &.closed {
    display: none;
  }
}

// STYLE
#modal-certificado {
  .header-wrapper-modal {
    display: flex;
    align-items: flex-start;
    gap: 2rem;

    @include d(md2) {
      flex-direction: column-reverse;
    }
  }

  .modal-guts {
    @include d(md2) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}

/* Webkit based browsers (Chrome, Safari) */
.scroll-box::-webkit-scrollbar {
  width: 8px;
}

.scroll-box::-webkit-scrollbar-track {
  background-color: #55555574;
}

.scroll-box::-webkit-scrollbar-thumb {
  background-color: #0d4691;
}

.scroll-box::-webkit-scrollbar-thumb:hover {
  background-color: #55555574;
}

/* Firefox */
.scroll-box {
  scrollbar-width: thin;
  scrollbar-color: #0d4691 #55555574;
}

.scroll-box::-moz-scrollbar {
  width: 8px;
}

.scroll-box::-moz-scrollbar-track {
  background-color: #55555574;
}

.scroll-box::-moz-scrollbar-thumb {
  background-color: #0d4691;
}

.scroll-box::-moz-scrollbar-thumb:hover {
  background-color: #55555574;
}
