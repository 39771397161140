
.floating-button{
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    z-index: 99;
    @include d(md2){
        bottom: 1rem
    }

    &.button-wrapper{ 
        // @include d(md){
        //     display: none;
        // }
        .button{
            @include BotaoMain(#3CC5B1);
        }
    } 
 
}


#footer-bienal{
   
  background-color: #EEEEF0;
  border-top: 1px solid rgba(27, 29, 33, 0.10);




    .footer-wrapper{
        padding-top: 2.375rem;
        margin-bottom: 2rem;


        .container{
            
            &::after{
                content: '';
                width: 200vw;
                height: 1px;
                background-color: rgba(27, 29, 33, 0.10);
                position: absolute;
                bottom: 46px;
                left: -50vw;
                @include d(md){
                    display: none;
                }
            }
        }
        .grid-footer{
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                gap: 1rem;

            @media (max-width: 1400px){
                grid-template-columns: 1fr;

                .col-1{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }

                @include d(md){
                    grid-template-columns: 1fr;
                    .col-1{
                        display: block;
                    }
                }
        }

        .logo-wrapper{
            max-width: 350px;
            padding-bottom: 2rem;
            @media (max-width:1500px){
                max-width: 200px;
            }
        }
    }


    .links-footer{
        display: flex;
        
        align-items: center;
        justify-content: flex-end;
        gap: 2rem;
        margin-bottom: 4rem;

        @media (max-width: 1400px){
            justify-content: space-between;
        }

        @include d(md){
            display: block;
        }
        ul{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-end;

            @include d(md){
                // justify-content: space-between;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 2rem;
            }
            li+li{
                margin-left: 1.5rem;
                @include d(md){
                    margin-left: 0;
                }
            }

            li{

                &:hover{
                    text-decoration: underline;
                }

                a{
                    color:  #1B1D21;
                    text-align: center;
                    font-size: 16px;
                    font-family: Causten;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: -0.16px;
                }
            }
        }
        .button-wrapper{
            flex-shrink: 0;
            @include d(md){
                margin-top: 2rem
            }

          
            .button{
                @include BotaoMain(#297B6F);

                // @include BotaoTeste(red, blue);
                // 
                &.secondary{
                    background-color: transparent;
                    border: 1px solid #297B6F;
                    color: #297B6F;
                   
                    @include d(md2){
                
                        max-width: unset;
                        width: 100%;
                        justify-content: center;
                        margin-top: 2rem;
                  
                    }
                    span{ 
                        color:#297B6F;
                    }
                }
                
            }
        }
    }

    .socials{
        @include d(md2){
            
        }
        ul{
            display: flex;
            align-items: center;
            gap: 9px;
            
            li{
                transition: 0.2s ease-in;
            }
            li:hover{
                transform: scale(1.05) ;
            }
            a{
                padding: 2px;
                display: block;
            }

            svg{
                width: 2rem;
                height: 2rem;
                color: #3B9C8E;
                @include d(md2){
                    width: 2.5rem;
                    height: 2.5rem;
                    
                }
            }
        }

        
    }



    .swiper-parceiros,
    .swiper-parceiros2,
    .swiper-parceiros3{

        .swiper-wrapper{
            transition-timing-function: linear;

            .swiper-slide{
                height: auto;
            }
        }
    }
    .parceiros{
        // margin-bottom: 5rem;
        
        .section-title{
            margin-bottom: 2.5rem;
            h2{
                text-align: center;
                font-size: 2rem;
            }
        }


        .swiper-slide {
            max-width: max-content;
        }

        .parceiro{
            height: 100%;
            background-color: #fff;
            border-radius: 8px;
            border: 1px solid rgba(27, 29, 33, 0.10);
            padding: 12px 2rem;
            max-width: max-content ;
            min-width: 240px;
            display: grid;
            place-content: center;
            img{
                max-height: 48px;
                margin: 0 auto;
            }
        }
    }

    .bottom-footer{
        padding: 1.2rem 0;
        color: #fff;


        background: linear-gradient(-90deg, #0D4DA1 0%, #47BAA9 100%);
       
        .container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            
            row-gap: 5px;
            column-gap: 1.5rem;
        }


        p{
            font-weight: 700;
            font-size: 1.125rem;
            line-height: 1.5;

            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: #FFFFFF;
        }
           
           

            .ndt-wrapper{
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 400;
                font-size: 12px;
                line-height: 1.5;

                letter-spacing: -0.02em;
                text-transform: uppercase;

                color: #FFFFFF;
                img{
                    padding-bottom: 7px;
                }
            }
    }


    .termos-footer{
        a{
            color: #fff;
        }
    }

}
