
@media (min-width:1024px){
    &{
        overflow: unset;
    
        main{
            overflow: unset;    
        }
        .side-bar{
            position: sticky;
            top: 140px;
        }
    }
}

 

#base-banner{


    .detail-bottom{
        background-color: #A3238E;
    }
}


#informacoes{
    padding: 5.375rem 0;
    position: relative;



    .all-ornas-wrapper{
        position: absolute;
        top: 490px;
        // bottom: 0;
        display: flex;
        flex-direction: column;
        gap: 12.5rem;

        @include d(md) {
            display: none;
        }
       

        .img-orna-1{
            max-width: 204px;
        }
        @media (max-width: 1700px) {
            
            img{
                transform: scale(0.8);
                transform-origin: left;
            }
        }
    }

    .hand-orna-right{
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: row-reverse;

        @media (max-width: 1700px){
            transform: scale3d(0.78, 0.78,0.78);
            transform-origin: right;

            right: -30px;
        }
        @media (max-width: 1440px){
            display: none;
        }

        .hand-orna-img{
            padding-bottom: 3rem;
        }
        .alien-orna-hand{
            max-width: 108px;
            align-self: flex-end;
            // position: relative;
            // right: 40px;
        }
    }

    .grid-wrapper{
        display: grid;
        grid-template-columns: 374px 1fr;
        gap: 10rem;
        align-items: flex-start;
        @media (max-width: 1700px){
            gap: 6rem;
        }
        @include d(lg){
            grid-template-columns: 274px 1fr;
            gap: 4rem;
        }
        @include d(md){
           grid-template-columns: 1fr;
        }
    }

    .side-bar{
        background: #FFFFFF;
        border: 1px solid rgba(15, 15, 18, 0.05);
        padding: 1rem;

        @include d(md){
            display: none;
        }

        .destaque{
            margin-bottom: 1.5rem;
        }
        h2{
            font-size: 1rem;
            line-height: 1.5;
            /* identical to box height, or 150% */

          
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: rgba(15, 15, 18, 0.5);
            cursor: pointer;
            margin-bottom: 1.5rem;
        }

        h2.active{
            color: #0F0F12;
        }
        h2:last-child{
            margin-bottom: 0;
        }
       
    }



   
    .editor{
        max-width: 784px;
        // margin-left: auto;

        h2{

            font-weight: 700;
            font-size: 1.375rem;
            line-height: 136%;
            /* identical to box height, or 136% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/100 */

            color: #0F0F12;
            margin-bottom: 1rem;

            @include d(md2){
            font-size: 1.5rem;
            }
        }
        p{
            margin-bottom: 1rem;

            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.44;
            /* or 144% */

            letter-spacing: -0.02em;

            /* Neutral/70 */

            color: #36363B;
        }
        
        li{
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 144%;
            /* identical to box height, or 144% */

            letter-spacing: -0.02em;

            /* Neutral/70 */

            color: #36363B;


            list-style: disc;
            color: #36363B;
            margin-left: 2rem;
            margin-bottom: .75rem;
            
        }

    }
    .block-item{
        scroll-margin-top: 140px;
        margin-bottom: 2.5rem;
    }

    p.destaque{
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 144%;
        /* identical to box height, or 144% */

        letter-spacing: -0.02em;
        text-transform: uppercase;

        /* Primary/Fucsia */

        color: #A3238E;
    }

    .ingresso-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.5rem;

        @include d(md2){
            grid-template-columns: 1fr;
        }
    }

    .ingresso-item{
        // background-color: #A3238E;
        background-image: url('../../src/assets/imagens/info-visitas/price-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        max-width: 380px;
        min-height: 90px;
        border-radius: 3px 0 0 3px;
        padding: 12px;
        padding-right: 80px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .info{
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            /* identical to box height, or 150% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* White */

            color: #FFFFFF;

        }
        .preco{
            font-weight: 800;
            font-size: 1.5rem;
            line-height: 133%;
            /* identical to box height, or 133% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Primary/Verde */

            color: #D6DF21;
        }
    }
 

    .table-container{
        border-radius: 3px ;
        overflow: hidden;
        margin-bottom: 1.5rem;
        .table-head{
            background-color: #A3238E;
            padding: .875rem 1.5rem;
            
            .head-title{
                color: #fff;
                font-weight: 700;
                font-size: 18px;
                line-height: 144%;
                /* identical to box height, or 144% */
                letter-spacing: -0.02em;
                text-transform: uppercase;
                margin-bottom: 0;

                color: #FFFFFF;
            }

           
        }
        .table-body{
            background-color: #ffff;
            border: 1px solid rgba(15, 15, 18, 0.05);
            border-bottom: none;

            .row{
                display: flex;
                justify-content: space-between;
                padding: 1rem 24px;
                border-bottom: 1px solid rgba(15, 15, 18, 0.05);
                p{
                    margin-bottom: 0;
                }

                .label{
                    font-weight: 500;
                    font-size: 1rem;
                    line-height: 150%;
                    /* identical to box height, or 150% */

                    letter-spacing: -0.02em;
                    text-transform: uppercase;

                    /* Neutral/100 */

                    color: #0F0F12;
                }
                .preco{
                    font-weight: 700;
                    font-size: 1rem;
                    line-height: 150%;
                    /* identical to box height, or 150% */

                    letter-spacing: -0.02em;
                    text-transform: uppercase;

                    /* Primary/Fucsia */

                    color: #A3238E;
                }
            }
        }
    }

  

}