#base-banner{
    position: relative;
    height: 480px;
    // margin-top: 129px;

    // @media(max-width:1400px){
    //     margin-top: 123px;
       
    // }
    // @media (max-width:1024px){
    //     // margin-top: 72px;
    //     margin-top: 115px;
    // }

    &::after{
        content: '';
        position: absolute;
        inset: 0;
        
        background: linear-gradient(91deg, #2E3192 0%, rgba(46, 49, 146, 0.84) 29.57%, rgba(217, 217, 217, 0.00) 100%);

        @include d(md2){
            background: linear-gradient(359deg, #2e3192 0%, #2e3192d6 42.57%, #d9d9d900 100%);
        }
    }

    .banner-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .banner-wrapper{
        height: 100%;
        position: absolute;
        inset: 0;
    }
    // background-image: url('../../src/assets/imagens/blog/background-midia.webp');
    // background-image: url('../../src/assets/imagens/blog/banner-full-blog.webp');
    // background-repeat: repeat;
    // background-size: cover;

    // min-height: 444px;

    // position: relative;

    // margin-top: 160px;
    // display: flex;
    // flex-direction: column;

    .container{
        flex: 1;
        height: 100%;
        display: grid;
        position: relative;
    }
    .banner-content{

        position: absolute;
        // top: 50%;
        // transform: translateY(-50%);
        left: 1.5rem;
        right: 1.5rem;
        z-index: 99;
        // bottom: 5rem;
        @include clamp('bottom', 3rem , 4vw, 5rem);


        .section-title{

            .badge{
                color: #fff;
            }
            h1{
            
         

            

                @include clamp('font-size', 3rem , 5vw, 5rem);
                font-style: normal;
                font-weight: 700;
                line-height: 1.2; /* 120% */
                letter-spacing: -0.8px;
                text-transform: uppercase;
                color: #fff;
            }
        }
       
    }
}    

