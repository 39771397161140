.input-group{
    margin-bottom: 1rem;

    label{
       margin-bottom: 4px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.32px;
    }
    input{
        height: 56px;
    }
    input,
    textarea{
        width: 100%;

        background: #FFFFFF;
        /* Neutral/10% OP

        0F0F12
        */
        border: 1px solid rgba(27, 29, 33, 0.10);
        border-radius: 3px;
        padding: 8px 14px;

        &:focus{
            border-color: #000;
        }

        &::placeholder{
            color: #525C64;
           
        }
    }
    textarea{
        resize: none;
    }
}

.input-group-2-col{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    // @include d(md2){
    //     grid-template-columns: 1fr;
    // }
}

form{

    .button-wrapper{
        // transition: 0.2s ease-in;
        // &:hover{
        //     transform: scale(1.02);
           
        // }
        .button{
            @include BotaoMain(#3CC5B1);     
        }
        .button{
         
                width: 100%;
                justify-content: center;
                text-align: center;
         
                max-width: unset; 

                span{   
                    font-size: 16px;
                }
        }

    }
}