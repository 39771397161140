#palestrantes{
    padding:  6rem 0;

    .grid-palestrantes{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;
        @media (max-width: 1440px){
            grid-template-columns: repeat(3, 1fr);
        }
        @include d(md){
            grid-template-columns: 1fr 1fr;
        }
        @include d(md2){
            grid-template-columns: 1fr;
        }
    }

}


.pagination{
 margin-top: 2rem;

    .page-list{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .75rem;

        a{
            padding: .375rem .875rem;
            border: 1px solid rgba(15, 15, 18, 0.1);
            border-radius: 3px;
            display: block;
        }
    }

    li{
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 3px;
        /* identical to box height, or 150% */

   
        letter-spacing: -0.02em;
        text-transform: uppercase;

        color: #000000;

        &.current{
            background-color: #3B9C8E;
            a{
                color: #fff;

            }
        }
    }

    .on-edges{
        
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5;
        /* identical to box height, or 150% */

        text-align: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;

  
        // background-color: $bienal-amarelo;
        /* Black */

        color: #7B7C7F;
        &.left{
                svg{
                    transform: rotate(180deg);
                    // margin-right: 8px;
                    align-self: flex-start;
                }
        }
        &.right{
            svg{
                // margin-left: 8px;
                align-self: flex-start;
            }

        }
       
        a{
            display: flex;
            align-items: center;
        }
        svg{
            width: 20px;
            height: 20px;

        }
    }
}


