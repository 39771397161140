.accordion {
    display: flex;
    flex-direction: column;
    // gap: 8px;

    & + .accordion {
        margin-top: -1px;
    }

    &-item {
        border-radius: 3px;
        // background: var(--white);
        border-bottom: 1px solid rgba(27, 29, 33, 0.10);
        &.active {
            border-color: #3B9C8E;
            .icon-right .bg-icon-active,
            .icon-left .bg-icon-active{
                background: rgba(59, 156, 142, 0.10);
            }
            svg {
                transform: rotate(180deg);
            }
        }
    }
    svg {
        width: 24px;
        height: 24px;
        transform: rotate(0deg);
        transition: transform 0.3s ease-in-out;
    }

    &-title {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 96px;
        color: #1B1D21;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.02em;
        padding: 16px;
        position: relative;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        @include d(sm){
            font-size: 16px;
        }
        &:hover {
            background-color: #f0f0f0;
        }
        &.icon-left {
            padding-left: 2.5rem;
            .bg-icon-active {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 16px;
                margin: auto 0;
                display: grid;
                width: 40px;
                height: 40px;
                border-radius: 28px;
                place-items: center;
                background: transparent;
            }
        }
        &.icon-right {
            padding-right: 2.5rem;
            .bg-icon-active {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 16px;
                margin: auto 0;
                display: grid;
                width: 40px;
                height: 40px;
                border-radius: 28px;
                place-items: center;
                background: transparent;
            }
        }
    }

    &-content {
        overflow: hidden;
        height: 0;
        max-width: 100%;
        &.active {
            height: auto;
        }
    }

    .accordion-container {
        color: #3B4042;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: -0.03em;
        padding: 16px;
        padding-top: 8px;
    }
}