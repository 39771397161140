#contato-rc{
    // background-color: #fff;

    .section-title{
        margin-bottom: 3.5rem ;
        h2{
            font-size: 5rem;
            @include d(md2){
                font-size: 2.5rem;
            }
        }
        .description{
            margin-top: 12px;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5;
            letter-spacing: -0.02em;

            /* Neutral/70 */
            color: #3B4042;
            max-width: 54ch;
        }
    }

    
    .info-contato-container{
        margin-top: 2rem;
        .block-wrapper-contato{
            margin-bottom: 1.125rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            .block-title{
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 1.5;
                /* identical to box height, or 150% */            
                letter-spacing: -0.02em;
                text-transform: uppercase;
                         
                background: linear-gradient(89deg, #0D4DA1 0%, #42B6A7 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
    
            .block-info{
                // container
                display: flex;
                padding: 1rem;
                border-radius: 3px;
                width: 100%;
                justify-content: space-between;
                border: 1px solid rgba(27, 29, 33, 0.05);
                transition: all .2s ease-in;

                // text
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 1.3;
                letter-spacing: -0.02em;
                color: #0D4DA1;

                svg{
                    width: 24px;
                    height: 24px;
                    color: #1B1D21;
                    opacity: .4;
                }

                &:hover{
                    border-color: #3cc5b1;
                    transform: scale(1.05);
                }
            }
        }
    }


    .grid-wrapper{

        display: grid;
        grid-template-columns: 1fr 1fr;
        @include d(md){
            grid-template-columns: 1fr;
        }
    }


    .col-1{
        max-width: 650px;
        padding: 120px 0;
        margin-right: 2rem;
        @include d(md){
            padding: 5rem 0;
        }
      
    }


    .col-2{
            position: relative;
            padding: 120px 0;

            display: flex;
            flex-direction: column;
            justify-content: center;

            @include d(sm){
                padding: 1.5rem 0;
            }

            &::before{
                content: '';
                position: absolute;
                
                // background-image: url('../../src/assets/imagens/contato/bg-contato.webp');
                background-image: url('../../src/assets/imagens-rio/contato/bg-contato-new.webp');
                background-position: 25%;
                background-repeat: no-repeat;
                background-size: cover;

                width: 40vw;
                height: 100%;
                top: 0;
                left: 185px;
                bottom: 0;
                @include d(md){
                    width: 120vw;
                    left: -20vw;
                }
            }


        .form-wrapper{
            width: 100%;
            max-width: 445px; 
            position: relative;
            z-index: 2;

            background: #FFFFFF;
            border-radius: 6px;
            padding: 3rem 2.5rem;
            border: 1px solid rgba(15, 15, 18, 0.1);
            box-shadow: 0px 8px 32px 0px rgba(210, 216, 223, 0.08);

            @include d(sm){
                padding: 2.5rem 2rem;
            }
            
            .form-title{
                font-weight: 400;
                font-size: 24px;
                line-height: 1.33;
                margin-bottom: 2.5rem;

                letter-spacing: -0.02em;
                // text-transform: uppercase;

                color: #0D4DA1;
            }
        }
    }
}


#programacao-rolling{
    background-color: #F7F8F9;
    border-top: 1px solid rgba(27, 29, 33, 0.10);
    border-bottom: 1px solid rgba(27, 29, 33, 0.10);

    .swiper-infinite-programacao{
        .swiper-wrapper{
            transition-timing-function: linear;
        }
        .swiper-slide{
            width: max-content;
            max-width: 100%;
            min-width: max-content;
        }
    }
    .item-infinite{
        display: flex;
        align-items: center;
        min-width: max-content;
        h2{
            font-weight: 700;
            font-size: 5rem;
            line-height: 1.2;
            letter-spacing: -0.03em;
            text-transform: uppercase;
            /* Neutral/30 */
            color: rgba(27, 29, 33, 0.10);
            min-width: max-content;

            @include d(lg){
                font-size: 5rem;
            }
            @include d(md){
                font-size: 3rem;
            }
        }

        .dot-right{
            width: 1rem;
            height: 1rem;
            min-width: 1rem;
            min-height: 1rem;
            background-color: #E3E3E5;
            border-radius: 50%;
            margin-left: 64px;
            @include d(md){
                margin-left: 20px;
            }
        }
    }

}

.ornamentos-faq{
    position: relative;
    overflow: hidden;
    &::after{
        content: '';
        position: absolute;
        display: block;
        width: 814px;
        height: 814px;
        border-radius: 50%;
        background: radial-gradient(68.50% 68.50% at 42.00% 39.06%, rgba(60, 197, 177, 0.20) 0%, rgba(60, 197, 177, 0.00) 100%);
        filter: blur(112px);
        top: -10vh;
        left: -20vw;
        z-index: -1;
        @include d(dt){
            display: none;
        }
    }

    &::before{
        content: '';
        position: absolute;
        display: block;
        width: 814px;
        height: 814px;
        border-radius: 50%;
        background: radial-gradient(68.50% 68.50% at 42.00% 39.06%, rgba(60, 197, 177, 0.20) 0%, rgba(60, 197, 177, 0.00) 100%);
        filter: blur(112px);
        bottom: 0;
        right: -20vw;
        z-index: -1;
        @include d(dt){
            display: none;
        }
    }
}

#faq-rc{
    padding: 6rem 0;
    max-width: 1056px;
    margin: 0 auto;
    position: relative;
}

