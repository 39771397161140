@import "../sass/variaveis";
@import "../sass/utilities/reset";

@import "../sass/mixins"; //MIXINS
@import "../sass/utilities/animation";


//components
@import "../sass/components/body"; //BODY
@import "../sass/components/menu/menu";
@import "../sass/components/footer";
@import "../sass/components/login";
@import "../sass/components/accordion";
@import "../sass/components/card_evento";
@import "../sass/components/banner-content";
@import "../sass/components/instagram-feed";
@import "../sass/components/banner-patrocinadores.scss";

@import "../sass/components/modal-atracoes";
@import "../sass/components/modal-certificado";
@import "../sass/components/richTextEditorjs";


//rio construcao
@import "../sass/components/card-palestrante";

// STYLE SALEBRAND
@import "../sass/sections/salebrand/salebrand_style";


@import "../sass/components/section-title";
@import "../sass/components/input-group";
@import "../sass/components/data-evento";

// PÁGINAS
@import "pages/coming_soon"; 
  
// html[lang="pt-br"],
// html[lang="auto"]{
//   [data-btn="PT"]{
//     display: flex !important;
//   }
// }

// html[lang="en"]{
//   [data-btn="EN"]{
//     display: flex !important;
//   }
// }

// html[lang="es"]{
//   [data-btn="ES"]{
//     display: flex !important;
//   }
// }

main {
  overflow: hidden;
}

.body_home,
.body_edicao-2023 { 
  @import "pages/home.scss";
}

.body_blog{
  @import "pages/blog.scss";
}

.body_single_blog{
  @import "pages/single-blog.scss";
} 

.body_eventos{
  @import "pages/eventos";
}

.body-o-evento{
  @import "pages/o-evento";
}

.body_quemsomos{
  @import "pages/quem-somos.scss";
}

.body_programacao{
  @import "pages/programacao";
}


.body_infos{
  @import "pages/infos";
}

.body_contato{
  @import "pages/contato";
}
.body_credenciamento{
  @import "pages/credenciamento";
}

.body_espacos{
  @import "pages/espaco";
}

.body_servicos{
  @import "pages/servicos";
}

.body_expositores{
  @import "pages/expositores";
}

// GLOBAL 


.dot-contato{
  width: 8px;
  height: 8px;
  background-color: #a3238e;
  display: inline-block; 
  border-radius: 5px;
  margin-right: 2px;
}

.body-coming-soon{


  #footer-bienal{
    display: none;
  }
}
.menu-space-top{
  // margin-top: 129px;
  margin-top: 83px;

  @media(max-width:1520px){
    margin-top: 121px;
  }
  @media (max-width:1024px){
    // margin-top: 113px;  
    margin-top:77px;
  }
}

 
// comment buttons  TO UNDO

.button-wrapper.floating-button {
  display: none;
}
// #header .button-wrapper a.button,
// #inscricao 
#inscricao {
  // display: block !important;
}

.card-palestrante-wrapper .bottom-infos .role.text-lower{
  text-transform: initial;
}

.card-palestrante-wrapper .card-palestrante .infos-wrapper{
  display: none;
}
#inovacao{
  scroll-margin-top: 120px;
  padding-top: 5rem;
}

.turismo-agencia{
  span{
    font-weight: 600; 
  }
   a{
    color: #3b4042 ;
   }
}

#gt_float_wrapper{
  position: static !important;
} 
body .gt_float_switcher img{
  width: 16px;
}
body .gt_float_switcher{
  font-size: 14px;
}
body .gt_float_switcher .gt-selected .gt-current-lang span.gt_float_switcher-arrow{
  height: 22px;
  width: 13px;
}
body  .gt_float_switcher .gt-selected .gt-current-lang{
  padding: 4px 11px;
}

body .flags-mobile{
  display: none !important; 
  @include d(md){
    display: block !important;
    margin-top: 1rem;
    margin-left: 1rem;
  }
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc,
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf{
  display: none !important;
}


.glink.nturl.notranslate{
   img{
    opacity: 0.5;
   }
}
.glink.nturl.notranslate.gt-current-lang img{
 opacity: 1;
}



// O EVENTO  
.body_home #evento .grid-wrapper .section-title h2 {
 @include d(md){
  margin-bottom: 0rem;
 }
}

.scale-1-1{
  img{
    transform: scale(1.1);
    transform-origin: right;
  }
}



// ATRACAO AJUSTE 
.descricao-new{
  margin-top: 1rem;
}
.richText-info{
  font-size: 1.125rem;
  letter-spacing: -0.18px;
  color: #1B1D21;
  line-height: 1.6;
}

.richText-info{
 

  .ql-align-center{
    text-align: center;
  } 

}

.swiper-parceiros,
.swiper-parceiros2,
.swiper-parceiros3{

    .swiper-wrapper{
        transition-timing-function: linear;

        .swiper-slide{
            height: auto;
        }
    }
}