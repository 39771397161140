
#videos-instagram{

    .limit-video-width{
        max-width: 900px;
        margin: 0 auto 0;
        @media (max-width: 1440px){

        }
    }
    .aspect-video-custom{
        position: relative;
        // padding-top: 156%;
        padding-top: 56%;
        height: 0;
        overflow: hidden;
        > *{
            position: absolute;
            inset: 0;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
    .relative-video-9-16{
        position: relative;
        // padding-top: 156%;
        padding-top: 177.78%;
        height: 0;
        overflow: hidden;
        > *{
            position: absolute;
            inset: 0;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
}


.custom-limit-height{
    @media (min-width: 1024px ) and (max-height: 600px) {
            margin-top: -68px;
    }
}
@media (min-width: 1024px ) and (max-height: 600px) {
    #banner-home{
        min-height: 400px;
    }
    #banner-home .logos-header{
        bottom: 2rem !important;
    }
}

#banner-home{
    position: relative;
    height: calc(100vh - 83px);
    height: calc(100dvh - 83px);


    .logos-header{
        position: absolute;
        bottom: 8rem;
        left: 2.6rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        height: fit-content;
        z-index: 88;
        font-size: 2rem;
        color: white;

        @include d(xl){
            //left: 8.5rem;
        }
        @media (max-width: 1800px){
            left: 8.5rem;
        }

        @include d(lg){
            bottom: 2rem;
        }

        @include d(md){
            top: 1.5rem;
            left: 1rem !important;
            gap: 1rem;
            flex-wrap: wrap;
            font-size: 1.5rem;
        }

    
        a{
            display: inline-flex;

            figure{
                transition: transform .3s ease-in;
                img{
                    max-width: 130px;
                    filter: brightness(0) invert(1);
                    aspect-ratio: 16 / 9;

                    &.cbic{
                        max-width: 76px;
                        @include d(md){
                            max-width: 60px;
                        }
                        @include d(sm){
                            max-width: 40px;
                        }
                    }
    
                    @include d(md){
                        max-width: 80px;
                    }
                    @include d(sm){
                        max-width: 60px;
                    }
                }
            }
            &:hover{
                figure{
                    transform: scale(1.1);
                }
            }
        }
        
    }
  

    &::after{
        content: '';
        position: absolute;
        inset: 0;
        background-image: url('../../src/assets/imagens-rio/banner/filtro-do-banner.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        // background: rgba(0, 0, 0, 0.5);
        // mix-blend-mode: multiply;



        @include d(md){
            // display: none;
            background-image: url('../../src/assets/imagens-rio/banner/banner-linear-bg-mobile.webp');
         
        }
    } 

    @media(max-width:1520px){    
        height: calc(100vh -  121px);
        height: calc(100dvh -  121px);
        .logos-header{
            bottom: 3rem;
        }

    }
    @media(max-width:1400px){
       
        height: calc(100vh - 83px);
        height: calc(100dvh - 83px);
    }
    @media (max-width:1024px){
        
        height: calc(100vh - 77px);
        height: calc(100dvh - 77px); 
        min-height: 740px; 
      
    }


    .socials-banner{
        position: absolute;
        z-index: 3;
        top: 0;
        bottom: 0;
        border-right: 1px solid #fff;
        display: grid;
        place-content: center;

        @include d(md2){
            display: none;
        }
        svg{
            color: #fff;
            width: 32px;
            height: 32px;
        }
        ul{
            padding: 0 33px;
  
            li{
                transition: 0.2s ease-in;
                margin-bottom: 1rem;
            }
            li:hover{
                transform: scale(1.05) ;
            }
          
        }
    }

    .video-wrapper{
        position: absolute;
        inset: 0;
        // bottom: 55px;
        overflow: hidden;

        .banner-img{
            inset: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: left;
            @include d(md){
                object-position: top;
            }
        }
    }

    .container{
        height: 100%;


        @media (min-width: 1024px){
            max-width: calc(1600px + 3rem);
        }

    }
   
    .banner-content{
        position: absolute;
        top: 50%;
        // left: 50%;
        transform: translateY(-50%);
        left: 0;
        padding: 0 1.5rem;
       
        width: 100%;
        color: #fff;
        // text-align: center;
        z-index: 3;

        @media (max-width:1800px) {
            margin-left: 100px;
            width: calc(100% - 100px);
        }
        @include d(md2){
            margin-left: 0;
            width: 100%;

            bottom: 64px;
            top: unset;
            transform: translateY(0);
        }
 
    }

    .grid-wrapper{
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 4rem;

        @include d(md){
            grid-template-columns: 1fr;
        }
        @include d(md2){
            gap: 2rem;
        }
        h1{
            color: #fff;
            font-size: 2rem;
          
            font-style: normal;
            font-weight: 700;
            line-height: 1.1;
            letter-spacing: -0.26px;
            text-transform: uppercase;
        }
        @media (max-width: 500px){
            h1{
                font-size: 1.8rem;
            }
        }
        @media (max-width: 413px){
            h1{
                font-size: 1.5rem;
            }
        }
      
        .descricao{
            font-size: 1.125rem;
            color: #fff;
            font-style: normal;
            font-weight: 400;
            line-height: 1.4;
            letter-spacing: -0.18px;
            margin-bottom: 1.5rem;
            @include d(md2){
                margin-bottom: 2rem;
            }
        }

       
    }

    .linha-hr{
        height: 1px;
        width: 100%;
        background-color: #fff;
        margin: 1rem 0;
    }

    .col-1{

        svg{
            max-width: 604px;
        }
        @include d(md){
        max-width: 400px;
        }
    }

    .col-2{
        max-width: 551px;
        margin-left: auto; 

        
        @include d(md){
            margin-left: unset;
        }
    }  
}
 
#patrocinadores{
    background: #fff;
    scroll-margin-top: 50px;

    @media (min-width: 992px){
        .container {
            max-width: 1300px;
        }
    }
    .button-wrapper{  
        @include d(md){
            display: none;
        }
        .button{
            @include BotaoMain(#3CC5B1);
        }
    }

    .header-wrapper{
        @include d(md2){
            h2{
                font-size: 1.5rem;
            }
        }
    }
    
    
    .governo {
        border-right: 1px solid #c1c5c7;
        padding-right:1rem;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .rio {
        border-right: 1px solid #c1c5c7;
        padding-right:2rem;
        height: 100%;
        display: flex;
        align-items: center;    
    }

    .grid-patrocinio{
        display: flex;
        align-items: stretch;
        gap: 1rem;
        justify-content: center;
        padding-left: 3.75rem;
        padding-right: 3.75rem;
        // display: grid;
        // gap: 2rem;
        // justify-content: space-between;
        // grid-template-columns: repeat(4, 1fr);

        @include d(md){
            flex-wrap: wrap;
        }

        .group-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media (min-width: 1024px){
                &:not(:last-child) {
                    padding-right: 1rem;
                    border-right: 1px solid #C1C5C7;
                }
            }

            .group-title{
                color:  #1B1D21;
                /* Paragraph/P2 - 18 Lig */
                font-size: 1.125rem;
             
                font-style: normal;
                font-weight: 400;
                line-height: 1.3;
                letter-spacing: -0.18px;
                margin-bottom: 1rem;
            }
            .group-imagens{
                display: flex;
                align-items: center;
                gap: 2rem;
                align-items: flex-start;
                align-items: center;
            }

            .group-imagens-1{
                display: flex;
                align-items: center;
                gap: 2rem;
                align-items: flex-start;
                align-items: center;

                @include d(md2){
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    place-items: center;    
                }
            }
        }
    }
}

#scroll-section{
   

    .scroll-text{
        color:  #0D4DA1;     
        font-size: 5rem;         
        font-weight: 500;
        // line-height: 96px;
        letter-spacing: -0.8px;
        text-transform: uppercase;    
        // text-align: center;     
        padding: 3rem 0;
        text-align: center;
        // max-width: max-content;
        // margin: 0 auto;   
        // background-color: #f3ec78;
     
        // background-clip: text;
        // text-fill-color: transparent; 
        //     background-image: linear-gradient(90.09deg, #03AAC5 0.63%, #0099CA 98.73%);
        //     background-image: linear-gradient(45deg, #f3ec78, #af4261);
        //     -webkit-background-clip: text;
        // -webkit-text-fill-color: rgba(0,0,0,0);
        @include d(md){
            font-size: 2rem; 
        }

        &-1{
            border-bottom: 1px solid rgba(27, 29, 33, 0.10);
        }

        &-2{ 
            // background-image: linear-gradient(to right ,#0D4DA1, #42B6A7);
            // background-clip: text;
            // text-fill-color: transparent; 
            // background-color: #f3ec78;
            // background-image: linear-gradient(45deg, #f3ec78, #af4261);
            // background-clip: text;
            // text-fill-color: transparent; 
            background-color: #0D4DA1;
            background-image: linear-gradient(to right ,#0D4DA1, #42B6A7);
            -webkit-background-clip: text;
            -webkit-text-fill-color: rgba(0,0,0,0);
        }
    }
    .scroll-text-2{
        // text-align: right;
    }
}

#banner-random{
    .img-wrapper{
        margin-right: -3px;
        img{
            width: 100%;
            max-width: 100%;
            object-fit: cover;
        }
    }
}

#evento{

    .section-title{
        max-width: 560px;

        @include d(md2){
            h2{
                max-width: 14ch;
                font-size: 36px;
            }
        }
    }
    .grid-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        
        @include d(md){
            grid-template-columns: 1fr;
        }

        .col-2{
            max-width: 644px;
            margin-left: auto;
            @include d(md){
                margin-left: unset;
            }
        }

        .section-title{
            h2{
                margin-bottom: 3.5rem;

                @include d(md){
                    margin-bottom: 2rem
                }
            }
        }

        .button-wrapper{
            .button{
                @include BotaoMain(#3CC5B1);

                @include d(sm){
                    justify-content: center;
                    max-width: unset;
                }
                svg{
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }
}

#sessao-numeros{

    .grid-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @include d(md){
            grid-template-columns: 1fr ;
        }
        .col{
            display: grid;
            grid-template-rows:  minmax(1fr, 535px) auto;
        }
        .col-2{
            .img-wrapper{
                grid-row: 2;
                @include d(md){
                    grid-row: 1;
                }
            }
        }
    }
    .img-wrapper{
            // max-height: 535px;
        img{
            width: 100%;
            height: 100%; 
            object-fit: cover;
        }
    }

    .numero-wrapper{
        color: #fff;
        background-color: #0D4DA1;
        padding: 2rem;
        @include d(md2){
                padding: 24px;
        }

        .numer{
            font-size: 6.5rem;
          
            font-style: normal;
            font-weight: 700;
            line-height: 1;
            letter-spacing: -1.04px;
            @include d(md2){
                font-size: 5rem;
            }
        }
        .text{
            
            /* Title/T2 - 36 Reg */
            font-size: 2.25rem;
        
         
            font-weight: 400;
            line-height: 1.3;
            letter-spacing: -0.36px;
            @include d(md2){
                // font-size:  2.25rem;
            }
           
        }
        .text-info{
            font-size: 1rem;
            text-transform: uppercase;
        }
        .hash-tag{
            margin-top: 2rem;
            color: var(--primary-01100, #3CC5B1);
            /* C3 - 18 Bold */
            font-size: 1.125rem;

            font-weight: 700;
            line-height: 1.2;
            letter-spacing: 1.44px;
            text-transform: uppercase;
            text-align: right;
            @include d(md){
                text-align: left;
            }

        }
    }

}

#palestrantes{

    .section-title{
        h2{
            // font-size: 5rem;
            @include clamp(font-size, 3rem, 7vw, 5rem);
            margin-bottom: 1rem;
        }
        p{
            // max-width: 50ch; 
            span{
                display: block;
                @include d(md2){
                    display: inline;
                }
            }
        }
    }
   
}

#palestrantes{
    background-color: #fff;
    .section-title{
        margin-bottom: 3.5rem;

        @include d(md2){
            margin-bottom: 2rem;
        }
    }


    .swiper-palestrantes-mobile{

        @include d(md){
            overflow: visible;
        }
        @media (min-width: 1024px){

            .swiper-wrapper{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 2rem;
                @media (min-width: 1440px){
                    grid-template-columns: repeat(4, 1fr);
                }
            }
        }
       
    }
    
    
}

#inscricao{
    position: relative;
    z-index: 2;
    margin-top: -50px;
    background-color: #f7f8f9;

    .header-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.5rem;

        @include d(md){
        .section-title{
                    margin-bottom: 1.5rem;
              h2{           
                margin-bottom: 8px;  
                max-width: 12ch;
                }
            }
        }
       
        @include d(md){
            flex-direction: column;
            align-items: flex-start;
        }

        .data-wrapper{
           

            .data-destaque{
                min-width: unset;
            }
            .data-destaque, p{ 
                font-size: 1.5rem;
            }
            .local{
                font-size: 1.375rem;
                
            }
        }
    }

    .inscricao-tabs{
        // border-bottom: 1px solid red;
        margin-bottom: 3rem;
        @include d(md){
            margin-bottom: 1.5rem;
        }

        
        

        &::after{
            content: '';
            width: 100%;
            position: absolute;
            bottom: 0px;
            // // height: 1px;
            // border: 1px solid rgba(27, 29, 33, 0.10);
            height: 1px;
            background-color: rgba(27, 29, 33, 0.10);
        }
        .swiper-slide{
            height: auto;
            @include d(md){
                max-width: max-content;

            }
        }

        .tab-inscricao{
            padding: 1rem 0;
            position: relative;
            cursor: pointer;
            &::after{
                content: '';
                width: 100%;
                position: absolute;
                bottom: 0px;
                // height: 1px;
                
                height: 1px;
               
            }
        

            p{
              
                color:  #7B7C7F;
                text-align: center;
                font-size: 1.375rem;
             
                font-style: normal;
                font-weight: 500;
                line-height: 1.3;
                letter-spacing: -0.22px;
                text-transform: uppercase;
                min-width: max-content;

                @include d(md2){
                    font-size: 18px;
                }
            }

            &.active,
            &:hover{
                p{
                    color: #0D4DA1;
                    transition: 0.2s ease-in-out;
                }
                &::after{
                    background-color: #0D4DA1;
                    transition: 0.2s ease-in-out;
                }
            }
        }
    }

    .inscricao-tab-content{
        display: none;
        &.active{
            display: block;
        }
    }

    .grid-wrapper{
        display: grid;
        grid-template-columns: 1.3fr 1fr;
        align-items: center;
        gap: 2rem;
        @include d(md){
            grid-template-columns: 1fr;
        }
        .col-2{
            max-width: 512px;
            margin-left: auto;
            padding: 2rem 0;
            
            @include d(md){
                margin-left: unset;
                grid-row: 1;
                padding: 0;
            }
            .titulo{
                color:  #1B1D21;
                /* Title/T2 - 36 Bold */
                font-size: 2.25rem;
              
                font-style: normal;
                font-weight: 700;
                line-height: 1.3;
                letter-spacing: -0.36px;
                text-transform: uppercase;
                margin-bottom: 1rem;

                @include d(md2){
                    margin-bottom: .75rem;
                    font-size: 24px;
                }
            }
            .descricao{
                color: #1B1D21;
                /* Paragraph/P2 - 18 Lig */
                font-size: 1.125rem;
             
                font-style: normal;
                font-weight: 400;
                line-height:1.4;
                letter-spacing: -0.18px;
                span{
                   font-weight: 600;
                }
            }
           
        }

        .button-wrapper{
            // margin-top: 3.5rem;
            .button{
                @include BotaoMain(#3CC5B1);
                svg{
                    // width: 2rem;
                    // height: 2rem;
                }
            }
        }

        .img-wrapper{
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                border-radius: 3px;
                object-fit: cover;
            }
        }

        .img-wrapper .button-wrapper{
            display: none;
            @include d(md){
                display: block;
                margin-top: 2rem;
                .button{
                    max-width: unset;
                    text-align: center;
                    justify-content: center;
                   
                }
            }

        }
        .col-2 .button-wrapper{
            
            @include d(md){
                display: none;
            }
        }
    }



}

#inovacao{
    position: relative;

    .header-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3.5rem;
        @include d(md){
            margin-bottom: 2rem;
        }

        h2{
         
            color:  #0D4DA1;
            font-size: 3rem;
            font-family: Causten;
            font-style: normal;
            font-weight: 400;
            line-height: 1.3;
            letter-spacing: -0.48px;
            text-transform: uppercase;
            max-width: 32ch;
            @include d(md2){
                // font-size: 2.25rem;
                font-size: 36px; 
            }

            span{
                font-weight: 800;
            }
        }
        .img-detalhe{
            @include d(md){
                display: none;
            }
            img{
                max-width: 5rem;
                max-height: 5rem;
            }
        }
     
    }

   
    .wrapper-for-bg-img{
        position: relative;
        .img-wrapper{
            position: absolute;
            top: 0;   
            bottom: 0;
            @include d(md){
                position: unset;
            }
            img{
                height: 100%;
                object-fit: cover;
            }
            
        }
    }
    .inovacao-wrapper{
        max-width: calc(649px + 3rem );
        margin-left: auto;
        position: relative;
        z-index: 3;
        padding-left: 3rem; 
  
        background: linear-gradient(90deg, #f7f8f900 0%, #F7F8F9 2%);
        @include d(lg){
            // max-width: calc(609px + 3rem );
            max-width:50%;
        }
        @include d(md){
            padding-left: 0;
            max-width: 100%;
            margin-left: unset;
            background: unset;
        }
    }

   


    .card-inovacao{
        border-bottom: 1px solid rgba(27, 29, 33, 0.10);
        padding: 1.5rem 0;

        @include d(md){
            &:first-child{
                padding-top: 0;
            }
        }

        &:last-child{
            border-bottom: none;
        }

        svg{
            width: 40px;
            height: 40px;
            margin-bottom: 1.5rem;
        }
        p{
            font-size: 16px;
        }
        .titulo{
            color:  #1B1D21;
            /* Title/T3 - 24 Reg */
            font-size: 24px;
            font-family: Causten;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: -0.24px;
            margin-bottom: 8px;
        }
    }
  
}

#imprensa{
    .section-title{
        max-width: 512px;
        margin-right: 1rem;

     
        
        h2{
            margin-bottom: 1rem;
            @include d(md2){
                font-size: 36px;
            } 
        }
    }
    .grid-wrapper{
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1.3fr;
        @include d(md){
            grid-template-columns: 1fr;
        }
        
    }
    .img-wrapper{
        @include d(md){
            display: none;
        }
        img{
            width: 100%;
        }
    }
    .button-wrapper{
        margin-top: 3.5rem;
        @include d(md){
            margin-top: 2rem;
        }
        .button{

            @include BotaoMain(#3CC5B1);
            @include d(md){
                max-width: unset ;
                width: 100%;
                justify-content: center;
                text-align: center;
            }
          
        }
    }
}

#instagram{

    .badge-for-ornamento{
        max-width: max-content;
        margin: 0 auto;
    } 
    .ornamento-camera{
        position: absolute;
        z-index: -1;
        left: -53px;     
        bottom: -23px;
    }
    .section-title{
        max-width: max-content;
        margin: 0 auto;
        margin-bottom: 3.5rem;
    }
    .instagram-wrapper-new{

        #instafeed {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            @include d(md2){
                grid-template-columns: 1fr 1fr ;
            }
    
         
        
            a {
                width: 100%;
                overflow: hidden;
                position: relative;

                &:hover .content-hover{
                 transform: translateY(0);
                }
            
                .content-hover{
                    position: absolute;
                    pointer-events: none;
                    background-color: #0D4DA1;
                    text-align: center;
                    padding: 2rem;
                    display: grid;
                    place-content: center;
                    gap: 10px;

                    inset: 0;
                    height: 100%;

                    overflow: hidden;
                    transform: translateY(100%);
                    transition: 0.2s ease;

                    .see-insta{
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 1.4;
                        /* identical to box height, or 144% */
                        text-align: center;
                        letter-spacing: -0.02em;
                        text-transform: uppercase;
                        /* Black */
                        color: #fff;
                        @include d(md2){
                            font-size: 1rem;
                        }
                    }
                    .click-icon{
                        margin-right: 2rem;

                    }
                    svg{
                        filter: brightness(0) invert(1);
                        margin: 0 auto;
                        width: 2.0625rem;
                        height: 2rem;
                    
                    }
                }
            }
        }


    }
    .banner-embaixo{
        display: flex;
        align-items: stretch;
        margin-top: 8px;
        gap:8px;

        @include d(sm){
            flex-direction: column;
        }

        .img-wrapper{
            flex: 1;
            @include d(md2){
                display: none;
            }
        }
        .img-wrapper:first-child{
            flex: 1.33;
            display: block;
        }
    }
    
    .instagram-wrapper{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 8px;

        @include d(md2){ 
            grid-template-columns: repeat(2, 1fr);
        }
    }

 

    .img-wrapper{
        border-radius: 3px;
        overflow: hidden;
        position: relative;
       

        a{
            height: 100%;
            display: block;
            img{
                object-fit: cover;
                height: 100%;
            }
        }

        
        &:hover .content-hover{
            transform: translateY(0);
        }
    

        .content-hover{
            position: absolute;
            pointer-events: none;
            background-color: #0D4DA1;
            text-align: center;
            padding: 2rem;
            display: grid;
            place-content: center;
            gap: 10px;

            inset: 0; 
            height: 100%;

            overflow: hidden;
            transform: translateY(100%);
            transition: 0.2s ease;

            .see-insta{
                font-weight: 700;
                font-size: 18px;
                line-height: 1.4;
                /* identical to box height, or 144% */
                text-align: center;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                /* Black */
                color: #fff;
                @include d(md2){
                    font-size: 1rem;
                }
            }
            .click-icon{
                margin-right: 2rem;

            }
            svg{
                filter: brightness(0) invert(1);
            margin: 0 auto;
                width: 2.0625rem;
                height: 2rem;
            
            }
        }

       
    }

   
}

#contato-footer{

    background: #fff;
    padding-bottom: 9.375rem;
    scroll-margin-top: 130px;
 
    @include d(md2){
        background: #fff;
        padding-bottom: 0;
    }

    .contato-grid-wide{
        display: grid;
        grid-template-columns: 0.8fr 1.2fr;
        align-items: center;

        @include d(md2){
            grid-template-columns: 1fr;
        }
    }

    .col-1{
        position: relative;
        align-self: stretch;


       

        .orna{
            position: absolute;
            z-index: 2;

        

            &-1{
                top: 9%;
                right: -2rem;
                @media (max-width: 1400px){
                    max-width: 60px;
    
                }

                @include d(md2){
                    top: -24px;
                    left: 3.875rem;
                    max-width: 47px;
                }

            }
            &-2{
                left: 160px;
                bottom: 119px;
                @media (max-width: 1400px){
                    left: 120px;
                    bottom: 89px;
    
                }
                @include d(md2){
                    left: 100px;
                    bottom: 74px;
                }
            }
            &-3{
                bottom: 0;
                left: 0;

                @media (max-width: 1400px){
               
                    max-width: 120px;
                    
                }
                @include d(md2){
                    max-width: 100px;
                }

            }
            &-4{
                bottom: -40px;
                right: 20%;

                @media (max-width: 1400px){
                    max-width: 120px;
    
                }
                @include d(md2){
                    display: none;
                }

            }

        }
        .img-wrapper{
            height: 100%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: left;
            }
        }
    }

    .col-2{
        padding-top: 7.5rem;
        padding-left: 10rem;
        padding-bottom: 0rem;
        padding-right:  10rem;
        position: relative;

        @media (max-width: 1600px){
            
            padding-left: 6rem;
            padding-top: 5rem;
        }
        @include d(md){
            padding-right:  3rem;
            padding-left: 3rem;
        }
        @include d(md2){
            grid-row: 1;
            padding-right:  1rem;
            padding-left: 1rem;
            padding-top: 4rem;
            padding-bottom: 2.625rem;
        }

       
        
        .limit-width{
            max-width: 635px;
            padding-right: 50px;
            position: relative;
            z-index: 1;

            @include d(md){
                padding-right: 0;
            }
            @include d(md2){
                max-width: 540px;
                margin: 0 auto;
            }
        }

       
    }


    .contato-wrapper{
        margin-bottom: 3.5rem;

        .label-texto{
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 144%;
            /* identical to box height, or 144% */
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #3B9C8E;
            margin-bottom: .75rem;
        }

        .contato-texto{
            font-weight: 800;
            font-size: 2.25rem;
            line-height: 140%;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #0D4DA1;

            margin-bottom: 1rem;
            @media (max-width: 1360px){
                font-size: 2.3rem;
            }
            @include d(dt){
                font-size: 2rem;
            }
            @include d(md2){
                font-size: 2.2rem;
            }
        }

        .contato-descricao{ 
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.4;
      
            
            letter-spacing: -0.02em;
            
         
            
            color: #36363B;
        }

      
    }

    .form-wrapper{

        .button-wrapper{
            // transition: 0.2s ease-in;
            // &:hover{
            //     transform: scale(1.02);
               
            // }
            .button{
                @include BotaoMain(#3CC5B1);
                @include d(md){
                    max-width: unset ;
                    width: 100%;
                    justify-content: center;
                    text-align: center;
                }
            }
           
            
        }
    }
    .info-contato-container{
        margin-top: 2rem;
        .block-wrapper-contato{
            margin-bottom: 1.125rem;
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            .block-title{
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 1.5;
                /* identical to box height, or 150% */            
                letter-spacing: -0.02em;
                text-transform: uppercase;           
                /* Primary/Fucsia */            
                color: #A3238E;
    
                
                
              
                
            }
    
            .block-info{
                display: block;
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 1.4;
                
                /* identical to box height, or 144% */
    
                letter-spacing: -0.02em;
    
                /* Neutral/70 */
    
                color: #36363B;
            }
        }
    }

}

#como-chegar{
    background-color: #fff;
    border-top: 1px solid  rgba(27, 29, 33, 0.10);
    border-bottom: 1px solid  rgba(27, 29, 33, 0.10);

// background: red;
    .como-chegar-wrapper{
        display: grid;
        grid-template-columns: 1fr 1.1fr;
        align-items: center;
        gap: 10px;
        @include d(md2){
            grid-template-columns: 1fr;
            gap: 3.875rem;
        }
    }

    .section-title{

        .icon{
            background-color: #fff;
            box-shadow: 0px 8px 32px 0px rgba(59, 156, 142, 0.08);
            width: max-content;
            padding: 16px;
            border-radius: 50%;
            margin-bottom: 1.5rem;
            svg{
                width: 32px;
                height: 32px;
                color: #3B9C8E;
            }
        }
        h2{
            margin-bottom: 1rem;
        }
        .estado{
            color: #0D4DA1;
            font-size: 24px;
         
            font-style: normal;
            font-weight: 400;
            line-height: 1.3;
            letter-spacing: -0.24px;
            margin-bottom: 4px;
        }

        .button-wrapper{
            margin-top: 3.5rem;
            @include d(md){
                margin-top: 2rem;
            }
            .button{

                @include BotaoMain(#3CC5B1);
                @include d(md){
                    max-width: unset ;
                    width: 100%;
                    justify-content: center;
                    text-align: center;
                }
            
            }
        }
    }

    .mapa-wrapper{

        iframe{
            width: 100%;
            height: 412px;
        }
    }
}

.display-hidden {
    display: none;
}
///// bienal ///////

#o-evento{
    position: relative;
    .section-title .section-description{
        text-align: justify;
    }
    .grid-wrapper{
        display: grid;
        grid-template-columns: 0.8fr 1.2fr;
        align-items: center;
    

        @include d(md){
            grid-template-columns: 1fr; 
            gap: 2rem ;
        }

    }
    .icon-laranja{
        position: absolute;
        right: 0;
        top: 4rem
    }
    .badge-for-ornamento{
        position: relative;
        width: max-content;
        .icon-luneta{
            position: absolute;
            z-index: 2;
            top: -60px;
            right: 0;
        }
    }
    .col-2{
        position: relative;

        @include d(md2){
            margin-top: 2rem;
        }
    }

    .card-flutuante {
        background: #FFFFFF;

        border: 1px solid rgba(15, 15, 18, 0.05);
        box-shadow: 0px 7.93959px 31.7584px rgba(53, 55, 56, 0.08);
        border-radius: 6px;
        width: max-content;
        padding: 1rem 1.5rem;

        text-align: center;
        max-width: 200px;
        width: 100%;
        position: absolute;
        @include d(dt){
            max-width: 180px;
        }
      

        @include d(md2){
            // max-width: 106px;
            padding: 10px 14px;
            max-width: max-content;
            min-width: 102px;
        }

        &::before{
            content: '';
             position: absolute;
             height: 1px;
          
             bottom: 0;
             z-index: 1;
             left: 50%;
             transform: translateX(-50%);
             width: 63px;

        }

        &-1{
            color: #A3238E;
            top: 5rem;
            left: 0;
            @include d(dt){
                top: 1rem;
            }
            @include d(md2){
                top: -10px;
            }

            &::after{
                background-color: #A3238E;
            }
        }
        &-2{ 
            color: #56C5D0; 
            
            bottom: 3.125rem;
            left: 0;
            @include d(dt){
                bottom: -40px;
            }
            @include d(md2){
                left: 40px;
                bottom: -40px;
            }
                &::after{
                background-color: #56C5D0;
            }
        }
        &-3{ color: #F58220;
            bottom: 3.75rem;
            right: 0;
            @include d(dt){
                bottom: 1rem;
            }
            @include d(md2){
                bottom: 4rem;
            }
            &::after{
                background-color: #F58220;
            }
        }
     

        .number{
        
            font-weight: 800;
            font-size: 3rem;
            line-height: 117%;
            /* identical to box height, or 117% */

           
        
            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Primary/Fucsia */
            @include d(dt){
                font-size: 2rem;
            }
            @include d(md2){
                font-size: 24px;
            }
          
        }
        .info{
            font-weight: 500;
            font-size: 1rem;
            line-height: 150%;
            /* identical to box height, or 150% */

         
            letter-spacing: -0.02em;
            text-transform: uppercase;

            /* Neutral/70 */

            color: #36363B;
            @include d(md2){
                font-size: 12px;
            }
        }

    }

    .img-wrapper{
        padding: 0 100px;
        position: relative;
        @include d(lg){
            padding: 0 60px;
        }
        @include d(md2){
            padding: 0 10px;
        }

        img{
            // min-height: 400px
            
        }
    }
    .button-wrapper{

        // box-shadow: 6px 6px #1d336f !important;
        a{
            @include BotaoMain(#D6DF21);
    
            // @include BotaoTeste(red, blue);
            // 
            
        }
    }


    .alien-orna{
        position: absolute;
        bottom: -70px;
        right: 20vw;
        z-index: 2;

        @include d(lg){
            right: 20vw;
            bottom: -50px;
            max-width: 200px;
        }
        @include d(md){
            max-width: 130px;
            bottom: -100px;
            right: 1.5rem;
        }
    }

    .gatinho-orna{
        margin-bottom: -20px;
        @include d(md2){
            max-width: 123px;
            margin-left: 21px;

        }
    }
    .linear-orna{
        position: relative;

        img{
            min-height: 18px;
            width: 100%;
            object-fit: cover;
        }
    }

    
}

#atracoes{
    background-color:  $bienal-laranja;

    background-image: url('../../src/assets/imagens/background-eventos.svg');
    position: relative;
    @include d(md2){
        background-size: 80px 80px;
    }
    // &::after{
    //     content: '';
    //     position: absolute;
    //     inset: 0;
    //     background: rgba(217, 217, 217, 0.2);
    // }
    .info-sujeita{
        color: #fff;
        letter-spacing: -.02em;
        text-transform: uppercase; 
        font-style: normal;
        font-weight: 800;
        line-height: 114%;
        font-size: 1.8rem;
    }
    
    .section-title{
        h2{
            color: #fff;

            @include d(sm){
                max-width: 15ch;
            }
        }
      
    }


    .info-atracoes-titulo{
        letter-spacing: -.02em;
        text-transform: uppercase;
        color: #fff;
        font-size: 2rem;
        font-style: normal;
        font-weight: 800;
        line-height: 114%;
      margin-top: 4rem;
      margin-bottom: 1rem;
    }

    .button-wrapper{
        flex-shrink: 0;
        a{
            @include BotaoMain(#D6DF21);
        }
    }

     .button-wrapper-bottom{
        @media (min-width: 768px){
            display: none; 
        }
        .button{
            max-width: 100%;
            justify-content: center;
        }
     }


     .linear-orna{

        img{
            min-height: 18px;
            width: 100%;
            object-fit: cover;
        }
    }
} 

#programacao{
    position: relative;


    .section-title{
        position: relative;
        width: max-content;
        margin: 0 auto;
        margin-bottom: 3.125rem;

      
        .ornamento-peixe{
            position: absolute;
            right: -4rem;
            top: 50%;
            transform: translateY(-57%);
        }
        h2{
            position: relative;
            z-index: 4;
        }
    }



    .swiper-programacao-tabs{
        margin-bottom: 2.5rem;

        .swiper-wrapper{
            justify-content: center;
            @include d(md){
                justify-content: unset;
            }
        }
        .swiper-slide{
            max-width: max-content;
         
        }
    }

    .tab-programacao{

        background-color: #F5872C;
        cursor: pointer;
        /* White
        
        FFFFFF
        */
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        padding: 10px;
        color: rgba(255, 255,255, 0.6);


        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
        

        text-align: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;


        min-width: 140px;

       
        @include d(lg){
            min-width: 112px;
        }
        // @include d(lg){
        //     min-width: 120px;
        // }

        .programacao-data{
            font-size: 1.5rem;
            font-weight: 800;
            @include d(md2){
                line-height: 1;
            }
        }

        &.active{
            background: #A3238E;;
            color: #fff;
        }
    }
 

    .programacao-tab-content{
        display: none; 
        // opacity: 0;
        // max-height: 0;
        // overflow: hidden;
        // transition: max-height  0.2s ease-in;
        min-height: max-content;
        position: relative;
        &.active{
            display: block;           
            opacity: 1;     
        }

        .gatinho-orna{
            position: absolute;
            top: -100px;
            left: 15px;

            @include d(dt){
                display: none;
            }
            @include d(md2){
                display: block;
                top: unset;
                bottom: 0;
                transform: scale(0.5);
                transform-origin: left;
            }

        }
        

        .grid-wrapper{
            display: grid;
            grid-template-columns: 0.3fr 1fr;
            gap: 1rem;
            color: #fff;
            z-index: 2;
            position: relative;
            @include d(md){
                grid-template-columns: 1fr;
                gap: 4px;
            }
        }
     

        .side-info{
            position: relative;
            border-radius: 4px;

            background-color: $bienal-rosa;           
            text-align: center;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include d(md){
                grid-row: 2;
            }
            @include d(md2){
                background: transparent;
            }

            .content-side{
                padding: 2rem;
                margin: auto;
                @include d(lg){
                    padding: 1rem; 
                }
                @include d(md2){
                    background-color: $bienal-rosa;
                    margin: unset;
                    border-radius: 4px;
                    margin-bottom: 4px;
                }
            }
           
            .img-detail{
                width: max-content;  
                margin: 0 auto;
                margin-bottom: 2rem;
                @include d(md2){
                    position: absolute;
                    right: 0;
                    top: -20px;
                    margin-bottom: 0;
                    img{
                        height: 40px;
                    }
                }
                img{
                
                  
                }
            }

            .programacao-horario{
                margin-bottom: 8px;
                font-weight: 600;
                font-size: 3rem;
                line-height: 1.17;
                /* identical to box height, or 117% */

                text-align: center;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #FFFFFF;
                @include d(lg){
                    font-size: 2.5rem;
                }
                @include d(md2){
                    text-align: left;
                }
            }
            .programacao-local{
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 1.4;
             
                
                text-align: center;
                letter-spacing: -0.02em;
                text-transform: uppercase;

                color: #FFFFFF;
                @include d(md2){
                    text-align: left;
                }
            }
            .bottom-info{
                background: #56C5D0;
                padding: 12px 0;
               
                @include d(md2){
                    border-radius: 4px;
                    padding:14px ;
                    margin-top: 2rem;
                    
                }
                .button-bottom-info{
                    display: flex;
                    // align-items: center;
                    align-items: center;
                    justify-content: center;

                    font-weight: 700;
                    font-size: 14px;
                    line-height: 1;
                    text-align: center;
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
                    color: #000000;

                   
                    

                    svg{
                        width: 1.25rem;
                        height: 1.3125rem;
                        margin-left: 4px;
                        flex-shrink: 0;
                        margin-top: -3px;
                    }
                }
            }
        }
        .programacao-dia-wrapper{
            background-color: $bienal-rosa;
            display: grid;
            grid-template-columns: 0.4fr 0.6fr;
            border-radius: 4px;

            @include d(md2){
                grid-template-columns: 1fr;
            }
          


            .swiper-programacao-atracoes{
                display: grid;
                width: 100%;

                @include d(md2){
                    // overflow: scroll;
                }

             
            }
            .swiper-controls-header{

                .buttons{
                    display: flex;
                    gap: 4px;
                    align-items: center;
                    .button{
                        background-color: $bienal-amarelo;
                        width: 1.5rem;
                        height: 1.5rem;
                        border-radius: 50%;
                        display: grid;
                        place-content: center;
                        svg{
                            path{

                                stroke: #000
                            }
                            
                        }
                        &.swiper-button-disabled{
                            background-color: transparent;
                            border: 1px solid white;
                            svg{
                                path{
    
                                    stroke: #fff
                                }
                                
                            }
                        }
                    }
                }

                .swiper-button-lock{
                    display: none !important;
                }
            }
            .dia-info-wrapper{
                padding: 3.5rem;
                border-right: 1px solid rgba(255, 255, 255, 0.2);

                @include d(lg){
                    padding: 2rem; 
               
                } 
                @include d(md2){
                    padding: 1.5rem; 

                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
               
                } 

                .title{
                    font-weight: 800;
                    font-size: 1.5rem;
                    line-height: 1.3;
                    margin-bottom: 1rem;
                    /* identical to box height, or 133% */

                    letter-spacing: -0.02em;
                    text-transform: uppercase;

                    /* White */

                    color: #FFFFFF;
                }
                .descricao{
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    /* or 150% */

                    letter-spacing: -0.02em;

                    @include d(md2){

                        display: block;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }
                }
                .content-for-p{
                    &.open{
                        
                        .descricao{
                            display: block;
                        }
                    }
                }

                
                .continuar-lendo{
                    display: flex;
                    align-items: center;
                    margin-top: 1rem;
                    cursor: pointer;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 1.5;
                    letter-spacing: -0.02em;
                    color: #D6DF21;
                    display: none;
                    @include d(md2){
                        display: flex;
                    }
                    svg{
                        flex-shrink: 0;
                        margin-left: 5px;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .dia-atracoes-wrapper{
                padding: 3.5rem;
                display: grid;
                align-items: flex-start;
                width: 100%;
                @include d(lg){
                    padding: 2rem;
                }
            }

            .header-atracoes {
                align-self: flex-end;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 11px;

                .header-atracoes-titulo{
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 1.3;

                    
                }
            }

            .card-programacao{
                cursor: pointer;
                .content{
                   
                }
                .card-evento-img{ 
                    position: relative;
                    padding-top: 100%;
                    overflow: hidden;
                    img{
                        position: absolute;
                        inset: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 3px;
                        border-radius: 3px;
                    } 
                }
                .card-evento-title{
                    font-weight: 700;
                    font-size: 1.125rem;
                    line-height: 1.4;

                    letter-spacing: -0.02em;
                    text-transform: uppercase;

                    color: #D6DF21;
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .card-evento-role{
                    font-weight: 500;
                    font-size: .875rem;
                    line-height: 157%;

                    letter-spacing: -0.02em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                }
            }
        }
    }
}







#midia{


    .badge-for-ornamento{
        max-width: max-content;
        margin: 0 auto;
    } 
    .ornamento-camera{
        position: absolute;
        z-index: -1;
        left: -53px;     
        bottom: -23px;
    }
    .section-title{
        max-width: max-content;
        margin: 0 auto;
        margin-bottom: 3.5rem;
    }

} 


.bottom-info-blog{

    display: flex;
    align-items: center;
    justify-content: space-between;

    svg{
        width: 24px;
        height: 24px;
       
        filter: brightness(0) invert(1);
    }
}

.blog-card {
    border-radius: 3px;
    overflow: hidden;
    
}
.blog-card.blog-destaque{
    // display: flex;


    &.half{
        display: grid;
        grid-template-columns: 1fr 1fr;

        @include d(dt){
            grid-template-columns: 1fr;
            grid-template-rows: 60% 40%;
            display: flex;
             flex-direction: column;
        }
    }
    &.half-bottom{
        display: grid;
        grid-template-rows: 60% 40%;
        @include d(dt){
            display: flex;
            flex-direction: column;
        }
    }

    .img-wrapper{
        @include d(dt){
            flex: 1;

            position: relative;
            padding-top: 75%;
            overflow: hidden;
        }

        img{ 
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include d(dt){
                position: absolute;
                inset: 0;
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
        }
    }


    
    
    .content-blog{
        background: #A3238E;
        padding: 2rem;
        color: #fff;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include d(dt){
            padding: 1rem;
            flex: 1;
        }

        h2{
            font-style: normal;
            font-weight: 600;
            font-size: 2rem;
            line-height: 1.1;
            /* or 106% */
            letter-spacing: -0.02em;
            text-transform: uppercase;
            flex: 1;
            margin-bottom: 1rem;
            

            display: flex;
            justify-content: flex-start;
            align-items: center;
            @include d(lg){
                font-size: 1.5rem;
            }
            @include d(dt){
                font-size: 1.2rem;
            }
        }

        .info-blog{
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.5;
            /* identical to box height, or 150% */

            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: rgba(255, 255, 255, 0.8);
            display: flex;
            flex-wrap: wrap;
            column-gap: 5px;
        }
    }
}


@media (min-width:768px){
    .swiper-midia {
        display: none;
    
    }
    
}

.swiper-midia{
    overflow: visible;
    .swiper-slide{
        height: auto;

        .blog-card{
            height: 100%;
            display: flex;
            flex-direction: column;

            .img-wrapper{
                flex: 1;
            }

            .content-blog{
                flex: unset;
            }
        }
    }

    .swiper-slide:nth-child(1) {
        .content-blog{
            background-color:  $bienal-rosa;
        }
    }
    .swiper-slide:nth-child(2) {
        .content-blog{
            background-color:  #369AA3;
        }
    }
    .swiper-slide:nth-child(3) { 
        .content-blog{
            background-color:  $bienal-laranja;
        }
    }
    .swiper-slide:nth-child(4) {
        .content-blog{
            background-color:  #369AA3;
        }
    }

    .todas-noticias{
       
        
        border: 1px solid #0F0F12;
        border-radius: 3px;
   
        display: block;
        padding: 10px;
        margin-top: 1rem;
      
        p{

            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            /* identical to box height, or 157% */
    
            text-align: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;
    
            /* Black */
    
            color: #000000;
        }

        svg{
            width: 20px;
            height: 20px;
        }
    }
}

.blog-grid-wrapper{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 27.8125rem 27.8125rem;
    gap: 2rem;

    @media( max-width: 768px){
        display: none;
    }
    @include d(lg){
        gap: 1rem;
        grid-template-rows: 25rem 25rem;
    }

    @include d(dt){
        grid-template-columns: 1fr 1fr;
        grid-template-rows: unset;
       
    }
    @include d(md2){
        grid-template-columns: 1fr;
    }

    .blog-card:nth-child(1){
        grid-template-columns: 1.6fr 1.4fr;
        grid-column: 1/ 4;

        @include d(dt){
            grid-template-columns: 1fr;
            grid-column: unset;
            // display: block;
        }

       


        .content-blog{
            background-color: $bienal-rosa;
        }
        img{
            max-height: 445px;
        }
    }
    .blog-card:nth-child(2){
        grid-column: 4 / 6;

        @include d(dt){
            grid-column: unset;
        }

        .content-blog{
            background-color:  #369AA3;
        }
    }
    .blog-card:nth-child(3){
        grid-row: 2;
        grid-column: 1 / 3;

        @include d(dt){
            grid-column: unset;
            grid-row: unset;
        }
        .content-blog{
            background-color:  $bienal-laranja;
        }
    }
    .blog-card:nth-child(4){
        grid-row: 2;
        grid-column: 3 / 5;

        @include d(dt){
            grid-column: unset;
            grid-row: unset;
        }
        .content-blog{
        background-color:  #369AA3;
         }
    }

    .todas-noticias{
        border: 1px solid #0F0F12;
        background: #FFFFFF;
        border-radius: 3px;
        padding: 1rem;
        display: grid;
        place-content: center;
        @include d(dt){
            grid-column:  span 2;
        }
        @include d(md2){
            grid-column: unset;
        }
        p{
            display: flex;
            align-items: center;
          
            font-weight: 700;
            font-size: 14px;
            line-height: 1.5;
            
            letter-spacing: -0.02em;
            text-transform: uppercase;
        }
        svg{
            width: 20px;
            margin-bottom: 5px;
            height: 21px;
        }
    }
}



#youtube{
    background-color:  $bienal-laranja;

    background-image: url('../../src/assets/imagens/background-eventos.svg');
    position: relative;
    @include d(md2){
        background-size: 80px 80px;
    }

    @media (min-width:1024px){
        
        .swiper-pagination{
            position: unset;
            margin-right: 2rem;
            max-width: 80px !important;
        }
    }
    .swiper-pagination{
        max-width: 80px !important;
    }
    .controls{
        display: flex;
        align-items: center;
        margin-bottom: 10px;


        
            .buttons{
                flex-shrink: 0;
                display: flex;
                align-items: center;
                gap: 1rem;
                @include d(md){
                    
                  display: none;
                   
                }
    
                .button{
                    padding: 8px 16px;
                    gap: 8px;
    
                    width: 56px;
                    height: 56px;
                    display: grid;
                    place-content: center;
                    /* Primary/Verde
    
                    D6DF21
                    */
                    background: #D6DF21;
                    border-radius: 3px;
    
                    svg{
                        width: 24px;
                        height: 24px;
                    }
                    &.button-left{
    
                        svg{
                            transform: rotate(180deg); 
                        }
                    }
                } 
    
                .swiper-button-disabled{
                    background: #E3E3E6;
                }
                
            }
    }


    .swiper-youtube{
        @include d(md){
            overflow: visible;
        }

        .swiper-wrapper{
            @include d(md){
    
                padding-bottom: 4rem;
            }
        }
        .swiper-slide{
            height: auto;
            > a{
                height: 100%;
                display: grid;
            }
        }

    }
 
   
  
    .swiper-pagination{

        .swiper-pagination-bullet {
            opacity: 1;
            background: rgba(255, 255, 255, 0.36);;
        }
        .swiper-pagination-bullet-active{
            background-color: $bienal-amarelo;
            width: 16px;
            border-radius: 8px;
        }
    }


    .section-title{
        h2{
            color: #fff;
        }
    
    }
   
   
    .card-youtube{
        color: #fff;
        // max-width: 512px;
        display: flex;
     flex-direction: column;

        .img-wrapper{
            margin-bottom: 1.5rem;
            // flex: 1;
            padding-top: 56.25%;
            position: relative;
            
            @include d(md){
                margin-bottom: 1rem;
            }

            img{
                object-fit: cover;
                width: 100%;
                height: 100%;            
                border-radius: 6px;

                position: absolute;
                inset: 0;                
            }
        }

        .data{
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.5;           
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: $bienal-rosa;
            margin-bottom: 8px;
        }
        .video-title{
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.3;

            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: #FFFFFF;

            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical; 
            overflow: hidden;
            text-overflow: ellipsis;

            height: 3.5625rem;
            

          
        }
    }
}

