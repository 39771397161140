.card-palestrante-wrapper{
    .card-palestrante{

        max-width: 500px;
        position: relative;
        overflow: hidden;
    
        &::after {
        content: "";
        position: absolute;
        display: block;
        // background:   red;
        background: 
        linear-gradient(180deg, rgba(22, 65, 148, 0.80) 0%,
            rgba(22, 65, 148, 0.93) 51.66%, #164194 100%);
        opacity: 0.9;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
        transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
        }

        &:hover:after{
        transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
        }
        &:hover .item-text {
        opacity: 1 !important;
        transform: translateY(0) !important;
        } 
        &:hover .plus-icon{
        // visibility: hidden;
        opacity: 0;
        }
        
        &:hover .img-wrapper{
            transform: scale(1.2);
        }
        .card-palestrante-inside{
            height: 0;
            overflow: hidden;   
            padding-top: calc( 444px / 376px) * 100%;
            background: white;
            position: relative;
        }
        .img-wrapper{
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
            
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    
        .item-text {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            // height: 100%;
            max-height: 100%;
            overflow: hidden;
            padding: 1.5rem;
            // text-align: center;
            z-index: 1;
            color: #fff;
            transform: translateY(-20%);
            transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),
              transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
            transition-delay: 300ms;
          }
        
    
    
        .infos-wrapper{
            margin-top: 2rem;
            display: flex;
            align-items: stretch;
            gap: 1rem;
            border: 1px solid #ffffff56;
            border-radius: 3px;
            // padding: 8px;
        }
        .local{
        padding: 8px; 
        display: grid;
        
        &:first-child{
            border-right: 1px solid #ffffff56; 
            
        }

        .local-wrapper{
            display: flex;
            align-items: center;
            gap: 4px;
            margin-bottom: 4px;
            .label{
                text-transform: uppercase;
                line-height: 1;
                font-weight: 500;
                font-size: .875rem;
                color: #ffffffb2;;
            }
        
        }
        .info-text{
            color:  #FFF;
            font-size: 1rem;
            
            font-style: normal;
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: 0.64px;
            text-transform: uppercase;
        }

        svg{
            width: 18px;
            height: 18px;
            color: #fff;
            opacity: 0.8;
        }
        }
        .item-text-wrap {
            position: absolute;
            inset: 0;
            padding: 1.5rem;
            max-height: 100%;
            top: unset;
            // padding: 2rem 0;
            overflow: auto;
            // width: 100%;
            // top: 50%;
            // transform: translateY(-50%);

            .item-text-descricao{ 

                p{            
                    font-size: 15px;
                    font-weight: 400; 
                    color: #fff; 
                    line-height: 1.4;
                    letter-spacing: -0.16px;
                }
            }
        }
        
    
        .plus-icon{
        cursor: pointer;
        background-color:  rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(25px);
        border-radius: 50%;
        width: max-content;
        padding: 8px;
        position: absolute;
        right: 1.5rem;
        bottom: 1.5rem;
        z-index: 4;
        transition: 300ms cubic-bezier(0.2, 1, 0.3, 1);
        svg{
            
            width: 24px;
            height: 24px;
        }
        }                
    }
    .bottom-infos{
        margin-top: 1rem;
        .nome{
            color: #1B1D21;
            font-size: 1.625rem;
        
            font-style: normal;
            font-weight: 600;
            line-height: 1.3;
            letter-spacing: -0.26px;
            text-transform: uppercase;
        }
        .role{
            color: #3B4042;
            font-size: 14px;
          
            font-style: normal;
            font-weight: 500;
            line-height: 1.3;
            letter-spacing: 0.56px;
            text-transform: uppercase;
        }
        .tags{
           display: flex;
           flex-wrap: wrap;
           gap: 4px;
           margin-top: 1rem;
            span{
                border-radius: 12px;
                border: 1px solid  #1B1D21;
                display: inline-block;
                padding: 8px;
              
              
                font-size: 12px;

                font-style: normal;
                font-weight: 500;
                line-height: 10px;
                letter-spacing: 0.96px;
                text-transform: uppercase;
            }
        }
      }
}

.faixa-wrapper{
    position: relative;
    z-index: 3;

    margin-top: 5rem;
    border-radius: 4px;

    background-image: url('../../src/assets/imagens-rio/palestrantes/faixa-botao.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    padding: 1.5rem;

    @include d(md2){
        margin-top: 2rem;
    }

    &.only-btn-mobile{
        @include d(md2){

            background-image: none;
            .button-wrapper{
                .button{
                    margin-left: unset;
                    width: 100%;
                    justify-content: center;
                    text-align: center;
                    max-width: unset;
                    padding: 1.4rem;
                }
            }
        }
    }
    .button-wrapper{
     
        .button{
            @include BotaoMain(#3CC5B1);
            padding: 1.5rem;
            margin-left: auto;
            svg{
                // width: 2rem;
                // height: 2rem;
            }
        }
    }
}



.scroll-box-palestrante::-webkit-scrollbar {
    width: 8px;
  }
  
  .scroll-box-palestrante::-webkit-scrollbar-track {
    background-color: #b4a8a874;
  }
  
  .scroll-box-palestrante::-webkit-scrollbar-thumb {
    background-color: #0d4691;
  }
  
  .scroll-box-palestrante::-webkit-scrollbar-thumb:hover {
    background-color: #b4a8a874;
  }
  
  /* Firefox */
  .scroll-box-palestrante {
    scrollbar-width: thin;
    scrollbar-color: #0d4691 #b4a8a874;
  }
  
  .scroll-box-palestrante::-moz-scrollbar {
    width: 8px;
  }
  
  .scroll-box-palestrante::-moz-scrollbar-track {
    background-color: #b4a8a874;
  }
  
  .scroll-box-palestrante::-moz-scrollbar-thumb {
    background-color: #0d4691;
  }
  
  .scroll-box-palestrante::-moz-scrollbar-thumb:hover {
    background-color: #b4a8a874;
  }
  