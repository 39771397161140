
#categorias{
    background: #FFFFFF;
    /* Neutral/10% OP
    
    0F0F12
    */
    border-bottom: 1px solid rgba(15, 15, 18, 0.1);
    .container{
        overflow: hidden;
    }
 
    .tabs-wrapper{
        // display: flex;
        // justify-content: space-between;      
    }

    .swiper-slide{
        // max-width: max-content;
        flex: 25%;
        min-width: max-content;
        
    }
    .tab{
        // flex-basis: 33%;
        cursor: pointer;
      text-align: center;
        span{
            transition: 0.2s ease;
            padding: .4375rem 1.25rem;
            border-radius: 20px;
        }
        &.active{
           
            span{
                background: rgba(214, 223, 33, 0.2);
                
                color: #000000;
               
            }
           
        }

        &:hover{
            span{
                background: rgba(214, 223, 33, 0.2);
                border-radius: 20px;
                color: #000000;
                padding: .4375rem 1.25rem;
            }
           
        }
        a{
            padding: 1.1875rem 1rem;
            display: block;

            font-weight: 700;
            font-size: 1.125rem;
            line-height: 1.4;
            /* identical to box height, or 144% */
            
            // text-align: center;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            
            /* Neutral/40% OP */
            
            color: rgba(15, 15, 18, 0.4);
        }
    }

} 

#todos-espacos{
    background-color: #F3F3F5;
    
    .espaco{
        display: none;
        .grid-espaco{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            align-items: flex-start;
            @include d(md){
                grid-template-columns: 1fr;
            }
        }

        .col-1{

            max-width: calc(528px + 4rem);
            margin:  0 auto;
            padding: 4rem 2rem;
            width: 100%;

            @include d(md2){
                padding: 3rem 1.5rem 0;

            }

            .espaco-title{
                font-style: normal;
                font-weight: 800;
                font-size: 3.5rem;
                line-height: 114%;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #000000;
                margin-bottom: 2rem;

               @include d(md){
                font-size: 2.5rem;
                margin-bottom: 1.5rem;
               }
            }
            .content{
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 144%;
                /* or 144% */
                letter-spacing: -0.02em;

                /* Neutral/70 */

                color: #36363B;
                margin-bottom: 2rem;


                p {
                    margin-bottom: 1rem;
                }
            }


            
            .curadores{
                display: grid;
                width: 100%;

                .swiper-curadores{
                    display: grid;
                    width: 100%;
                }
                .title-curador{
                    font-weight: 700;
                    font-size: 1.125rem;
                    line-height: 144%;
                    /* identical to box height, or 144% */

                    letter-spacing: -0.02em;
                    text-transform: uppercase;

                    /* Neutral/100 */

                    color: #0F0F12;
                    margin-bottom: .75rem;
                }

                .curador-item{
                    // max-width: 150px;

                    .img-wrapper{
                        position: relative;
                        overflow: hidden;
                        padding-top: 100%;

                        img{
                            position: absolute;
                            inset: 0;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    
                    .curador-nome{
                        font-weight: 700;
                        font-size: .875rem;
                        line-height: 150%;
                        margin-top: .5rem;
                        letter-spacing: -0.02em;
                        text-transform: uppercase;
                        /* Primary/Fucsia */
                        color: #A3238E;
                    }
                }
            }
        }

        .col-2{
            display: grid;
            width: 100%;
            .swiper-espaco{
                display: grid;
                width: 100%;
                .swiper-wrapper{
                    position: unset;
                }

                .swiper-slide{
                    height: auto;
                }
            }

            .swiper-controls-custom{
                display: flex;
                align-items: center;
                background-color: #A3238E;
                padding: 1rem 1.5rem;


                .pagination{
                    color: #fff;
                }

            }


            .espaco-slider{

                .img-wrapper{
                    position: relative;
                    padding-top: 79.86%;
                    overflow: hidden;

                    img{
                        position: absolute;
                        inset: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

            }
            .buttons{
                display: flex;
                gap: 7px;
                align-items: center;

                position: absolute;
                right: 1rem;
                .button{
                    background-color: $bienal-amarelo;
                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: 50%;
                    display: grid;
                    place-content: center;
                    svg{
                        path{

                            stroke: #000
                        }
                        
                    }
                    &.swiper-button-disabled{
                        background-color: transparent;
                        border: 1px solid white;
                        svg{
                            path{

                                stroke: #fff
                            }
                            
                        }
                    }
                }
            }
        }
    }

    .espaco.show{
        display: block;
    }
}


#mosaico-info{

    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
    .grid-mosaico{
        display: grid;
        grid-template-columns: 3fr  1fr;

        @include d(md2){
            grid-template-columns: 1fr;
        }


        .mosaico-item{
            display: flex;
            @include d(md2){
                display: block;
            }

            .img-wrapper{

                img{
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }

            .text-wrapper{
                font-style: normal;
                font-weight: 600;
                font-size: 2rem;
                line-height: 106%;
                /* or 106% */
                

                text-align: center;
                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* White */

                color: #FFFFFF;
                display: grid;
                place-content: center;
                padding: 2rem;
                background-color: #A3238E;

                @include d(dt){ 
                    font-size: 1.8rem;
                }
                @include d(md){ 
                    font-size: 1.5rem;
                }
                @include d(md2){
                    min-height: 200px;
                }
            }
        }

        & .mosaico-item:nth-child(1){

            .img-wrapper{
                flex: 0 0 66%;
            }
            
        }
        & .mosaico-item:nth-child(2){}
        & .mosaico-item:nth-child(3){

            .img-wrapper{
                flex: 0 0 57%;
            }
           
            .text-wrapper{
                background-color: #DB761D;
            }
        }
        & .mosaico-item:nth-child(4){

            .text-wrapper{
                background-color: #369AA3
            }
        }
    }

}