.data-wrapper{
           
    color: #fff;

    &.isBlue,
    &.isBlue p,
    &.isBlue .local,
    &.isBlue .data-destaque{
        color: #0D4DA1;  
        border-color:#0D4DA1 ;   
    }
                
    p{
        /* Title/T2 - 36 Reg */
        font-size: 2.25rem;
        font-family: Causten;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: -0.36px;
        @include d(md2){
            font-size: 1.5rem;

        }
    }

    .data-destaque{
        border-radius: 28px;
        border: 1px solid  #3CC5B1;
    
        color:  #FFF;
        display: inline-block;
        font-size: 2.5rem;
        font-family: Causten;
        font-style: normal;
        font-weight: 700;
    
        letter-spacing: -0.4px;
        min-width: 102px;
        line-height: 1;
    
        padding: 7px 21px; 
        // display: grid;
        text-align: center; 
        margin: 0 2px;
    
        @include d(md2){
            font-size: 1.5rem;
            min-width: 60px;
            padding: 7px 16px; 
        }
    }
    p.local{
        margin-top: 12px;
        font-size: 1.375rem;
        color: #fff;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: -0.22px;
    }
}


