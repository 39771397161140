$logo-height: 48px;
$bg-color-on-scroll: white;
$bg-color-menu-open-mobile: linear-gradient(90deg, #35469c 0%, #5831a6 100%);


$color-links: #000;
$color-hamburguer: #000;

.menu-superior-info{
	background-color: #56C5D0;
	text-align: center;
	padding: 12px 0;
	p{
		font-style: normal;
		font-weight: 500;
		font-size: .875rem;
		line-height: 1.5;
	

		text-align: center;
		letter-spacing: -0.02em;
		text-transform: uppercase;

		/* Black */

		color: #000000;

	@include d(md){
		overflow: hidden;
		white-space: nowrap;
		animation: scroll-left 10s linear infinite;
		min-width: max-content;
	}
	}

	@keyframes scroll-left {
		0% { transform: translateX(100%); }
		100% { transform: translateX(-100%); }
	  }


} 


#logo {

	height: $logo-height;
	max-width: max-content;
    transition: 0.3s ease-in-out;
	position: relative;
	z-index: 3;
	display: block;

	@include d(lg){
		// height: 27px;
	}

	img{
        height: 100%;

		@include d(md2){
			// max-width: 260px;
		}
	}	
}



#header {

	background: $bg-color-on-scroll;
	position: fixed;
	
	top: 0;
	left: 0;
    right: 0;
	// top: 4rem;
	// left: 6rem;
    // right: 6rem;
	z-index: 999;
	transition: background-color 0.3s ease-in-out;
	// max-width: 100vw;

	
	
	// @include d(lg){
	// 	top: 3rem;
	// 	left: 3rem;
    //     right: 3rem;
	// }
	// @media (max-width: 1300px ){
	// 	left: 1rem;
	// 	right: 1rem;
	// }
	// @include d(md2){
	// 	left: 0;
	// 	right: 0;
	// }


    /* Note: backdrop-filter has minimal browser support */
		
	.container--menu{
		// max-width: calc(1728px + 2rem);
		max-width: calc(1600px + 3rem);
		margin: 0 auto;
		padding: 0 1.5rem;
	}
	&.sticky{
		// top: 1rem;
		
		
		.header-container{
			// padding: 12px 16px;
			// background: $bg-color-on-scroll;
			// box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, .2);      
		}
	
	}

    @media (min-width: 1024px){
        &.sticky #logo {
            // height: calc(#{$logo-height} - 10px);                   
        }
    } 
 
 @include d(md){
	#logo { 
		// height: calc(#{$logo-height} - 20px);                   
	}
 }

}

#header .header-wrapper{
	padding: 1rem 0;
	border-bottom: 1px solid rgba(15, 15, 18, 0.05);

	// transition:  0.3s ease;

	// @media (max-width: 1520px) and (min-width:1024px){
		
	// }

	@media (min-width:1024px){
		padding-bottom: 0;

		#nav{
			max-height: 1000px;
			transition: max-height 0.3s ease-in-out;

			#menu{
				opacity: 1;
				transition: 0.2s ease-in-out;
			}
			
		}
	}
}

#header.sticky{
	// @media (max-width: 1520px) and (min-width:1024px){

	// }

	@media (min-width:1024px){
		#nav{
			transform: translateY(-100%);
			overflow: hidden;
			max-height: 0;
			transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

			#menu{
				opacity: 0;
			}
			
		}
	}
}
.header-container{
	// display: flex;
	// align-items: center;
	// justify-content: space-between;

	// padding: 1.5rem;
	// background: rgba(255, 255, 255, 0.1);
	// border: 1px solid rgba(255, 255, 255, 0.2);
	// box-shadow: 0px 2px 4px rgba(76, 99, 182, 0.2);

	// border-radius: 6.25rem;
	//  max-width: 1568px;
    //  margin: 0 auto;
	// overflow: hidden;
	
	position: relative;
	transition: background  0.3s ease-in, padding 0.3s ease-in;

	@include d(md){
		display: grid;
		grid-template-columns: auto auto;
	}

	// @media (max-width: 1520px) and (min-width:1024px){

	// }

	@media (min-width:1024px){
		display: grid;
		grid-template-columns: 1fr auto;  
		padding-bottom: 0;
		#nav{ 
			border-top: 1px solid rgba(27, 29, 33, 0.10);
			
			grid-row: 2;
			grid-column: 1/-1;

			margin-top: 1rem;
			padding-bottom: 0;
		}

	}
	
}

#header .button-wrapper{
	display: flex;
	align-items: center;
	gap: 1rem;
	// box-shadow: 6px 6px #1d336f !important;
	.button{
		@include BotaoMain(#3CC5B1);
		// &[data-btn]{
		// 	display: none;
		// }

		// @include BotaoTeste(red, blue);
		// 
		&.secondary{
			background-color: #fff;
			border: 1px solid #297B6F; 
			color: #297B6F;
			@include d(md){
				margin-left: 0;
			}
			span{ 
				color:#297B6F;
			}
		}
	}
}
#menu {
	display: flex;
    align-items: center;
	
	position: relative;
	z-index: 3;
	text-align: center;

	justify-content: flex-end;
	justify-content: center;
	flex-wrap: wrap;
	@include d(md){
		text-align: left;
	}

    li+li{
        margin-left: 1rem;

		@include d(lg){
			margin-left: 10px;
		}
    }
	
	.button-wrapper.button-mobile{
		display: none;
	}

	.button-wrapper{
		@include d(md){
			margin-top: 2rem;
		}
	}
	.button{
		
		// @include botao-primary(#3B4EA1,  #fff, #98AEEB);
		// @include botao-primary(#fff,  #5067B9);
		// @include Botao(red, blue);
		// height: 100%;	
		margin-left: 2rem;
		// box-shadow: 0px 2px 4px rgba(76, 99, 182, 0.4);
		// max-width: max-content;
		// transition: transform 0.2s ease;

		@include d(md){
			// margin-top: 2rem;
		}
		@include d(sm){
			width: 100%;
		}
		svg{ 
			width: 25px;
			height: 26px;
			
		}

		&:hover{
			transform: scale(1.05);
			
		}
	}
}

#menu a.link {
	display: block;
	padding: .5rem;
    color: $color-links;
    line-height: 120%;
    font-size: 1rem;	
	transition: transform 0.2s ease;

	font-family: $font-1;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.5;
	/* identical to box height, or 157% */

	letter-spacing: -0.02em;
	text-transform: uppercase;

	/* Neutral/100 */

	color: #0F0F12;

	&:hover{
		transform: scale(1.05);
		@include d(md){
			transform: scale(1);
		}
	}
}

#btn-mobile {
	display: none;
	position: relative;
	z-index: 4;	
}



@include d(md){


	

	#header .header-wrapper .button-wrapper{
		display: none;
	}
	#menu .button-wrapper.button-mobile{
		display: block;

		display: grid;
		place-content: center;
		grid-template-columns: 1fr;
		padding: 0 1rem;
		
		.button{
			margin-left: 0;
			@include d(sm){
				max-width: 100%;
				justify-content: center;
				// box-shadow: 2.5px 2.5px  #EF7D00;
			}
		}
	}
	
  
   
	#btn-mobile {
		display: block;      
	}
	
	#menu {
		display: block;
		position: fixed;
		
		width: 100%;
		// top: calc(#{$logo-height} + 2.5rem ); 
		top: 77px; 
		left: 0;
        bottom: 0;
		
        transform: translateX(-100%);
		transition: 0.3s ease-in-out;
		opacity: 0;
		z-index: 1000;
		
		overflow-y: hidden;


	} 

	#nav.active #menu {

        transform: translateX(0);
		overflow-y: auto;
        opacity: 1;
		// background: $bg-color-menu-open-mobile;
		// background-color: #fff;
		background-color: #fff;;
	}
	#menu {
		
		// padding-top: 2rem;
		padding-top: 3rem;
		padding-bottom: 3rem;


		&::before{
			content: 'Menu';
			display: block;
			margin: 0 1rem;


			font-weight: 500;
			font-size: 1.125rem;
			line-height: 1.3;
			/* identical to box height, or 144% */

			letter-spacing: -0.02em;
			text-transform: uppercase;

			/* Primary/Verde */

			color: #3CC5B1;;
		}

		.menu-li{
			a{

				font-weight: 500;
				font-size: 1.5rem;
				line-height: 1.4;
				letter-spacing: -0.02em;
				text-transform: uppercase;
			}
		}
		li {

			
		}
        li+li{
            margin-left: 0;
        }
        a.link {
            padding: 8px 0;
            margin: 0 1rem;
            // border-bottom: 2px solid rgba(0, 0, 0, .05);
			// color: #fff;
        }
    }

	#btn-mobile {
		display: flex;
		padding: .5rem 1rem;
		font-size: 1rem;
		border: none;
		background: none;
		cursor: pointer;
		gap: .5rem;
        color: $color-hamburguer;
		background-color: #3CC5B1;
		padding: 1rem 1rem;
		border-radius: 3px;
		margin-left: auto;
		// box-shadow: 2.5px 2.5px #a11c64;
	}
	#hamburger {
		display: block;
		border-top: 2px solid;
		width: 20px;
       &::after,&::before {
            content: '';
            display: block;
            width: 20px;
            height: 2px;
            background-color: currentColor;
            margin-top: 5px;
            transition: .3s;
            position: relative;
        }
	} 

	#nav.active #hamburger {
		border-top-color: transparent;
		color:  $color-hamburguer;

        &::before {
            transform: rotate(135deg);
        }
    
        &::after {
            transform: rotate(-135deg);
            top: -7px;
        }
        
	}	
}


 
body.menu--open{
	.menu-superior-info{
		// display: none;
	}

	#header .header-wrapper{
		// transform: translateY(-42px);
		position: relative;
		// top: -42px;
		// background-color: #D6DF21;

		#logo{
			// display: none;
			// opacity: 0;
		}
	
		#btn-mobile{
			background-color: #3CC5B1;
			box-shadow: none;
		}
		#nav.active #hamburger{
			// color:#D6DF21; ;
		}
	}
}

// @include d(sm){
// 	#header{
// 		top: 2rem;
// 	}
// }




