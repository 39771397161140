&{

    @media (min-width:1024px){
        overflow-x:initial;

        main{
            overflow: initial;
        }
    }
}



#programacao{


    .grid-programacao{
        display: grid;
        grid-template-columns: 23.4375rem 1fr;
        align-items: flex-start;
        gap: 2rem;
        @include d(lg){
            grid-template-columns: 19rem 1fr;
        }
        @include d(md){
            grid-template-columns: 1fr;
        }
       
    }

    .div-to-sticky{
        position: sticky;
        top: 140px;


        .img-menina{
            // position: absolute;
            // bottom: 0;
            margin-top: 100px;
            @include d(md){
                display: none;
            }
        }
    }

    .filtrar-por-data{
        border: 1px solid rgba(15, 15, 18, 0.1);
        border-radius: 3px;
        padding: 12px 16px;
        cursor: pointer;
        display: none;
        margin-bottom: 1rem;
        background: #fff;

        @include d(md){
            display: block;
        }

        .flex-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        p{
            font-weight: 500;
            font-size: 18px;
            line-height: 1.2;
            /* identical to box height, or 122% */

            letter-spacing: -0.02em;

            /* Neutral/40% OP */

            color: rgba(15, 15, 18, 0.4);
        }
        svg{
            width: 24px;
            height: 24px;
            flex-shrink: 0;
        }
    }


    .info-download-wrapper{
        margin-top: 2rem;

        p{
            font-weight: 500;
            font-size: 14px;
            line-height: 1.5;
            /* identical to box height, or 157% */
            letter-spacing: -0.02em;
            text-transform: uppercase;
            /* Neutral/100 */
            color: #0F0F12;
            margin-bottom: 1rem;
        }
        .button{

            @include BotaoMain(#3CC5B1);

            span{
                font-size: 12px;
            }

            svg{
                width: 18px;
                height: 18px;
                margin-left: 0;
                margin-right: 8px;
            }
        }
    }

    .sidebar{
        border: 1px solid rgba(15, 15, 18, 0.1);
        border-radius: 3px;
        padding: 1rem;
        background-color: #fff;

        @include d(md){
            overflow: hidden;
            max-height: 0;
            
            border-width: 0;
            padding: 0;
            transition: 0.2s ease-in;
            &.open{

                max-height: 1000px;
                      
                border-width: 1;
            }
        }
    
    
        .dias-wrapper{
    
    
    
            li{
                padding: 12px;
                cursor: pointer;
                .dia-semana{
                    font-weight: 500;
                    font-size: 14px;
                    line-height:1.5;
                    /* identical to box height, or 157% */
    
                    
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
    
                    /* Neutral/50% OP */
    
                    color: #7B7C7F;
                }
                .dia-data{
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 26px;
                    /* identical to box height, or 144% */
    
                    
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
    
                    /* Primary/Fucsia */
    
                    color: #0D4DA1;
    
    
                }
                &.active{
                    background-color: #0D4DA1;
                    border:  4px solid #3CC5B1;
                    padding: 12px;
                    color: #fff;
                    
                    .dia-semana{
                        color: rgba(255, 255, 255, 0.70);;
                        font-weight: 700;
                    }
                    .dia-data{
                        color: #FFFFFF;
                    }
                }
             
            }
        }
       
    }

    .programacoes{
        display: grid;
        
        width: 100%;
        max-width: 1050px;
        margin-left: auto;
    }
    .programacao{
        margin-bottom: 2rem;
    }
    .horario-wrapper{
        background: #FFFFFF;
      
        // border: 1px solid #A3238E;
        padding: 1.5rem;
        margin-bottom: 2rem;
        background: linear-gradient(133deg, rgba(231, 238, 246, 0.77) 0%, rgba(231, 238, 246, 0.85) 51.30%, rgba(143, 217, 211, 0.24) 99.99%, rgba(60, 197, 177, 0.06) 100%), #FFF;
        @include d(md){
            padding: 1rem;
        }

        p{
            font-weight: 600;
            font-size: 24px;
            line-height: 132%;
            /* identical to box height, or 32px */
            letter-spacing: -0.02em;
            text-transform: uppercase;
            /* Primary/Fucsia */
            color: #0D4DA1;
        }
    }
   

    .dia-atracoes-wrapper{
        padding: 1.5rem;
        display: grid;
        align-items: flex-start;
        width: 100%;
        background-color: #fff;
        color: #1B1D21;
        border: 1px solid rgba(27, 29, 33, 0.10);
        border-radius: 3px;
        margin-bottom: 3px;
        
        
        .mesa-redonda{
            color: #3B9C8E;
           
           
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; /* 114.286% */
            letter-spacing: 1.12px;
            text-transform: uppercase;
        }

        .jornada-wrapper{
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
            border-bottom: 1px solid rgba(27, 29, 33, 0.10);
        }
        .jornada-title{

            margin-bottom: 16px;

            color: #1B1D21;
         
            font-size: 1.625rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.3; /* 123.077% */
            letter-spacing: -0.26px;
        }
        .content-for-p{

            p:not(.continuar-lendo) {
                font-style: normal;
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 1.44;
                /* or 144% */
                letter-spacing: -0.02em;
                /* White */   
                color: #1B1D21;
                @include d(md){
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &.open{
                p:not(.continuar-lendo){
                    display: block;
                }
            }
          

          
        }

       

        .debatedores-grid{
            .debate-text{
                color: #000;
            
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; /* 150% */
                letter-spacing: -0.16px;
                margin-bottom: 8px
            }
            .debatedores-wrapper{
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                gap: 2rem;
            }
        }
    }

    .line-grey{
        position: relative;
        width: 1px;
        align-self: stretch;
        // max-height: 40px;
     
     
        &::after{
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #D7DADB;
            height: 68%;
            left: 0;
            right: 0;
        }
    }
   .infos-sala-wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;

    .line-grey{
        @include d(md){
            display: none;
        }
    }
   }

   .mod-text{
    color: #000;
  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
    margin-bottom: 8px
   }
    .tags{
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        // margin-top: 1rem;
        margin-bottom: 1rem;
         span{
             border-radius: 12px;
             border: 1px solid  #1B1D21;
             display: inline-block;
             padding: 6px 8px;
           
           
             font-size: 12px;

             font-style: normal;
             font-weight: 500;
             line-height: 10px;
             letter-spacing: 0.96px;
             text-transform: uppercase;
         }
     }

     .local-wrapper{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 2rem;
        row-gap: 10px;

        .line-grey{
            // display: block;
        }
        .local{
            display: flex;
            align-items: center;

            p{
                color:  #1B1D21;
                font-family: Causten;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
                letter-spacing: -0.16px;
                span{
                    font-weight: 600;
                    color: #000;
                }
            }
            svg{
                width: 24px;
                height: 24px;
                filter: brightness(0) saturate(100%) invert(20%) sepia(80%) saturate(1721%) hue-rotate(199deg) brightness(99%) contrast(99%);
            }
        }
     }

     .debate-item{
        display: flex;
        align-items: center;
        gap: 8px;
        .debate-img{
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            overflow: hidden;
            img{
                width:100%;
                height:100%;
                object-fit: cover;
              
               
            }
        }
        .debate-infos{
            .debate-nome{
                color:  #1B1D21;
                
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 1.5; /* 157.143% */
                letter-spacing: -0.14px;
                text-transform: uppercase;
            }
            .debate-info{
                color:  #3B4042;              
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5; /* 157.143% */
                letter-spacing: -0.14px;
            }
        }
     }
   
}