#modal-atracoes {
  position: fixed;
  z-index: 9999;
  background-color: #fff;
  color: #1B1D21;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 625px;
  max-width: 100%;
  
  // max-height: 690px;
  max-height: 725px;
  height: 90%;
  border-radius: 6px;
  // overflow: hidden;

  @media (min-width: 1024px){
    // max-height: 98%;
  }
  @include d(lg){
    max-height: 675px;
  }

  
  @include d(md2){
    // max-height: 100%
    // max-height: 776px;
    max-height: 700px;
  }

  &.closed {
    display: none;
  }

  

  .modal-header {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    margin-top: -1px;
    border-radius: 3px 3px 0 0;
  }
  #close-button {
    margin-top: -1rem;
    margin-right: -1rem;
    background: #0D4691;
    width: 3rem;
    height: 3rem;
    display: grid;
    place-content: center;
    border-radius: 50%;
    cursor: pointer;
    @include d(md2){
      margin-right: 0;
    }
    svg{
      filter: brightness(0) invert(1);
    }
  }

  .titulo{
    color:  #0D4DA1;
    font-size: 1.5rem;
    font-family: Causten;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.24px;
    text-transform: uppercase;
    margin-bottom: 2rem; 

    max-width: 36ch;
  }
  .texto-politica{
    font-size: 14px;
    margin-bottom: 2.5rem;
    margin-top: -1rem;
  }

  .modal-guts {
    /* other stuff we already covered */

    /* cover the modal */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /* spacing as needed */
    // padding: 45px 50px 20px 0;
    padding: 2rem;

    /* let it scroll */
    overflow: auto;
  }
}

#modal-overlay {
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  &.closed {
    display: none;
  }
}


// STYLE 
#modal-atracoes{

    .header-wrapper-modal{
        display: flex;
        align-items: flex-start;
        gap: 2rem;

        @include d(md2){
            flex-direction: column-reverse;
        }



    }

    
    .atracao-img-modal{
        position: relative;
        max-width: 316px;
        width: 100%;

        flex-shrink: 0;


        @include d(md2){
            display: none;
        }
        &-mobile{
            display: none;

            @include d(md2){
                display: block;
                margin-top: 1.5rem;
            }
        }

        @include d(md){
            max-width: 260px;

            
        }
    }
    .card-evento-img{

        img{
            border-radius: 3px;
            width: 100%;
            min-height: 250px;
            object-fit: cover;
        }
    }

    .content{
        background-color: #56C5D0;
        max-width: calc(100% - 1.25rem);
        padding:  1rem 1.5rem;

        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        @include d(lg){
            padding: 10px 12px;
        }


        .icon-wrapper{
            position: absolute;
            right: 0;
            top: 0;
            background-color: #D6DF21;
            padding: .375rem;
            border-radius: 0 0 0 6px;
            svg{
                width: 1.25rem;
                height: 1.3125rem;
            }
        }

        .card-evento-title{
      
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.3;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #0F0F12;


            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            @include d(lg){
                font-size: 1.3rem;
            }


        }
        .card-evento-role{
            font-style: normal;
            font-weight: 500;
            font-size: 1.125rem;
            line-height: 1.4;
         

            letter-spacing: -0.02em;
            text-transform: uppercase;

            color: rgba(0, 0, 0, 0.6);;
            
            @include d(lg){
                font-size: 1rem;
            }
        }
    }
    
   


    .infos-tragetoria{
        padding-top: 3rem;

        @include d(md2){
            padding-top: 2rem;
           
        }

        h2{
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.33;
            /* identical to box height, or 133% */
            
            letter-spacing: -0.02em;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }

        .paragraphs-content{
           
            p{
                margin-bottom: 1rem;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5;
                /* or 150% */
    
                letter-spacing: -0.02em;
    
                /* White */
    
                color: #FFFFFF;
            }
        }
    }

    .modal-guts{
        @include d(md2){
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }

    .programacao{

        padding: 2rem 3rem;
        padding-bottom: 0;
        padding-right: 0;

        @include d(md2){
            padding-left:0;
        }
        .title-programacao{
            font-weight: 700;
            font-size: 1.125rem;
            line-height: 1.3;
            text-transform: uppercase;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            padding-bottom: 10px;
            margin-bottom: 1.5rem;
        } 

        
        .wrapper-programacao{
            display: flex;
            align-items: flex-start;
            gap: 4rem;

            @include d(md2){
                flex-direction: column;
                gap: 1rem;
            }
            
        }
        .nome-evento-wrapper{

            .confirmado{
                font-weight: 500;
                font-size: 14px;
                line-height: 1.5;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #D6DF21;
                margin-bottom: 8px;
            }
            .nome-evento{
                font-weight: 800;
                font-size: 1.5rem;
                line-height: 1.33;

                letter-spacing: -0.02em;
                text-transform: uppercase;

                /* White */

                color: #FFFFFF;
            }
        }

    }
}




/* Webkit based browsers (Chrome, Safari) */
.scroll-box::-webkit-scrollbar {
  width: 8px;
}

.scroll-box::-webkit-scrollbar-track {
  background-color: #55555574;
}

.scroll-box::-webkit-scrollbar-thumb {
  background-color: #0d4691;
}

.scroll-box::-webkit-scrollbar-thumb:hover {
  background-color: #55555574;
}

/* Firefox */
.scroll-box {
  scrollbar-width: thin;
  scrollbar-color: #0d4691 #55555574;
}

.scroll-box::-moz-scrollbar {
  width: 8px;
}

.scroll-box::-moz-scrollbar-track {
  background-color: #55555574;
}

.scroll-box::-moz-scrollbar-thumb {
  background-color: #0d4691;
}

.scroll-box::-moz-scrollbar-thumb:hover {
  background-color: #55555574;
}
