.section-title{
    position: relative;

    .badge{
        position: relative;
        display: flex;
        align-items: center;
        gap: 1rem;

        color:  #3B9C8E;
      
        font-size: 14px;
    
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 1.12px;
        text-transform: uppercase;

        margin-bottom: 8px;
        &::before{
            content: '';
            width: 5px;
            height: 5px;
            display: block;
            background-color: currentColor;
        }
    }

    h2{
        color:  #0D4DA1;       
        font-size: 3rem;
       
        font-style: normal;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -0.48px;
        text-transform: uppercase;
        // @include d(lg){
        //     font-size: 3rem;
        // }
        // @include d(md2){
        //     font-size: 2.5rem;
        // }
    }
    .section-description{
       
        color:  #34383B;

        /* Paragraph/P2 - 18 Lig */
        font-size: 1.125rem;
      
        font-style: normal;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.18px;
    }

}