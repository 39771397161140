body {
  --white: #fff;
  --white-70-op: #ffffffb3;
  --white-10-op: #ffffff1a;
  --black: #000;
  --neutral-100: #090a1a;
  --neutral-70: #464651;
  --neutral-60: #666670;
  --neutral-50: #090a1a80;
  --neutral-40: #090a1a66;
  --neutral-30: #e5e6eb;
  --neutral-20: #f0f1f5;
  --neutral-10: #f5f6fa;
  --primary-light: #8186f7;
  --primary-light-10-op: #8288f71a;
  --primary-light-30: #a8abfe;
  --primary-pure-10: #eaeaf8;
  --primary-pure: hsla(var(--hue-primary), 64%, 54%, 1);
  --primary-pure-70: #6a6ed1;
  --hue-primary: 238;
  --alert-error: #e92c2c;
  --alert-error-10: #fdeaea;
  --alert-warning: #d2af00;
  --alert-warning-10: #fbf7e6;
  --alert-sucess: #008024;
  --alert-sucess-10: #e6f9f0;
  --bienal-amarelo: #d6df21;
  --bienal-rosa: #a3238e;
  --bienal-laranja: #f58220;
}

@font-face {
  font-family: Causten;
  font-weight: 300;
  src: url("Causten-Thin.37ee4ab8.otf") format("opentype");
}

@font-face {
  font-family: Causten;
  font-weight: 400;
  src: url("Causten-Regular.6cb166d6.otf") format("opentype");
}

@font-face {
  font-family: Causten;
  font-weight: 500;
  src: url("Causten-Medium.948ba101.otf") format("opentype");
}

@font-face {
  font-family: Causten;
  font-weight: 600;
  src: url("Causten-SemiBold.a4cf7e3d.otf") format("opentype");
}

@font-face {
  font-family: Causten;
  font-weight: 700;
  src: url("Causten-Bold.cace4ca6.otf") format("opentype");
}

@font-face {
  font-family: Causten;
  font-weight: 800;
  src: url("Causten-ExtraBold.245d0a3c.otf") format("opentype");
}

body, p, h1, h2, ul, li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

ol {
  padding: 0;
}

a {
  color: #000;
  text-decoration: none;
}

:after, :before {
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

button {
  background: initial;
  border: initial;
  -webkit-appearance: none;
  font-family: Causten, sans-serif;
}

button:active {
  border-style: initial;
}

button:focus {
  outline: initial;
}

button[type="button"], button[type="submit"], button[type="reset"] {
  -webkit-appearance: none;
}

button[type="submit"] {
  -webkit-appearance: none;
  appearance: none;
}

img {
  object-fit: contain;
  max-width: 100%;
  display: block;
}

fieldset {
  -webkit-margin-start: 0px;
  -moz-margin-start: 0px;
  -webkit-margin-end: 0px;
  -moz-margin-end: 0px;
  border: 2px #0000;
  border-image: initial;
  min-inline-size: min-content;
  margin-inline: 0;
}

figure {
  margin-block: 0;
  margin-inline: 0;
  display: block;
}

:focus {
  outline: 0 #0000 !important;
}

input[type="submit"] {
  -webkit-appearance: none;
  appearance: none;
}

input, [type="button"], select {
  -webkit-appearance: none;
}

:root {
  --xl-device: 1920px;
  --lg-device: 1440px;
  --dt-device: 1200px --md-device: 1024px;
  --md2-device: 768px;
  --sm-device: 600px;
}

@keyframes volta_menu {
  to {
    -webkit-clip-path: circle(0% at 80% 0);
    clip-path: circle(0% at 80% 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

@keyframes opacidade_1 {
  to {
    opacity: 1;
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

body {
  color: #3b4042;
  opacity: 0;
  background-color: #f7f8f9;
  font-family: Causten, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  overflow-x: hidden;
}

body.dcl {
  opacity: 1;
}

body a {
  font-family: Causten, sans-serif;
}

p {
  letter-spacing: -.18px;
  font-family: Causten, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;
  position: relative;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1100px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: calc(1600px + 3rem);
  }
}

html {
  --font-base: 100%;
  font-size: var(--font-base);
  scroll-behavior: smooth;
  overflow-x: hidden;
}

@media (max-width: 1440px) {
  html {
    font-size: calc(var(--font-base)  - 9.25%);
  }
}

@media (max-width: 980px) {
  html {
    font-size: calc(var(--font-base)  - 12.5%);
  }
}

.menu-superior-info {
  text-align: center;
  background-color: #56c5d0;
  padding: 12px 0;
}

.menu-superior-info p {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  font-size: .875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

@media screen and (max-width: 1024px) {
  .menu-superior-info p {
    white-space: nowrap;
    min-width: max-content;
    animation: 10s linear infinite scroll-left;
    overflow: hidden;
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

#logo {
  z-index: 3;
  max-width: max-content;
  height: 48px;
  transition: all .3s ease-in-out;
  display: block;
  position: relative;
}

#logo img {
  height: 100%;
}

#header {
  z-index: 999;
  background: #fff;
  transition: background-color .3s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

#header .container--menu {
  max-width: calc(1600px + 3rem);
  margin: 0 auto;
  padding: 0 1.5rem;
}

#header .header-wrapper {
  border-bottom: 1px solid #0f0f120d;
  padding: 1rem 0;
}

@media (min-width: 1024px) {
  #header .header-wrapper {
    padding-bottom: 0;
  }

  #header .header-wrapper #nav {
    max-height: 1000px;
    transition: max-height .3s ease-in-out;
  }

  #header .header-wrapper #nav #menu {
    opacity: 1;
    transition: all .2s ease-in-out;
  }

  #header.sticky #nav {
    max-height: 0;
    transition: max-height .3s cubic-bezier(0, 1, 0, 1);
    overflow: hidden;
    transform: translateY(-100%);
  }

  #header.sticky #nav #menu {
    opacity: 0;
  }
}

.header-container {
  transition: background .3s ease-in, padding .3s ease-in;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .header-container {
    grid-template-columns: auto auto;
    display: grid;
  }
}

@media (min-width: 1024px) {
  .header-container {
    grid-template-columns: 1fr auto;
    padding-bottom: 0;
    display: grid;
  }

  .header-container #nav {
    border-top: 1px solid #1b1d211a;
    grid-area: 2 / 1 / auto / -1;
    margin-top: 1rem;
    padding-bottom: 0;
  }
}

#header .button-wrapper {
  align-items: center;
  gap: 1rem;
  display: flex;
}

#header .button-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #3cc5b1;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

#header .button-wrapper .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

#header .button-wrapper .button:active, #header .button-wrapper .button:hover {
  transform: scale(1.05);
}

#header .button-wrapper .button svg, #header .button-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

#header .button-wrapper .button svg {
  width: 24px;
  height: 24px;
}

#header .button-wrapper .button.secondary {
  color: #297b6f;
  background-color: #fff;
  border: 1px solid #297b6f;
}

@media screen and (max-width: 1024px) {
  #header .button-wrapper .button.secondary {
    margin-left: 0;
  }
}

#header .button-wrapper .button.secondary span {
  color: #297b6f;
}

#menu {
  z-index: 3;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1024px) {
  #menu {
    text-align: left;
  }
}

#menu li + li {
  margin-left: 1rem;
}

@media screen and (max-width: 1440px) {
  #menu li + li {
    margin-left: 10px;
  }
}

#menu .button-wrapper.button-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  #menu .button-wrapper {
    margin-top: 2rem;
  }
}

#menu .button {
  margin-left: 2rem;
}

@media screen and (max-width: 600px) {
  #menu .button {
    width: 100%;
  }
}

#menu .button svg {
  width: 25px;
  height: 26px;
}

#menu .button:hover {
  transform: scale(1.05);
}

#menu a.link {
  color: #0f0f12;
  letter-spacing: -.02em;
  text-transform: uppercase;
  padding: .5rem;
  font-family: Causten, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  transition: transform .2s;
  display: block;
}

#menu a.link:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 1024px) {
  #menu a.link:hover {
    transform: scale(1);
  }
}

#btn-mobile {
  z-index: 4;
  display: none;
  position: relative;
}

@media screen and (max-width: 1024px) {
  #header .header-wrapper .button-wrapper {
    display: none;
  }

  #menu .button-wrapper.button-mobile {
    grid-template-columns: 1fr;
    place-content: center;
    padding: 0 1rem;
    display: grid;
  }

  #menu .button-wrapper.button-mobile .button {
    margin-left: 0;
  }
}

@media screen and (max-width: 1024px) and (max-width: 600px) {
  #menu .button-wrapper.button-mobile .button {
    justify-content: center;
    max-width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  #btn-mobile {
    display: block;
  }

  #menu {
    opacity: 0;
    z-index: 1000;
    width: 100%;
    transition: all .3s ease-in-out;
    display: block;
    position: fixed;
    top: 77px;
    bottom: 0;
    left: 0;
    overflow-y: hidden;
    transform: translateX(-100%);
  }

  #nav.active #menu {
    opacity: 1;
    background-color: #fff;
    overflow-y: auto;
    transform: translateX(0);
  }

  #menu {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  #menu:before {
    content: "Menu";
    letter-spacing: -.02em;
    text-transform: uppercase;
    color: #3cc5b1;
    margin: 0 1rem;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.3;
    display: block;
  }

  #menu .menu-li a {
    letter-spacing: -.02em;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.4;
  }

  #menu li + li {
    margin-left: 0;
  }

  #menu a.link {
    margin: 0 1rem;
    padding: 8px 0;
  }

  #btn-mobile {
    cursor: pointer;
    color: #000;
    background: #3cc5b1;
    border: none;
    border-radius: 3px;
    gap: .5rem;
    margin-left: auto;
    padding: 1rem;
    font-size: 1rem;
    display: flex;
  }

  #hamburger {
    border-top: 2px solid;
    width: 20px;
    display: block;
  }

  #hamburger:after, #hamburger:before {
    content: "";
    background-color: currentColor;
    width: 20px;
    height: 2px;
    margin-top: 5px;
    transition: all .3s;
    display: block;
    position: relative;
  }

  #nav.active #hamburger {
    color: #000;
    border-top-color: #0000;
  }

  #nav.active #hamburger:before {
    transform: rotate(135deg);
  }

  #nav.active #hamburger:after {
    top: -7px;
    transform: rotate(-135deg);
  }
}

body.menu--open #header .header-wrapper {
  position: relative;
}

body.menu--open #header .header-wrapper #btn-mobile {
  box-shadow: none;
  background-color: #3cc5b1;
}

.floating-button {
  z-index: 99;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

@media screen and (max-width: 768px) {
  .floating-button {
    bottom: 1rem;
  }
}

.floating-button.button-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #3cc5b1;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

.floating-button.button-wrapper .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.floating-button.button-wrapper .button:active, .floating-button.button-wrapper .button:hover {
  transform: scale(1.05);
}

.floating-button.button-wrapper .button svg, .floating-button.button-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

.floating-button.button-wrapper .button svg {
  width: 24px;
  height: 24px;
}

#footer-bienal {
  background-color: #eeeef0;
  border-top: 1px solid #1b1d211a;
}

#footer-bienal .footer-wrapper {
  margin-bottom: 2rem;
  padding-top: 2.375rem;
}

#footer-bienal .footer-wrapper .container:after {
  content: "";
  background-color: #1b1d211a;
  width: 200vw;
  height: 1px;
  position: absolute;
  bottom: 46px;
  left: -50vw;
}

@media screen and (max-width: 1024px) {
  #footer-bienal .footer-wrapper .container:after {
    display: none;
  }
}

#footer-bienal .footer-wrapper .grid-footer {
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 1rem;
  display: grid;
}

@media (max-width: 1400px) {
  #footer-bienal .footer-wrapper .grid-footer {
    grid-template-columns: 1fr;
  }

  #footer-bienal .footer-wrapper .grid-footer .col-1 {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

@media screen and (max-width: 1024px) {
  #footer-bienal .footer-wrapper .grid-footer {
    grid-template-columns: 1fr;
  }

  #footer-bienal .footer-wrapper .grid-footer .col-1 {
    display: block;
  }
}

#footer-bienal .footer-wrapper .logo-wrapper {
  max-width: 350px;
  padding-bottom: 2rem;
}

@media (max-width: 1500px) {
  #footer-bienal .footer-wrapper .logo-wrapper {
    max-width: 200px;
  }
}

#footer-bienal .links-footer {
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  margin-bottom: 4rem;
  display: flex;
}

@media (max-width: 1400px) {
  #footer-bienal .links-footer {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1024px) {
  #footer-bienal .links-footer {
    display: block;
  }
}

#footer-bienal .links-footer ul {
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 1024px) {
  #footer-bienal .links-footer ul {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    display: grid;
  }
}

#footer-bienal .links-footer ul li + li {
  margin-left: 1.5rem;
}

@media screen and (max-width: 1024px) {
  #footer-bienal .links-footer ul li + li {
    margin-left: 0;
  }
}

#footer-bienal .links-footer ul li:hover {
  text-decoration: underline;
}

#footer-bienal .links-footer ul li a {
  color: #1b1d21;
  text-align: center;
  letter-spacing: -.16px;
  font-family: Causten;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

#footer-bienal .links-footer .button-wrapper {
  flex-shrink: 0;
}

@media screen and (max-width: 1024px) {
  #footer-bienal .links-footer .button-wrapper {
    margin-top: 2rem;
  }
}

#footer-bienal .links-footer .button-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #297b6f;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

#footer-bienal .links-footer .button-wrapper .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

#footer-bienal .links-footer .button-wrapper .button:active, #footer-bienal .links-footer .button-wrapper .button:hover {
  transform: scale(1.05);
}

#footer-bienal .links-footer .button-wrapper .button svg, #footer-bienal .links-footer .button-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

#footer-bienal .links-footer .button-wrapper .button svg {
  width: 24px;
  height: 24px;
}

#footer-bienal .links-footer .button-wrapper .button.secondary {
  color: #297b6f;
  background-color: #0000;
  border: 1px solid #297b6f;
}

@media screen and (max-width: 768px) {
  #footer-bienal .links-footer .button-wrapper .button.secondary {
    max-width: unset;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }
}

#footer-bienal .links-footer .button-wrapper .button.secondary span {
  color: #297b6f;
}

#footer-bienal .socials ul {
  align-items: center;
  gap: 9px;
  display: flex;
}

#footer-bienal .socials ul li {
  transition: all .2s ease-in;
}

#footer-bienal .socials ul li:hover {
  transform: scale(1.05);
}

#footer-bienal .socials ul a {
  padding: 2px;
  display: block;
}

#footer-bienal .socials ul svg {
  color: #3b9c8e;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 768px) {
  #footer-bienal .socials ul svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}

#footer-bienal .swiper-parceiros .swiper-wrapper, #footer-bienal .swiper-parceiros2 .swiper-wrapper, #footer-bienal .swiper-parceiros3 .swiper-wrapper {
  transition-timing-function: linear;
}

#footer-bienal .swiper-parceiros .swiper-wrapper .swiper-slide, #footer-bienal .swiper-parceiros2 .swiper-wrapper .swiper-slide, #footer-bienal .swiper-parceiros3 .swiper-wrapper .swiper-slide {
  height: auto;
}

#footer-bienal .parceiros .section-title {
  margin-bottom: 2.5rem;
}

#footer-bienal .parceiros .section-title h2 {
  text-align: center;
  font-size: 2rem;
}

#footer-bienal .parceiros .swiper-slide {
  max-width: max-content;
}

#footer-bienal .parceiros .parceiro {
  background-color: #fff;
  border: 1px solid #1b1d211a;
  border-radius: 8px;
  place-content: center;
  min-width: 240px;
  max-width: max-content;
  height: 100%;
  padding: 12px 2rem;
  display: grid;
}

#footer-bienal .parceiros .parceiro img {
  max-height: 48px;
  margin: 0 auto;
}

#footer-bienal .bottom-footer {
  color: #fff;
  background: linear-gradient(-90deg, #0d4da1 0%, #47baa9 100%);
  padding: 1.2rem 0;
}

#footer-bienal .bottom-footer .container {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 5px 1.5rem;
  display: flex;
}

#footer-bienal .bottom-footer p {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;
}

#footer-bienal .bottom-footer .ndt-wrapper {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

#footer-bienal .bottom-footer .ndt-wrapper img {
  padding-bottom: 7px;
}

#footer-bienal .termos-footer a {
  color: #fff;
}

#login {
  --hue-primary: 214;
  --white-10-op: #ffffff1a;
  --white: #fff;
  --neutral-10: #d7dadb;
  --primary-light: #1e66c3;
  --primary-light-10-op: #1e65c21a;
  --white-70-op: #ffffffb3;
  --primary-pure: hsla(var(--hue-primary), 85%, 34%, 1);
  background: linear-gradient(90deg, hsla(var(--hue-primary), 100%, 3%, 1) 0%, hsla(var(--hue-primary), 100%, 3%, 1) 100%);
  height: 100vh;
  min-height: 900px;
  padding-top: 3.75rem;
  position: relative;
  overflow: hidden;
}

#login .ornamento {
  background: linear-gradient(92.74deg, hsla(var(--hue-primary), 68%, 32%, 1) 0%, hsla(var(--hue-primary), 100%, 73%, 1) 100%);
  border-radius: 11.875rem;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  max-height: 394px;
  margin: 0 auto;
}

@media (max-width: 1660px) {
  #login .ornamento {
    width: 90%;
    padding: 3rem;
  }
}

@media screen and (max-width: 1440px) {
  #login .ornamento {
    height: 40%;
  }
}

@media screen and (max-width: 600px) {
  #login .ornamento {
    margin: unset;
    border-radius: 0 0 16px 16px;
    width: 100%;
    height: 349px;
  }
}

#login .ornamento .logo-cliente {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding-top: 4.6875rem;
}

@media (max-width: 1660px) {
  #login .ornamento .logo-cliente {
    padding-top: 2.75rem;
  }
}

@media screen and (max-width: 1440px) {
  #login .ornamento .logo-cliente {
    padding-top: 2rem;
  }
}

#login .orn-sections {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

#login .orn-sections img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#login .logo-ndt {
  position: absolute;
  bottom: 72px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 1440px) {
  #login .logo-ndt {
    bottom: 64px;
  }
}

#login .form-wrapper {
  border: 1px solid var(--white-10-op);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  background: #00000080;
  border-radius: 8px;
  width: 100%;
  max-width: 428px;
  height: -moz-fit-content;
  height: fit-content;
  margin: -210px auto 0;
  padding: 2rem;
}

@media screen and (max-width: 1440px) {
  #login .form-wrapper {
    margin-top: -150px;
  }
}

@media screen and (max-width: 600px) {
  #login .form-wrapper {
    width: 327px;
  }
}

#login .form-wrapper .content .title {
  letter-spacing: -.02em;
  color: var(--white);
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 133%;
}

#login .form-wrapper .content .description {
  letter-spacing: -.02em;
  color: var(--white-70-op);
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

#login .form-wrapper .inputs-wrapper {
  margin-top: 2rem;
}

#login .form-wrapper .inputs-wrapper .input-item:first-child {
  margin-bottom: 1.5rem;
}

#login .form-wrapper .inputs-wrapper .input-item .input {
  border: unset;
  letter-spacing: -.02em;
  color: var(--white);
  background: none;
  border-bottom: 1px solid #ffffff1a;
  width: 100%;
  height: 56px;
  padding: .5rem;
  font-family: Causten, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (max-width: 600px) {
  #login .form-wrapper .inputs-wrapper .input-item .input {
    font-size: .875rem;
    line-height: 171%;
  }
}

#login .form-wrapper .inputs-wrapper .input-item .input::placeholder {
  letter-spacing: -.02em;
  color: var(--white);
  font-family: Causten, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (max-width: 600px) {
  #login .form-wrapper .inputs-wrapper .input-item .input::placeholder {
    font-size: .875rem;
    line-height: 171%;
  }
}

#login .form-wrapper .inputs-wrapper .input-item .show-password {
  display: flex;
  position: relative;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: .5rem;
  transform: translateY(-50%);
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password svg {
  width: 24px;
  height: 24px;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password .hidden {
  display: none;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password.mostra .hidden {
  display: block;
}

#login .form-wrapper .inputs-wrapper .input-item .show-password .password.mostra .show {
  display: none;
}

#login .form-wrapper .inputs-wrapper .recuperar-senha {
  margin-top: .5rem;
}

#login .form-wrapper .inputs-wrapper .recuperar-senha a {
  letter-spacing: -.02em;
  color: var(--white-70-op);
  font-size: .75rem;
  font-weight: 300;
  line-height: 167%;
  text-decoration: none;
}

#login .form-wrapper .inputs-wrapper .recuperar-senha a:hover {
  color: var(--white);
  text-decoration: underline;
}

#login .form-wrapper .btn-wrapper {
  margin-top: 3.5rem;
}

#login .form-wrapper .btn-wrapper .btn {
  letter-spacing: -.02em;
  background-color: var(--primary-light-10-op);
  border: 1px solid var(--primary-light);
  color: var(--primary-light);
  cursor: pointer;
  border-radius: 1.75rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  transition: background-color .2s ease-in, transform .1s ease-in;
  display: flex;
}

@media screen and (max-width: 600px) {
  #login .form-wrapper .btn-wrapper .btn {
    padding: .5rem 2rem;
    font-size: .875rem;
  }
}

#login .form-wrapper .btn-wrapper .btn:hover {
  box-shadow: 0 .5em .5em -.4em var(--primary-pure);
  color: var(--neutral-10);
  border-color: var(--primary-pure);
  transform: translateY(-.25em) !important;
}

#login .form-wrapper .voltar {
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem auto 0;
}

#login .form-wrapper .voltar a {
  letter-spacing: -.02em;
  color: var(--white-70-op);
  font-size: .875rem;
  font-weight: 300;
  line-height: 157%;
}

#login .form-wrapper .voltar a:hover {
  color: var(--white);
  text-decoration: underline;
}

#login .form-wrapper .checkbox-wrapper {
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  display: flex;
}

#login .form-wrapper .checkbox-wrapper .radio-container {
  align-items: center;
  display: flex;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  letter-spacing: -.02em;
  color: var(--white-70-op);
  align-items: center;
  gap: .375rem;
  font-size: .875rem;
  font-weight: 300;
  line-height: 150%;
  display: inline-flex;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label .checkbox {
  clip: rect(0px, 0px, 0px, 0px);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label:hover {
  color: var(--white);
  font-weight: 400;
}

#login .form-wrapper .checkbox-wrapper .radio-container .check-label:hover .radio-circle {
  border-color: var(--primary-pure);
  border-width: 2px;
}

#login .form-wrapper .checkbox-wrapper .radio-container .radio-circle {
  border: 1px solid #fff3;
  border-radius: 50%;
  flex-shrink: 0;
  align-items: center;
  width: 20px;
  height: 20px;
  display: inline-flex;
  position: relative;
}

#login .form-wrapper .checkbox-wrapper .radio-container .radio-circle-inner {
  background: var(--primary-pure);
  transition: all .2s ease-out;
  transition-property: width height;
  border-radius: 50%;
  flex-shrink: 0;
  width: 0;
  height: 0;
  margin: auto;
  position: absolute;
  inset: 0;
}

#login .form-wrapper .checkbox-wrapper .radio-container input:checked ~ span {
  border-color: var(--primary-pure);
}

#login .form-wrapper .checkbox-wrapper .radio-container input:checked ~ span i {
  width: 12px;
  height: 12px;
}

#login .form-wrapper .checkbox-wrapper .radio-container.round .radio-circle {
  border-radius: .25rem;
}

#login .form-wrapper .checkbox-wrapper .radio-container.round .radio-circle-inner {
  transition: initial;
  border-radius: initial !important;
}

#login .form-wrapper .checkbox-wrapper .radio-container.round input:checked ~ span i {
  border-radius: initial;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDJMNy42MjY4MSAxMkwyIDUuOTk2OTIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: auto;
}

#login .form-wrapper .checkbox-wrapper .radio-container.disabled {
  -webkit-user-select: none;
  user-select: none;
}

#login .form-wrapper .checkbox-wrapper .radio-container.disabled .check-label {
  pointer-events: none;
  color: var(--neutral-60);
}

#login .form-wrapper .checkbox-wrapper .radio-container.disabled .radio-circle {
  pointer-events: none;
  opacity: .2;
}

.accordion {
  flex-direction: column;
  display: flex;
}

.accordion + .accordion {
  margin-top: -1px;
}

.accordion-item {
  border-bottom: 1px solid #1b1d211a;
  border-radius: 3px;
}

.accordion-item.active {
  border-color: #3b9c8e;
}

.accordion-item.active .icon-right .bg-icon-active, .accordion-item.active .icon-left .bg-icon-active {
  background: #3b9c8e1a;
}

.accordion-item.active svg {
  transform: rotate(180deg);
}

.accordion svg {
  width: 24px;
  height: 24px;
  transition: transform .3s ease-in-out;
  transform: rotate(0);
}

.accordion-title {
  color: #1b1d21;
  letter-spacing: -.02em;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  height: 96px;
  padding: 16px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3;
  transition: background-color .3s ease-in-out;
  display: flex;
  position: relative;
}

@media screen and (max-width: 600px) {
  .accordion-title {
    font-size: 16px;
  }
}

.accordion-title:hover {
  background-color: #f0f0f0;
}

.accordion-title.icon-left {
  padding-left: 2.5rem;
}

.accordion-title.icon-left .bg-icon-active {
  background: none;
  border-radius: 28px;
  place-items: center;
  width: 40px;
  height: 40px;
  margin: auto 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
}

.accordion-title.icon-right {
  padding-right: 2.5rem;
}

.accordion-title.icon-right .bg-icon-active {
  background: none;
  border-radius: 28px;
  place-items: center;
  width: 40px;
  height: 40px;
  margin: auto 0;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 16px;
}

.accordion-content {
  max-width: 100%;
  height: 0;
  overflow: hidden;
}

.accordion-content.active {
  height: auto;
}

.accordion .accordion-container {
  color: #3b4042;
  letter-spacing: -.03em;
  padding: 8px 16px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.grid-eventos {
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  display: grid;
}

@media screen and (max-width: 1440px) {
  .grid-eventos {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .grid-eventos {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .grid-eventos {
    grid-template-columns: repeat(1, 1fr);
  }
}

.card-evento:not(.card-programacao) {
  cursor: pointer;
  position: relative;
}

.card-evento:not(.card-programacao):hover img {
  transform-origin: bottom;
  transform: scale(1.08);
}

.card-evento:not(.card-programacao) .card-evento-img {
  overflow: hidden;
}

.card-evento:not(.card-programacao) .card-evento-img img {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  min-height: 250px;
  transition: transform .2s ease-in-out;
}

.card-evento:not(.card-programacao) .content {
  background-color: var(--bienal-rosa);
  max-width: calc(100% - 1.25rem);
  padding: 1rem 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1440px) {
  .card-evento:not(.card-programacao) .content {
    padding: 10px 12px;
  }
}

.card-evento:not(.card-programacao) .content .icon-wrapper {
  background-color: #d6df21;
  border-radius: 0 0 0 6px;
  padding: .375rem;
  position: absolute;
  top: 0;
  right: 0;
}

.card-evento:not(.card-programacao) .content .icon-wrapper svg {
  width: 1.25rem;
  height: 1.3125rem;
}

.card-evento:not(.card-programacao) .content .card-evento-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  display: -webkit-box;
  overflow: hidden;
}

@media screen and (max-width: 1440px) {
  .card-evento:not(.card-programacao) .content .card-evento-title {
    font-size: 1.3rem;
  }
}

.card-evento:not(.card-programacao) .content .card-evento-role {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff9;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}

@media screen and (max-width: 1440px) {
  .card-evento:not(.card-programacao) .content .card-evento-role {
    font-size: 1rem;
  }
}

#base-banner {
  height: 480px;
  position: relative;
}

#base-banner:after {
  content: "";
  background: linear-gradient(91deg, #2e3192 0%, #2e3192d6 29.57%, #d9d9d900 100%);
  position: absolute;
  inset: 0;
}

@media screen and (max-width: 768px) {
  #base-banner:after {
    background: linear-gradient(359deg, #2e3192 0%, #2e3192d6 42.57%, #d9d9d900 100%);
  }
}

#base-banner .banner-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#base-banner .banner-wrapper {
  height: 100%;
  position: absolute;
  inset: 0;
}

#base-banner .container {
  flex: 1;
  height: 100%;
  display: grid;
  position: relative;
}

#base-banner .banner-content {
  z-index: 99;
  position: absolute;
  bottom: clamp(3rem, 4vw, 5rem);
  left: 1.5rem;
  right: 1.5rem;
}

#base-banner .banner-content .section-title .badge {
  color: #fff;
}

#base-banner .banner-content .section-title h1 {
  letter-spacing: -.8px;
  text-transform: uppercase;
  color: #fff;
  font-size: clamp(3rem, 5vw, 5rem);
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
}

.instagram-wrapper-plugin .instagram-gallery-medium {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  display: flex;
}

.instagram-wrapper-plugin .instagram-gallery-medium > a {
  cursor: pointer;
  border-radius: 3px;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.instagram-wrapper-plugin .instagram-gallery-medium > a > video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.instagram-wrapper-plugin .instagram-gallery-medium video {
  object-fit: cover;
  border-radius: 3px;
  position: absolute;
  inset: 0;
}

#banner-patrocinio {
  margin-bottom: 7.5rem;
}

#banner-patrocinio .container {
  padding-top: 120px;
  position: relative;
}

@media screen and (max-width: 768px) {
  #banner-patrocinio .container {
    padding-top: 96px;
  }
}

#banner-patrocinio .balao-wrapper {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 1.5rem;
}

@media screen and (max-width: 768px) {
  #banner-patrocinio .balao-wrapper {
    max-width: 72px;
  }
}

#banner-patrocinio .robo-wrapper {
  position: absolute;
  top: -58px;
  left: 0;
}

@media screen and (max-width: 768px) {
  #banner-patrocinio .robo-wrapper {
    width: 130px;
    top: 0;
    left: 1rem;
  }
}

#banner-patrocinio .origami {
  z-index: 2;
  position: absolute;
  bottom: -10px;
  right: 1.5rem;
}

@media screen and (max-width: 768px) {
  #banner-patrocinio .origami {
    width: 80px;
  }
}

#banner-patrocinio .alienigena-wrapper {
  z-index: 2;
  position: absolute;
  top: 55px;
  left: -11px;
}

@media screen and (max-width: 768px) {
  #banner-patrocinio .alienigena-wrapper {
    max-width: 106px;
  }
}

#banner-patrocinio .img-wrapper-banner {
  height: 100%;
}

#banner-patrocinio .img-wrapper-banner img {
  object-fit: cover;
  border-radius: 6px;
  width: 100%;
  height: 100%;
}

#banner-patrocinio .swiper-banner-patrocinadores .swiper-slide {
  height: auto;
}

#banner-patrocinio .swiper-pagination-bullet {
  opacity: 1;
  background-color: #bec5cc;
  width: .75rem;
  height: .75rem;
}

#banner-patrocinio .swiper-pagination-bullet-active {
  background: #868e96;
  border-radius: 8px;
  width: 24px;
}

.alienigena-wrapper, body:not(.body_home) .robo-wrapper, body:not(.body_home) .origami, .body_single_blog .robo-wrapper, .body_single_blog .origami, .body_single_blog .balao-wrapper {
  display: none;
}

.body_eventos .alienigena-wrapper, .body_quemsomos .alienigena-wrapper, .body_credenciamento .alienigena-wrapper {
  display: block;
}

.body_eventos .balao-wrapper, .body_quemsomos .balao-wrapper, .body_credenciamento .balao-wrapper, .body_home #banner-patrocinio .balao-wrapper {
  display: none;
}

#modal-atracoes {
  z-index: 9999;
  color: #1b1d21;
  background-color: #fff;
  border-radius: 6px;
  width: 625px;
  max-width: 100%;
  height: 90%;
  max-height: 725px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1440px) {
  #modal-atracoes {
    max-height: 675px;
  }
}

@media screen and (max-width: 768px) {
  #modal-atracoes {
    max-height: 700px;
  }
}

#modal-atracoes.closed {
  display: none;
}

#modal-atracoes .modal-header {
  z-index: 3;
  border-radius: 3px 3px 0 0;
  justify-content: space-between;
  margin-top: -1px;
  display: flex;
  position: relative;
}

#modal-atracoes #close-button {
  cursor: pointer;
  background: #0d4691;
  border-radius: 50%;
  place-content: center;
  width: 3rem;
  height: 3rem;
  margin-top: -1rem;
  margin-right: -1rem;
  display: grid;
}

@media screen and (max-width: 768px) {
  #modal-atracoes #close-button {
    margin-right: 0;
  }
}

#modal-atracoes #close-button svg {
  filter: brightness(0) invert();
}

#modal-atracoes .titulo {
  color: #0d4da1;
  letter-spacing: -.24px;
  text-transform: uppercase;
  max-width: 36ch;
  margin-bottom: 2rem;
  font-family: Causten;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

#modal-atracoes .texto-politica {
  margin-top: -1rem;
  margin-bottom: 2.5rem;
  font-size: 14px;
}

#modal-atracoes .modal-guts {
  width: 100%;
  height: 100%;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

#modal-atracoes .header-wrapper-modal {
  align-items: flex-start;
  gap: 2rem;
  display: flex;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .header-wrapper-modal {
    flex-direction: column-reverse;
  }
}

#modal-atracoes .atracao-img-modal {
  flex-shrink: 0;
  width: 100%;
  max-width: 316px;
  position: relative;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .atracao-img-modal {
    display: none;
  }
}

#modal-atracoes .atracao-img-modal-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .atracao-img-modal-mobile {
    margin-top: 1.5rem;
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  #modal-atracoes .atracao-img-modal {
    max-width: 260px;
  }
}

#modal-atracoes .card-evento-img img {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  min-height: 250px;
}

#modal-atracoes .content {
  background-color: #56c5d0;
  max-width: calc(100% - 1.25rem);
  padding: 1rem 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1440px) {
  #modal-atracoes .content {
    padding: 10px 12px;
  }
}

#modal-atracoes .content .icon-wrapper {
  background-color: #d6df21;
  border-radius: 0 0 0 6px;
  padding: .375rem;
  position: absolute;
  top: 0;
  right: 0;
}

#modal-atracoes .content .icon-wrapper svg {
  width: 1.25rem;
  height: 1.3125rem;
}

#modal-atracoes .content .card-evento-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  display: -webkit-box;
  overflow: hidden;
}

@media screen and (max-width: 1440px) {
  #modal-atracoes .content .card-evento-title {
    font-size: 1.3rem;
  }
}

#modal-atracoes .content .card-evento-role {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0009;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}

@media screen and (max-width: 1440px) {
  #modal-atracoes .content .card-evento-role {
    font-size: 1rem;
  }
}

#modal-atracoes .infos-tragetoria {
  padding-top: 3rem;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .infos-tragetoria {
    padding-top: 2rem;
  }
}

#modal-atracoes .infos-tragetoria h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.33;
}

#modal-atracoes .infos-tragetoria .paragraphs-content p {
  letter-spacing: -.02em;
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .modal-guts {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

#modal-atracoes .programacao {
  padding: 2rem 0 0 3rem;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .programacao {
    padding-left: 0;
  }
}

#modal-atracoes .programacao .title-programacao {
  text-transform: uppercase;
  border-bottom: 1px solid #fff3;
  margin-bottom: 1.5rem;
  padding-bottom: 10px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.3;
}

#modal-atracoes .programacao .wrapper-programacao {
  align-items: flex-start;
  gap: 4rem;
  display: flex;
}

@media screen and (max-width: 768px) {
  #modal-atracoes .programacao .wrapper-programacao {
    flex-direction: column;
    gap: 1rem;
  }
}

#modal-atracoes .programacao .nome-evento-wrapper .confirmado {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #d6df21;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}

#modal-atracoes .programacao .nome-evento-wrapper .nome-evento {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.33;
}

#modal-certificado {
  z-index: 9999;
  color: #1b1d21;
  background-color: #fff;
  border-radius: 6px;
  width: 625px;
  max-width: 100%;
  height: 100%;
  max-height: 420px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1200px) {
  #modal-certificado {
    max-height: 470px;
  }
}

#modal-certificado.closed {
  display: none;
}

#modal-certificado .modal-header {
  z-index: 3;
  border-radius: 3px 3px 0 0;
  justify-content: space-between;
  margin-top: -1px;
  display: flex;
  position: relative;
}

#modal-certificado #close-button {
  cursor: pointer;
  background: #fff;
  border: 1px solid #1b1d211a;
  border-radius: 3px;
  height: 56px;
  padding: 1.5rem;
}

@media screen and (max-width: 768px) {
  #modal-certificado #close-button {
    margin-right: 0;
  }
}

#modal-certificado #close-button svg {
  filter: brightness(0) invert();
}

#modal-certificado .titulo {
  color: #0d4da1;
  letter-spacing: -.24px;
  text-transform: uppercase;
  max-width: 36ch;
  margin-bottom: 2rem;
  font-family: Causten;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

#modal-certificado .texto-politica {
  margin-top: -1rem;
  margin-bottom: 2.5rem;
  font-size: 14px;
}

#modal-certificado .modal-guts {
  width: 100%;
  height: 100%;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

#modal-overlay {
  z-index: 1000;
  background: #00000080;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#modal-overlay.closed {
  display: none;
}

#modal-certificado .header-wrapper-modal {
  align-items: flex-start;
  gap: 2rem;
  display: flex;
}

@media screen and (max-width: 768px) {
  #modal-certificado .header-wrapper-modal {
    flex-direction: column-reverse;
  }

  #modal-certificado .modal-guts {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.scroll-box::-webkit-scrollbar {
  width: 8px;
}

.scroll-box::-webkit-scrollbar-track {
  background-color: #55555574;
}

.scroll-box::-webkit-scrollbar-thumb {
  background-color: #0d4691;
}

.scroll-box::-webkit-scrollbar-thumb:hover {
  background-color: #55555574;
}

.scroll-box {
  scrollbar-width: thin;
  scrollbar-color: #0d4691 #55555574;
}

.scroll-box::-moz-scrollbar {
  width: 8px;
}

.scroll-box::-moz-scrollbar-track {
  background-color: #55555574;
}

.scroll-box::-moz-scrollbar-thumb {
  background-color: #0d4691;
}

.scroll-box::-moz-scrollbar-thumb:hover {
  background-color: #55555574;
}

.richTextEditorJs {
  color: #36363b;
}

.richTextEditorJs h1, .richTextEditorJs h2, .richTextEditorJs h3 {
  margin-bottom: 1rem;
}

.richTextEditorJs h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0c1428;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.1;
}

.richTextEditorJs h3 {
  font-size: 18px;
}

.richTextEditorJs p {
  letter-spacing: -.02em;
  color: #36363b;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.44;
}

.richTextEditorJs ul, .richTextEditorJs ol {
  margin-bottom: 10px;
  margin-left: 20px;
}

.richTextEditorJs input[type="checkbox"] {
  -webkit-appearance: auto;
  appearance: auto;
}

.richTextEditorJs input[type="checkbox"] + label {
  margin-left: 5px;
}

.richTextEditorJs a {
  color: #007bff;
  text-decoration: underline;
}

.richTextEditorJs a:hover {
  color: #0056b3;
}

.richTextEditorJs img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.richTextEditorJs blockquote {
  background-color: #f9f9f9;
  border-left: 4px solid #ccc;
  margin: 10px 0;
  padding: 10px;
}

.richTextEditorJs pre {
  white-space: pre-wrap;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  margin: 10px 0;
  padding: 10px;
  font-family: Consolas, Monaco, Courier New, Courier, monospace;
}

.richTextEditorJs hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.richTextEditorJs table {
  border-collapse: collapse;
  width: 100%;
}

.richTextEditorJs th, .richTextEditorJs td {
  border-bottom: 1px solid #ccc;
  padding: 8px;
}

.richTextEditorJs input[type="text"], .richTextEditorJs textarea {
  border: 1px solid #ccc;
  width: 100%;
  padding: 8px;
}

.richTextEditorJs button {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  padding: 8px 12px;
}

.richTextEditorJs button:hover {
  background-color: #0056b3;
}

.richTextEditorJs .list li {
  margin-left: 16px;
  list-style: auto;
}

.richTextEditorJs li {
  color: #36363b;
  margin-bottom: .75rem;
  font-size: 1.125rem;
  list-style: disc;
}

.richTextEditorJs .warning {
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 16px 16px 16px 30px;
  position: relative;
}

.richTextEditorJs .warning:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 320 294' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath fill='%237B7E89' d='M160.5 97c12.426 0 22.5 10.074 22.5 22.5v28c0 12.426-10.074 22.5-22.5 22.5S138 159.926 138 147.5v-28c0-12.426 10.074-22.5 22.5-22.5zm0 83c14.636 0 26.5 11.864 26.5 26.5S175.136 233 160.5 233 134 221.136 134 206.5s11.864-26.5 26.5-26.5zm-.02-135c-6.102 0-14.05 8.427-23.842 25.28l-74.73 127.605c-12.713 21.444-17.806 35.025-15.28 40.742 2.527 5.717 8.519 9.175 17.974 10.373h197.255c5.932-1.214 10.051-4.671 12.357-10.373 2.307-5.702-1.812-16.903-12.357-33.603L184.555 70.281C174.608 53.427 166.583 45 160.48 45zm154.61 165.418c2.216 6.027 3.735 11.967 4.393 18.103.963 8.977.067 18.035-3.552 26.98-7.933 19.612-24.283 33.336-45.054 37.586l-4.464.913H61.763l-2.817-.357c-10.267-1.3-19.764-4.163-28.422-9.16-11.051-6.377-19.82-15.823-25.055-27.664-4.432-10.03-5.235-19.952-3.914-29.887.821-6.175 2.486-12.239 4.864-18.58 3.616-9.64 9.159-20.55 16.718-33.309L97.77 47.603c6.469-11.125 12.743-20.061 19.436-27.158 4.62-4.899 9.562-9.07 15.206-12.456C140.712 3.01 150.091 0 160.481 0c10.358 0 19.703 2.99 27.989 7.933 5.625 3.356 10.563 7.492 15.193 12.354 6.735 7.072 13.08 15.997 19.645 27.12l.142.24 76.986 134.194c6.553 10.46 11.425 19.799 14.654 28.577z'/%3E%3C/svg%3E");
  background-size: 18px 18px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 17px;
  left: 5px;
}

.card-palestrante-wrapper .card-palestrante {
  max-width: 500px;
  position: relative;
  overflow: hidden;
}

.card-palestrante-wrapper .card-palestrante:after {
  content: "";
  opacity: .9;
  background: linear-gradient(#164194cc 0%, #164194ed 51.66%, #164194 100%);
  width: 100%;
  height: 100%;
  transition: transform 3s cubic-bezier(.2, 1, .3, 1);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(2)translateX(-75%)translateY(-75%)rotate(-28deg);
}

.card-palestrante-wrapper .card-palestrante:hover:after {
  transform: scale(2)translateX(0)translateY(0)rotate(-28deg);
}

.card-palestrante-wrapper .card-palestrante:hover .item-text {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.card-palestrante-wrapper .card-palestrante:hover .plus-icon {
  opacity: 0;
}

.card-palestrante-wrapper .card-palestrante:hover .img-wrapper {
  transform: scale(1.2);
}

.card-palestrante-wrapper .card-palestrante .card-palestrante-inside {
  background: #fff;
  height: 0;
  padding-top: 118.085%;
  position: relative;
  overflow: hidden;
}

.card-palestrante-wrapper .card-palestrante .img-wrapper {
  width: 100%;
  height: 100%;
  transition: transform .75s cubic-bezier(.2, 1, .3, 1);
  position: absolute;
  inset: 0;
}

.card-palestrante-wrapper .card-palestrante .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.card-palestrante-wrapper .card-palestrante .item-text {
  opacity: 0;
  z-index: 1;
  color: #fff;
  max-height: 100%;
  padding: 1.5rem;
  transition: opacity .5s cubic-bezier(.2, 1, .3, 1) .3s, transform .5s cubic-bezier(.2, 1, .3, 1) .3s;
  position: absolute;
  inset: 0;
  overflow: hidden;
  transform: translateY(-20%);
}

.card-palestrante-wrapper .card-palestrante .infos-wrapper {
  border: 1px solid #ffffff56;
  border-radius: 3px;
  align-items: stretch;
  gap: 1rem;
  margin-top: 2rem;
  display: flex;
}

.card-palestrante-wrapper .card-palestrante .local {
  padding: 8px;
  display: grid;
}

.card-palestrante-wrapper .card-palestrante .local:first-child {
  border-right: 1px solid #ffffff56;
}

.card-palestrante-wrapper .card-palestrante .local .local-wrapper {
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
  display: flex;
}

.card-palestrante-wrapper .card-palestrante .local .local-wrapper .label {
  text-transform: uppercase;
  color: #ffffffb2;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1;
}

.card-palestrante-wrapper .card-palestrante .local .info-text {
  color: #fff;
  letter-spacing: .64px;
  text-transform: uppercase;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
}

.card-palestrante-wrapper .card-palestrante .local svg {
  color: #fff;
  opacity: .8;
  width: 18px;
  height: 18px;
}

.card-palestrante-wrapper .card-palestrante .item-text-wrap {
  inset: 0;
  top: unset;
  max-height: 100%;
  padding: 1.5rem;
  position: absolute;
  overflow: auto;
}

.card-palestrante-wrapper .card-palestrante .item-text-wrap .item-text-descricao p {
  color: #fff;
  letter-spacing: -.16px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
}

.card-palestrante-wrapper .card-palestrante .plus-icon {
  cursor: pointer;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  z-index: 4;
  background-color: #0003;
  border-radius: 50%;
  width: max-content;
  padding: 8px;
  transition: all .3s cubic-bezier(.2, 1, .3, 1);
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
}

.card-palestrante-wrapper .card-palestrante .plus-icon svg {
  width: 24px;
  height: 24px;
}

.card-palestrante-wrapper .bottom-infos {
  margin-top: 1rem;
}

.card-palestrante-wrapper .bottom-infos .nome {
  color: #1b1d21;
  letter-spacing: -.26px;
  text-transform: uppercase;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3;
}

.card-palestrante-wrapper .bottom-infos .role {
  color: #3b4042;
  letter-spacing: .56px;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
}

.card-palestrante-wrapper .bottom-infos .tags {
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 1rem;
  display: flex;
}

.card-palestrante-wrapper .bottom-infos .tags span {
  letter-spacing: .96px;
  text-transform: uppercase;
  border: 1px solid #1b1d21;
  border-radius: 12px;
  padding: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  display: inline-block;
}

.faixa-wrapper {
  z-index: 3;
  background-image: url("faixa-botao.982332f4.webp");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  margin-top: 5rem;
  padding: 1.5rem;
  position: relative;
}

@media screen and (max-width: 768px) {
  .faixa-wrapper {
    margin-top: 2rem;
  }

  .faixa-wrapper.only-btn-mobile {
    background-image: none;
  }

  .faixa-wrapper.only-btn-mobile .button-wrapper .button {
    margin-left: unset;
    text-align: center;
    max-width: unset;
    justify-content: center;
    width: 100%;
    padding: 1.4rem;
  }
}

.faixa-wrapper .button-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #3cc5b1;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  margin-left: auto;
  padding: 1.5rem;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

.faixa-wrapper .button-wrapper .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.faixa-wrapper .button-wrapper .button:active, .faixa-wrapper .button-wrapper .button:hover {
  transform: scale(1.05);
}

.faixa-wrapper .button-wrapper .button svg, .faixa-wrapper .button-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

.faixa-wrapper .button-wrapper .button svg {
  width: 24px;
  height: 24px;
}

.scroll-box-palestrante::-webkit-scrollbar {
  width: 8px;
}

.scroll-box-palestrante::-webkit-scrollbar-track {
  background-color: #b4a8a874;
}

.scroll-box-palestrante::-webkit-scrollbar-thumb {
  background-color: #0d4691;
}

.scroll-box-palestrante::-webkit-scrollbar-thumb:hover {
  background-color: #b4a8a874;
}

.scroll-box-palestrante {
  scrollbar-width: thin;
  scrollbar-color: #0d4691 #b4a8a874;
}

.scroll-box-palestrante::-moz-scrollbar {
  width: 8px;
}

.scroll-box-palestrante::-moz-scrollbar-track {
  background-color: #b4a8a874;
}

.scroll-box-palestrante::-moz-scrollbar-thumb {
  background-color: #0d4691;
}

.scroll-box-palestrante::-moz-scrollbar-thumb:hover {
  background-color: #b4a8a874;
}

#banner {
  background: #0a0b21;
  height: 100vh;
  overflow: hidden;
}

#banner:after {
  content: "";
  background: radial-gradient(17.97% 24.82% at 72.05% 42.15%, #754a981a 0%, #3244961a 100%);
  width: 100vw;
  height: 100vh;
  display: block;
  position: absolute;
  inset: 0;
}

#banner .video-banner {
  width: 100%;
  height: 100%;
  position: relative;
}

#banner .video-banner video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#banner .video-banner:after {
  content: "";
  background: linear-gradient(#00000040 0%, #00020d00 52.08%, #02093f80 77.6%, #26004580 100%);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  inset: 0;
}

#banner .content-banner {
  z-index: 2;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  bottom: 9rem;
}

@media screen and (max-width: 600px) {
  #banner .content-banner {
    width: 244px;
  }
}

#banner .content-banner .subtitle {
  color: #fff;
  letter-spacing: -.02em;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.2;
  position: relative;
}

@media (max-width: 767px) {
  #banner .content-banner .subtitle {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  #banner .content-banner .subtitle {
    font-size: 1.5rem;
  }
}

#banner .content-banner .title {
  color: #fff;
  letter-spacing: -.02em;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
}

@media (max-width: 767px) {
  #banner .content-banner .title {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  #banner .content-banner .title {
    font-size: 2rem;
  }
}

#portfolio {
  background: #0a0b21;
  margin-top: -5rem;
  position: relative;
}

@media screen and (max-width: 600px) {
  #portfolio {
    background: linear-gradient(90deg, #00020d 0%, #07000d 100%);
  }
}

#portfolio .orn-sections {
  display: none;
}

@media screen and (max-width: 600px) {
  #portfolio .orn-sections {
    display: block;
  }
}

#portfolio .swiper-portfolio {
  width: 100%;
  display: grid;
}

@media screen and (max-width: 768px) {
  #portfolio .swiper-portfolio {
    margin-top: 12rem;
  }
}

@media screen and (max-width: 600px) {
  #portfolio .swiper-portfolio {
    margin-top: 9rem;
  }
}

#portfolio .swiper-portfolio .swiper-wrapper {
  transition-timing-function: linear;
}

@media (max-width: 1661px) {
  #portfolio .swiper-portfolio .swiper-wrapper {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (min-width: 1024px) {
  #portfolio .swiper-portfolio .swiper-wrapper {
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 1rem;
    display: grid;
  }
}

@media screen and (max-width: 600px) {
  #portfolio .swiper-portfolio .swiper-wrapper {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 1367px) {
  #portfolio .swiper-portfolio .swiper-wrapper .swiper-slide {
    width: 100%;
  }
}

#portfolio .swiper-portfolio .swiper-wrapper .swiper-slide:first-child {
  grid-column-start: 2;
}

#portfolio .nav-wrapper {
  display: none;
}

@media screen and (max-width: 600px) {
  #portfolio .nav-wrapper {
    display: block;
  }

  #portfolio .nav-wrapper .swiper-pagination-bullet {
    background: var(--primary-pure-10);
  }

  #portfolio .nav-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--primary-pure-10);
    border-radius: 1.3125rem;
    width: 1.5rem;
    height: .4375rem;
  }

  #portfolio .cards {
    padding: .5rem 0 4rem 1rem;
  }
}

#portfolio .cards .card-principal {
  background: linear-gradient(90deg, #00020d 0%, #07000d 100%);
  width: 465.5px;
  height: 448px;
  position: absolute;
}

@media (max-width: 1661px) {
  #portfolio .cards .card-principal {
    width: 537px;
  }
}

@media screen and (max-width: 1440px) {
  #portfolio .cards .card-principal {
    width: 467px;
  }
}

@media (max-width: 1367px) {
  #portfolio .cards .card-principal {
    width: 439px;
  }
}

@media (max-width: 1280px) {
  #portfolio .cards .card-principal {
    width: 414px;
  }
}

@media screen and (max-width: 768px) {
  #portfolio .cards .card-principal {
    width: 427px;
    height: 182px;
    top: -12rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 600px) {
  #portfolio .cards .card-principal {
    width: 327px;
    height: 152px;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

#portfolio .cards .card-principal .content {
  position: absolute;
  top: 8rem;
  left: 4rem;
}

@media screen and (max-width: 768px) {
  #portfolio .cards .card-principal .content {
    top: 1.5rem;
    left: 1.5rem;
  }
}

#portfolio .cards .card-principal .content .icon {
  width: 64px;
  height: 64px;
  display: block;
}

@media screen and (max-width: 768px) {
  #portfolio .cards .card-principal .content .icon {
    width: 32px;
    height: 32px;
  }
}

#portfolio .cards .card-principal .content .subtitle {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 120%;
}

@media screen and (max-width: 768px) {
  #portfolio .cards .card-principal .content .subtitle {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  #portfolio .cards .card-principal .content .subtitle {
    font-size: 1.125rem;
    line-height: 144%;
  }
}

#portfolio .cards .card-principal .content .title {
  letter-spacing: -.02em;
  background: linear-gradient(90deg, #5574ff 0%, #c47cff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 114%;
}

@media screen and (max-width: 768px) {
  #portfolio .cards .card-principal .content .title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  #portfolio .cards .card-principal .content .title {
    font-size: 1.5rem;
    line-height: 133%;
  }
}

#portfolio .cards .card {
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: all .3s ease-in;
  position: relative;
}

#portfolio .cards .card .img-card {
  width: 100%;
  height: 448px;
  position: relative;
}

@media (max-width: 1661px) {
  #portfolio .cards .card .img-card {
    width: 100%;
  }
}

@media (max-width: 512px) {
  #portfolio .cards .card .img-card {
    width: 95%;
    height: 350px;
  }
}

@media (max-width: 440px) {
  #portfolio .cards .card .img-card {
    width: 312px;
    height: 312px;
  }
}

#portfolio .cards .card .img-card img, #portfolio .cards .card .img-card video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#portfolio .cards .card .img-card:after {
  content: "";
  background: linear-gradient(0deg, #00000080, #00000080);
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

#portfolio .cards .card .content {
  border-left: 2px solid var(--primary-pure-10);
  background: linear-gradient(270deg, #ffffff01 0%, #ffffff1a 100%);
  width: 100%;
  height: 5.8125rem;
  padding: 1rem;
  transition: all .3s ease-in;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

#portfolio .cards .card .content .tag {
  color: #fff;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  background: #ffffff1a;
  border: 1px solid #fff;
  border-radius: 18px;
  width: -moz-fit-content;
  width: fit-content;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #portfolio .cards .card .content .tag {
    font-size: 10px;
  }
}

#portfolio .cards .card .content .title {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-top: .5rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 133%;
}

#portfolio .cards .card .content .description {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  max-width: 412px;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media (max-width: 1661px) {
  #portfolio .cards .card .content .description {
    width: 85%;
    max-width: 100%;
  }
}

@media (max-width: 899px) {
  #portfolio .cards .card.active .content {
    height: 100%;
  }
}

@media (min-width: 900px) {
  #portfolio .cards .card:hover .content {
    height: 100%;
  }
}

#sobre {
  background: #0a0b21;
  padding: 12.5rem 0 7.5rem;
  position: relative;
}

@media screen and (max-width: 768px) {
  #sobre {
    padding: 6rem 0 .875rem;
  }
}

@media screen and (max-width: 600px) {
  #sobre {
    padding: 3rem 0 .875rem;
  }
}

#sobre .content {
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 768px) {
  #sobre .content {
    flex-direction: column;
  }
}

#sobre .content .col-1 {
  width: 470px;
}

@media screen and (max-width: 600px) {
  #sobre .content .col-1 {
    width: 306px;
  }
}

#sobre .content .col-1 .tag {
  color: #9b45e2;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  background: #9b45e21a;
  border: 1px solid #9b45e2;
  border-radius: 18px;
  width: -moz-fit-content;
  width: fit-content;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #sobre .content .col-1 .tag {
    font-size: 10px;
  }
}

#sobre .content .col-1 .title {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-top: .5rem;
  font-size: 3rem;
  font-weight: 400;
  line-height: 114%;
}

@media screen and (max-width: 600px) {
  #sobre .content .col-1 .title {
    font-size: 2rem;
  }
}

@media (max-width: 1190px) {
  #sobre .content .col-1 .title {
    font-size: 2rem;
  }
}

#sobre .content .col-2 {
  width: 488px;
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  #sobre .content .col-2 {
    margin-top: 4rem;
  }
}

@media screen and (max-width: 600px) {
  #sobre .content .col-2 {
    width: 327px;
    margin-top: 1rem;
  }
}

#sobre .content .col-2 .description {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media (max-width: 1190px) {
  #sobre .content .col-2 .description {
    color: var(--neutral-30);
    font-size: .875rem;
  }
}

#sobre .servicos {
  margin-top: 13.8125rem;
}

@media (max-width: 1190px) {
  #sobre .servicos {
    margin-top: 7.5rem;
  }
}

@media screen and (max-width: 768px) {
  #sobre .servicos {
    margin-top: 3.5rem;
  }
}

@media screen and (max-width: 600px) {
  #sobre .servicos {
    margin-top: 4rem;
  }
}

#sobre .servicos .swiper-servicos-sobre {
  border: 1px solid;
  border-width: 0 0 1px;
  border-top-style: none;
  border-top-color: currentColor;
  border-left-style: none;
  border-left-color: currentColor;
  border-right-style: none;
  border-right-color: currentColor;
  border-image-source: linear-gradient(90deg, #324496 0%, #754a98 100%);
  border-image-slice: 1;
}

@media screen and (max-width: 600px) {
  #sobre .servicos .swiper-servicos-sobre {
    padding-bottom: 1rem;
  }
}

#sobre .servicos .swiper-servicos-sobre .swiper-wrapper {
  transition-timing-function: linear;
}

@media screen and (max-width: 600px) {
  #sobre .servicos .swiper-servicos-sobre {
    border: unset;
  }
}

#sobre .servicos .nav-wrapper {
  display: none;
}

@media screen and (max-width: 600px) {
  #sobre .servicos .nav-wrapper {
    display: block;
  }

  #sobre .servicos .nav-wrapper .swiper-pagination-bullet {
    background: var(--primary-pure-10);
  }

  #sobre .servicos .nav-wrapper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--primary-pure-10);
    border-radius: 1.3125rem;
    width: 1.5rem;
    height: .4375rem;
  }
}

#sobre .servicos .title {
  text-align: center;
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 144%;
}

@media screen and (max-width: 600px) {
  #sobre .servicos .title {
    text-align: start;
    font-size: 1rem;
  }
}

#sobre .servicos .items-wrapper .item {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-width: 174px;
  padding: 1.5rem 0;
  display: flex;
}

#sobre .servicos .items-wrapper .item p {
  text-align: center;
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (max-width: 600px) {
  #sobre .servicos .items-wrapper .item p {
    color: var(--neutral-30);
    font-size: .875rem;
  }
}

#sobre .servicos .items-wrapper .item .icon {
  width: 24px;
  height: 24px;
}

#clientes {
  background: #0a0b21;
  padding: 12.5rem 0 7.5rem;
  position: relative;
}

@media screen and (max-width: 1440px) {
  #clientes {
    padding: 7.5rem 0;
  }
}

@media screen and (max-width: 600px) {
  #clientes {
    padding: 4.5rem 0 2rem;
  }
}

#clientes .swiper-clientes {
  width: 100%;
  display: grid;
}

#clientes .swiper-clientes .swiper-wrapper {
  transition-timing-function: linear;
}

#clientes .swiper-clientes.linha-2 {
  max-width: 1216px;
}

#clientes .content {
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

#clientes .content:after {
  content: "";
  filter: blur(103.5px);
  z-index: -1;
  background: radial-gradient(100% 100% at 100% 0, #581a8a 0%, #1a278a 100%);
  border-radius: 50%;
  width: 335px;
  height: 335px;
  position: absolute;
  bottom: 18.25rem;
  right: 3.875rem;
}

@media screen and (max-width: 600px) {
  #clientes .content:after {
    bottom: 0;
    right: unset;
  }
}

#clientes .content:before {
  content: "";
  filter: blur(103.5px);
  z-index: -1;
  background: radial-gradient(100% 100% at 100% 0, #581a8a 0%, #1a278a 100%);
  border-radius: 50%;
  width: 335px;
  height: 335px;
  position: absolute;
  top: -7.1875rem;
}

@media screen and (max-width: 600px) {
  #clientes .content:before {
    content: "";
    filter: blur(103.5px);
    z-index: -1;
    background: radial-gradient(100% 100% at 100% 0, #581a8a 0%, #1a278a 100%);
    border-radius: 50%;
    width: 231px;
    height: 231px;
    position: absolute;
    left: -7.1875rem;
  }
}

#clientes .content .tag {
  color: #9b45e2;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  background: #9b45e21a;
  border: 1px solid #9b45e2;
  border-radius: 18px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 1rem;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #clientes .content .tag {
    font-size: 10px;
  }
}

#clientes .content .title {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  text-align: center;
  width: 376px;
  margin-bottom: 4rem;
  font-size: 3rem;
  font-weight: 400;
  line-height: 114%;
}

@media screen and (max-width: 600px) {
  #clientes .content .title {
    width: 70%;
    margin-bottom: 2rem;
    font-size: 2rem;
  }
}

#clientes .logo-clientes {
  gap: 1rem;
  max-width: 1524px;
  margin: 0 auto;
  display: grid;
}

#clientes .logo-clientes .item {
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background: #ffffff0d;
  border: 1px solid #ffffff1a;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 123px;
  display: flex;
}

@media (max-width: 1661px) {
  #clientes .logo-clientes .item {
    width: auto;
  }
}

@media screen and (max-width: 600px) {
  #clientes .logo-clientes .item {
    height: 80px;
    padding: .5rem 1.2188rem;
  }
}

#contato {
  background: #0a0b21;
  padding: 13.25rem 0;
  position: relative;
}

@media screen and (max-width: 1440px) {
  #contato {
    padding: 7.5rem 0;
  }
}

@media screen and (max-width: 600px) {
  #contato {
    padding: 3rem 0 3.5rem;
  }
}

#contato .content-wrapper {
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 768px) {
  #contato .content-wrapper {
    flex-direction: column;
  }
}

#contato .content-wrapper .col-1 {
  max-width: 591px;
}

@media screen and (max-width: 1440px) {
  #contato .content-wrapper .col-1 {
    width: 80%;
  }
}

#contato .content-wrapper .col-1 .tag {
  color: #9b45e2;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  background: #9b45e21a;
  border: 1px solid #9b45e2;
  border-radius: 18px;
  width: -moz-fit-content;
  width: fit-content;
  padding: .25rem .75rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-1 .tag {
    font-size: 10px;
  }
}

#contato .content-wrapper .col-1 .title {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-top: .5rem;
  font-size: 3rem;
  font-weight: 400;
  line-height: 114%;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-1 .title {
    font-size: 2rem;
  }
}

@media (max-width: 1367px) {
  #contato .content-wrapper .col-1 .title {
    width: 85%;
  }
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-1 .title {
    width: 235px;
    margin-top: 1rem;
  }
}

#contato .content-wrapper .col-1 .content {
  margin-top: 4rem;
}

#contato .content-wrapper .col-1 .content .contato-item {
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

#contato .content-wrapper .col-1 .content .contato-item .icon-contato {
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-1 .content .contato-item .icon-contato {
    width: 24px;
    height: 24px;
  }
}

#contato .content-wrapper .col-1 .content .contato-item .info {
  flex-direction: column;
  gap: .5rem;
  display: flex;
}

#contato .content-wrapper .col-1 .content .contato-item .info span {
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--primary-pure-10);
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-1 .content .contato-item .info span {
    font-size: .625rem;
  }
}

#contato .content-wrapper .col-1 .content .contato-item .info a {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
  display: block;
  position: relative;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-1 .content .contato-item .info a {
    font-size: .875rem;
  }
}

#contato .content-wrapper .col-1 .content .contato-item .info a:after {
  content: "";
  background-color: #0000;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(.1, #0000), color-stop(.8, #4557e2), color-stop(.98, #4557e2));
  background-image: linear-gradient(left, transparent 10%, #4557e2 80%, #4557e2 98%);
  border-radius: .5rem;
  width: 0;
  height: 2.5px;
  transition: width .3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

#contato .content-wrapper .col-1 .content .contato-item .info a:hover:after {
  width: 100%;
}

#contato .content-wrapper .col-2 {
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  background: #ffffff0d;
  border: 1px solid #ffffff1a;
  border-radius: .25rem;
  width: 528px;
  padding: 2rem 1.5rem;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-2 {
    align-self: center;
    width: 100%;
    padding: 1.5rem 1rem;
  }
}

#contato .content-wrapper .col-2 h2 {
  letter-spacing: -.02em;
  color: var(--primary-pure-10);
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-2 h2 {
    font-size: 1rem;
  }
}

#contato .content-wrapper .col-2 .content-form {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

#contato .content-wrapper .col-2 .content-form input {
  color: var(--primary-pure-10);
  letter-spacing: -.02em;
  background: none;
  border: none;
  border-bottom: 1px solid #ffffff1a;
  width: 100%;
  height: 56px;
  padding: 1rem .5rem;
  font-family: Causten, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

#contato .content-wrapper .col-2 .content-form input::placeholder {
  color: var(--primary-pure-10);
  letter-spacing: -.02em;
  font-family: Causten, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-2 .content-form input::placeholder {
    font-size: .875rem;
    line-height: 157%;
  }
}

#contato .content-wrapper .col-2 .content-form textarea {
  color: var(--primary-pure-10);
  letter-spacing: -.02em;
  background: none;
  border: none;
  border-bottom: 1px solid #ffffff1a;
  width: 100%;
  padding: .5rem;
  font-family: Causten, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

#contato .content-wrapper .col-2 .content-form textarea::placeholder {
  color: var(--primary-pure-10);
  letter-spacing: -.02em;
  font-family: Causten, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 150%;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-2 .content-form textarea::placeholder {
    font-size: .875rem;
    line-height: 157%;
  }
}

#contato .content-wrapper .col-2 .content-form .btn {
  border: 1px solid var(--primary-pure);
  letter-spacing: -.02em;
  color: var(--primary-pure);
  cursor: pointer;
  background-color: #9b45e21a;
  border-radius: 28px;
  align-self: flex-start;
  margin-top: 2rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  transition: background-color .2s ease-in, transform .1s ease-in;
}

@media screen and (max-width: 600px) {
  #contato .content-wrapper .col-2 .content-form .btn {
    padding: .5rem 2rem;
    font-size: .875rem;
  }
}

#contato .content-wrapper .col-2 .content-form .btn:hover {
  box-shadow: 0 .5em .5em -.4em var(--primary-pure);
  color: var(--primary-light);
  border-color: var(--primary-pure);
  transform: translateY(-.25em) !important;
}

#contato .content-wrapper .col-2 .content-form .btn.disabled {
  color: var(--primary-pure-10);
  opacity: .5;
  pointer-events: none;
  background: #333;
  border-color: #0000;
}

#footer {
  background: #0a0b21;
  padding-bottom: 2.25rem;
  position: relative;
}

#footer .footer-wrapper {
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 600px) {
  #footer .footer-wrapper {
    flex-direction: column;
    gap: 1rem;
  }

  #footer .footer-wrapper .copy {
    align-self: center;
    width: 206px;
  }
}

#footer .footer-wrapper .copy h3 {
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--primary-pure-10);
  margin-bottom: 1rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 133%;
}

@media screen and (max-width: 600px) {
  #footer .footer-wrapper .copy h3 {
    text-align: center;
    font-size: .625rem;
  }

  #footer .footer-wrapper .logo {
    align-self: center;
  }
}

.section-title {
  position: relative;
}

.section-title .badge {
  color: #3b9c8e;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  align-items: center;
  gap: 1rem;
  margin-bottom: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  position: relative;
}

.section-title .badge:before {
  content: "";
  background-color: currentColor;
  width: 5px;
  height: 5px;
  display: block;
}

.section-title h2 {
  color: #0d4da1;
  letter-spacing: -.48px;
  text-transform: uppercase;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
}

.section-title .section-description {
  color: #34383b;
  letter-spacing: -.18px;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
}

.input-group {
  margin-bottom: 1rem;
}

.input-group label {
  letter-spacing: -.32px;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 500;
}

.input-group input {
  height: 56px;
}

.input-group input, .input-group textarea {
  background: #fff;
  border: 1px solid #1b1d211a;
  border-radius: 3px;
  width: 100%;
  padding: 8px 14px;
}

.input-group input:focus, .input-group textarea:focus {
  border-color: #000;
}

.input-group input::placeholder, .input-group textarea::placeholder {
  color: #525c64;
}

.input-group textarea {
  resize: none;
}

.input-group-2-col {
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  display: grid;
}

form .button-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #3cc5b1;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

form .button-wrapper .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

form .button-wrapper .button:active, form .button-wrapper .button:hover {
  transform: scale(1.05);
}

form .button-wrapper .button svg, form .button-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

form .button-wrapper .button svg {
  width: 24px;
  height: 24px;
}

form .button-wrapper .button {
  text-align: center;
  max-width: unset;
  justify-content: center;
  width: 100%;
}

form .button-wrapper .button span {
  font-size: 16px;
}

.data-wrapper {
  color: #fff;
}

.data-wrapper.isBlue, .data-wrapper.isBlue p, .data-wrapper.isBlue .local, .data-wrapper.isBlue .data-destaque {
  color: #0d4da1;
  border-color: #0d4da1;
}

.data-wrapper p {
  letter-spacing: -.36px;
  font-family: Causten;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .data-wrapper p {
    font-size: 1.5rem;
  }
}

.data-wrapper .data-destaque {
  color: #fff;
  letter-spacing: -.4px;
  text-align: center;
  border: 1px solid #3cc5b1;
  border-radius: 28px;
  min-width: 102px;
  margin: 0 2px;
  padding: 7px 21px;
  font-family: Causten;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .data-wrapper .data-destaque {
    min-width: 60px;
    padding: 7px 16px;
    font-size: 1.5rem;
  }
}

.data-wrapper p.local {
  color: #fff;
  letter-spacing: -.22px;
  margin-top: 12px;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
}

.coming-soon {
  background-color: #000;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
}

.coming-soon .logo {
  width: 160px;
  height: 160px;
  margin-top: 32px;
  margin-left: 32px;
}

.coming-soon video {
  width: 100%;
  height: 80%;
}

.coming-soon footer {
  background-color: #a3238e;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

main {
  overflow: hidden;
}

.body_home #videos-instagram .limit-video-width, .body_edicao-2023 #videos-instagram .limit-video-width {
  max-width: 900px;
  margin: 0 auto;
}

.body_home #videos-instagram .aspect-video-custom, .body_edicao-2023 #videos-instagram .aspect-video-custom {
  height: 0;
  padding-top: 56%;
  position: relative;
  overflow: hidden;
}

.body_home #videos-instagram .aspect-video-custom > *, .body_edicao-2023 #videos-instagram .aspect-video-custom > * {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_home #videos-instagram .relative-video-9-16, .body_edicao-2023 #videos-instagram .relative-video-9-16 {
  height: 0;
  padding-top: 177.78%;
  position: relative;
  overflow: hidden;
}

.body_home #videos-instagram .relative-video-9-16 > *, .body_edicao-2023 #videos-instagram .relative-video-9-16 > * {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

@media (min-width: 1024px) and (max-height: 600px) {
  .body_home .custom-limit-height, .body_edicao-2023 .custom-limit-height {
    margin-top: -68px;
  }

  .body_home #banner-home, .body_edicao-2023 #banner-home {
    min-height: 400px;
  }

  .body_home #banner-home .logos-header, .body_edicao-2023 #banner-home .logos-header {
    bottom: 2rem !important;
  }
}

.body_home #banner-home, .body_edicao-2023 #banner-home {
  height: calc(100vh - 83px);
  height: calc(100dvh - 83px);
  position: relative;
}

.body_home #banner-home .logos-header, .body_edicao-2023 #banner-home .logos-header {
  z-index: 88;
  color: #fff;
  align-items: center;
  gap: 1rem;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 2rem;
  display: flex;
  position: absolute;
  bottom: 8rem;
  left: 2.6rem;
}

@media (max-width: 1800px) {
  .body_home #banner-home .logos-header, .body_edicao-2023 #banner-home .logos-header {
    left: 8.5rem;
  }
}

@media screen and (max-width: 1440px) {
  .body_home #banner-home .logos-header, .body_edicao-2023 #banner-home .logos-header {
    bottom: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #banner-home .logos-header, .body_edicao-2023 #banner-home .logos-header {
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 1.5rem;
    top: 1.5rem;
    left: 1rem !important;
  }
}

.body_home #banner-home .logos-header a, .body_edicao-2023 #banner-home .logos-header a {
  display: inline-flex;
}

.body_home #banner-home .logos-header a figure, .body_edicao-2023 #banner-home .logos-header a figure {
  transition: transform .3s ease-in;
}

.body_home #banner-home .logos-header a figure img, .body_edicao-2023 #banner-home .logos-header a figure img {
  filter: brightness(0) invert();
  aspect-ratio: 16 / 9;
  max-width: 130px;
}

.body_home #banner-home .logos-header a figure img.cbic, .body_edicao-2023 #banner-home .logos-header a figure img.cbic {
  max-width: 76px;
}

@media screen and (max-width: 1024px) {
  .body_home #banner-home .logos-header a figure img.cbic, .body_edicao-2023 #banner-home .logos-header a figure img.cbic {
    max-width: 60px;
  }
}

@media screen and (max-width: 600px) {
  .body_home #banner-home .logos-header a figure img.cbic, .body_edicao-2023 #banner-home .logos-header a figure img.cbic {
    max-width: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #banner-home .logos-header a figure img, .body_edicao-2023 #banner-home .logos-header a figure img {
    max-width: 80px;
  }
}

@media screen and (max-width: 600px) {
  .body_home #banner-home .logos-header a figure img, .body_edicao-2023 #banner-home .logos-header a figure img {
    max-width: 60px;
  }
}

.body_home #banner-home .logos-header a:hover figure, .body_edicao-2023 #banner-home .logos-header a:hover figure {
  transform: scale(1.1);
}

.body_home #banner-home:after, .body_edicao-2023 #banner-home:after {
  content: "";
  background-image: url("filtro-do-banner.315635ec.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

@media screen and (max-width: 1024px) {
  .body_home #banner-home:after, .body_edicao-2023 #banner-home:after {
    background-image: url("banner-linear-bg-mobile.da853f1b.webp");
  }
}

@media (max-width: 1520px) {
  .body_home #banner-home, .body_edicao-2023 #banner-home {
    height: calc(100vh - 121px);
    height: calc(100dvh - 121px);
  }

  .body_home #banner-home .logos-header, .body_edicao-2023 #banner-home .logos-header {
    bottom: 3rem;
  }
}

@media (max-width: 1400px) {
  .body_home #banner-home, .body_edicao-2023 #banner-home {
    height: calc(100vh - 83px);
    height: calc(100dvh - 83px);
  }
}

@media (max-width: 1024px) {
  .body_home #banner-home, .body_edicao-2023 #banner-home {
    height: calc(100vh - 77px);
    height: calc(100dvh - 77px);
    min-height: 740px;
  }
}

.body_home #banner-home .socials-banner, .body_edicao-2023 #banner-home .socials-banner {
  z-index: 3;
  border-right: 1px solid #fff;
  place-content: center;
  display: grid;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .body_home #banner-home .socials-banner, .body_edicao-2023 #banner-home .socials-banner {
    display: none;
  }
}

.body_home #banner-home .socials-banner svg, .body_edicao-2023 #banner-home .socials-banner svg {
  color: #fff;
  width: 32px;
  height: 32px;
}

.body_home #banner-home .socials-banner ul, .body_edicao-2023 #banner-home .socials-banner ul {
  padding: 0 33px;
}

.body_home #banner-home .socials-banner ul li, .body_edicao-2023 #banner-home .socials-banner ul li {
  margin-bottom: 1rem;
  transition: all .2s ease-in;
}

.body_home #banner-home .socials-banner ul li:hover, .body_edicao-2023 #banner-home .socials-banner ul li:hover {
  transform: scale(1.05);
}

.body_home #banner-home .video-wrapper, .body_edicao-2023 #banner-home .video-wrapper {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.body_home #banner-home .video-wrapper .banner-img, .body_edicao-2023 #banner-home .video-wrapper .banner-img {
  object-fit: cover;
  object-position: left;
  width: 100%;
  height: 100%;
  inset: 0;
}

@media screen and (max-width: 1024px) {
  .body_home #banner-home .video-wrapper .banner-img, .body_edicao-2023 #banner-home .video-wrapper .banner-img {
    object-position: top;
  }
}

.body_home #banner-home .container, .body_edicao-2023 #banner-home .container {
  height: 100%;
}

@media (min-width: 1024px) {
  .body_home #banner-home .container, .body_edicao-2023 #banner-home .container {
    max-width: calc(1600px + 3rem);
  }
}

.body_home #banner-home .banner-content, .body_edicao-2023 #banner-home .banner-content {
  color: #fff;
  z-index: 3;
  width: 100%;
  padding: 0 1.5rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (max-width: 1800px) {
  .body_home #banner-home .banner-content, .body_edicao-2023 #banner-home .banner-content {
    width: calc(100% - 100px);
    margin-left: 100px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #banner-home .banner-content, .body_edicao-2023 #banner-home .banner-content {
    bottom: 64px;
    top: unset;
    width: 100%;
    margin-left: 0;
    transform: translateY(0);
  }
}

.body_home #banner-home .grid-wrapper, .body_edicao-2023 #banner-home .grid-wrapper {
  grid-template-columns: 1fr auto;
  gap: 4rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_home #banner-home .grid-wrapper, .body_edicao-2023 #banner-home .grid-wrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .body_home #banner-home .grid-wrapper, .body_edicao-2023 #banner-home .grid-wrapper {
    gap: 2rem;
  }
}

.body_home #banner-home .grid-wrapper h1, .body_edicao-2023 #banner-home .grid-wrapper h1 {
  color: #fff;
  letter-spacing: -.26px;
  text-transform: uppercase;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
}

@media (max-width: 500px) {
  .body_home #banner-home .grid-wrapper h1, .body_edicao-2023 #banner-home .grid-wrapper h1 {
    font-size: 1.8rem;
  }
}

@media (max-width: 413px) {
  .body_home #banner-home .grid-wrapper h1, .body_edicao-2023 #banner-home .grid-wrapper h1 {
    font-size: 1.5rem;
  }
}

.body_home #banner-home .grid-wrapper .descricao, .body_edicao-2023 #banner-home .grid-wrapper .descricao {
  color: #fff;
  letter-spacing: -.18px;
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .body_home #banner-home .grid-wrapper .descricao, .body_edicao-2023 #banner-home .grid-wrapper .descricao {
    margin-bottom: 2rem;
  }
}

.body_home #banner-home .linha-hr, .body_edicao-2023 #banner-home .linha-hr {
  background-color: #fff;
  width: 100%;
  height: 1px;
  margin: 1rem 0;
}

.body_home #banner-home .col-1 svg, .body_edicao-2023 #banner-home .col-1 svg {
  max-width: 604px;
}

@media screen and (max-width: 1024px) {
  .body_home #banner-home .col-1, .body_edicao-2023 #banner-home .col-1 {
    max-width: 400px;
  }
}

.body_home #banner-home .col-2, .body_edicao-2023 #banner-home .col-2 {
  max-width: 551px;
  margin-left: auto;
}

@media screen and (max-width: 1024px) {
  .body_home #banner-home .col-2, .body_edicao-2023 #banner-home .col-2 {
    margin-left: unset;
  }
}

.body_home #patrocinadores, .body_edicao-2023 #patrocinadores {
  background: #fff;
  scroll-margin-top: 50px;
}

@media (min-width: 992px) {
  .body_home #patrocinadores .container, .body_edicao-2023 #patrocinadores .container {
    max-width: 1300px;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #patrocinadores .button-wrapper, .body_edicao-2023 #patrocinadores .button-wrapper {
    display: none;
  }
}

.body_home #patrocinadores .button-wrapper .button, .body_edicao-2023 #patrocinadores .button-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #3cc5b1;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

.body_home #patrocinadores .button-wrapper .button span, .body_edicao-2023 #patrocinadores .button-wrapper .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #patrocinadores .button-wrapper .button:active, .body_home #patrocinadores .button-wrapper .button:hover, .body_edicao-2023 #patrocinadores .button-wrapper .button:active, .body_edicao-2023 #patrocinadores .button-wrapper .button:hover {
  transform: scale(1.05);
}

.body_home #patrocinadores .button-wrapper .button svg, .body_home #patrocinadores .button-wrapper .button img, .body_edicao-2023 #patrocinadores .button-wrapper .button svg, .body_edicao-2023 #patrocinadores .button-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

.body_home #patrocinadores .button-wrapper .button svg, .body_edicao-2023 #patrocinadores .button-wrapper .button svg {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 768px) {
  .body_home #patrocinadores .header-wrapper h2, .body_edicao-2023 #patrocinadores .header-wrapper h2 {
    font-size: 1.5rem;
  }
}

.body_home #patrocinadores .governo, .body_edicao-2023 #patrocinadores .governo {
  border-right: 1px solid #c1c5c7;
  align-items: center;
  height: 100%;
  padding-right: 1rem;
  display: flex;
}

.body_home #patrocinadores .rio, .body_edicao-2023 #patrocinadores .rio {
  border-right: 1px solid #c1c5c7;
  align-items: center;
  height: 100%;
  padding-right: 2rem;
  display: flex;
}

.body_home #patrocinadores .grid-patrocinio, .body_edicao-2023 #patrocinadores .grid-patrocinio {
  justify-content: center;
  align-items: stretch;
  gap: 1rem;
  padding-left: 3.75rem;
  padding-right: 3.75rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .body_home #patrocinadores .grid-patrocinio, .body_edicao-2023 #patrocinadores .grid-patrocinio {
    flex-wrap: wrap;
  }
}

.body_home #patrocinadores .grid-patrocinio .group-wrapper, .body_edicao-2023 #patrocinadores .grid-patrocinio .group-wrapper {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 1024px) {
  .body_home #patrocinadores .grid-patrocinio .group-wrapper:not(:last-child), .body_edicao-2023 #patrocinadores .grid-patrocinio .group-wrapper:not(:last-child) {
    border-right: 1px solid #c1c5c7;
    padding-right: 1rem;
  }
}

.body_home #patrocinadores .grid-patrocinio .group-wrapper .group-title, .body_edicao-2023 #patrocinadores .grid-patrocinio .group-wrapper .group-title {
  color: #1b1d21;
  letter-spacing: -.18px;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
}

.body_home #patrocinadores .grid-patrocinio .group-wrapper .group-imagens, .body_edicao-2023 #patrocinadores .grid-patrocinio .group-wrapper .group-imagens, .body_home #patrocinadores .grid-patrocinio .group-wrapper .group-imagens-1, .body_edicao-2023 #patrocinadores .grid-patrocinio .group-wrapper .group-imagens-1 {
  align-items: center;
  gap: 2rem;
  display: flex;
}

@media screen and (max-width: 768px) {
  .body_home #patrocinadores .grid-patrocinio .group-wrapper .group-imagens-1, .body_edicao-2023 #patrocinadores .grid-patrocinio .group-wrapper .group-imagens-1 {
    grid-template-columns: 1fr 1fr;
    place-items: center;
    display: grid;
  }
}

.body_home #scroll-section .scroll-text, .body_edicao-2023 #scroll-section .scroll-text {
  color: #0d4da1;
  letter-spacing: -.8px;
  text-transform: uppercase;
  text-align: center;
  padding: 3rem 0;
  font-size: 5rem;
  font-weight: 500;
}

@media screen and (max-width: 1024px) {
  .body_home #scroll-section .scroll-text, .body_edicao-2023 #scroll-section .scroll-text {
    font-size: 2rem;
  }
}

.body_home #scroll-section .scroll-text-1, .body_edicao-2023 #scroll-section .scroll-text-1 {
  border-bottom: 1px solid #1b1d211a;
}

.body_home #scroll-section .scroll-text-2, .body_edicao-2023 #scroll-section .scroll-text-2 {
  background-color: #0d4da1;
  background-image: linear-gradient(to right, #0d4da1, #42b6a7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: #0000;
}

.body_home #banner-random .img-wrapper, .body_edicao-2023 #banner-random .img-wrapper {
  margin-right: -3px;
}

.body_home #banner-random .img-wrapper img, .body_edicao-2023 #banner-random .img-wrapper img {
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}

.body_home #evento .section-title, .body_edicao-2023 #evento .section-title {
  max-width: 560px;
}

@media screen and (max-width: 768px) {
  .body_home #evento .section-title h2, .body_edicao-2023 #evento .section-title h2 {
    max-width: 14ch;
    font-size: 36px;
  }
}

.body_home #evento .grid-wrapper, .body_edicao-2023 #evento .grid-wrapper {
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_home #evento .grid-wrapper, .body_edicao-2023 #evento .grid-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_home #evento .grid-wrapper .col-2, .body_edicao-2023 #evento .grid-wrapper .col-2 {
  max-width: 644px;
  margin-left: auto;
}

@media screen and (max-width: 1024px) {
  .body_home #evento .grid-wrapper .col-2, .body_edicao-2023 #evento .grid-wrapper .col-2 {
    margin-left: unset;
  }
}

.body_home #evento .grid-wrapper .section-title h2, .body_edicao-2023 #evento .grid-wrapper .section-title h2 {
  margin-bottom: 3.5rem;
}

@media screen and (max-width: 1024px) {
  .body_home #evento .grid-wrapper .section-title h2, .body_edicao-2023 #evento .grid-wrapper .section-title h2 {
    margin-bottom: 2rem;
  }
}

.body_home #evento .grid-wrapper .button-wrapper .button, .body_edicao-2023 #evento .grid-wrapper .button-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #3cc5b1;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

.body_home #evento .grid-wrapper .button-wrapper .button span, .body_edicao-2023 #evento .grid-wrapper .button-wrapper .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #evento .grid-wrapper .button-wrapper .button:active, .body_home #evento .grid-wrapper .button-wrapper .button:hover, .body_edicao-2023 #evento .grid-wrapper .button-wrapper .button:active, .body_edicao-2023 #evento .grid-wrapper .button-wrapper .button:hover {
  transform: scale(1.05);
}

.body_home #evento .grid-wrapper .button-wrapper .button svg, .body_home #evento .grid-wrapper .button-wrapper .button img, .body_edicao-2023 #evento .grid-wrapper .button-wrapper .button svg, .body_edicao-2023 #evento .grid-wrapper .button-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

@media screen and (max-width: 600px) {
  .body_home #evento .grid-wrapper .button-wrapper .button, .body_edicao-2023 #evento .grid-wrapper .button-wrapper .button {
    max-width: unset;
    justify-content: center;
  }
}

.body_home #evento .grid-wrapper .button-wrapper .button svg, .body_edicao-2023 #evento .grid-wrapper .button-wrapper .button svg {
  width: 2rem;
  height: 2rem;
}

.body_home #sessao-numeros .grid-wrapper, .body_edicao-2023 #sessao-numeros .grid-wrapper {
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_home #sessao-numeros .grid-wrapper, .body_edicao-2023 #sessao-numeros .grid-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_home #sessao-numeros .grid-wrapper .col, .body_edicao-2023 #sessao-numeros .grid-wrapper .col {
  grid-template-rows: minmax(1fr, 535px) auto;
  display: grid;
}

.body_home #sessao-numeros .grid-wrapper .col-2 .img-wrapper, .body_edicao-2023 #sessao-numeros .grid-wrapper .col-2 .img-wrapper {
  grid-row: 2;
}

@media screen and (max-width: 1024px) {
  .body_home #sessao-numeros .grid-wrapper .col-2 .img-wrapper, .body_edicao-2023 #sessao-numeros .grid-wrapper .col-2 .img-wrapper {
    grid-row: 1;
  }
}

.body_home #sessao-numeros .img-wrapper img, .body_edicao-2023 #sessao-numeros .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body_home #sessao-numeros .numero-wrapper, .body_edicao-2023 #sessao-numeros .numero-wrapper {
  color: #fff;
  background-color: #0d4da1;
  padding: 2rem;
}

@media screen and (max-width: 768px) {
  .body_home #sessao-numeros .numero-wrapper, .body_edicao-2023 #sessao-numeros .numero-wrapper {
    padding: 24px;
  }
}

.body_home #sessao-numeros .numero-wrapper .numer, .body_edicao-2023 #sessao-numeros .numero-wrapper .numer {
  letter-spacing: -1.04px;
  font-size: 6.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .body_home #sessao-numeros .numero-wrapper .numer, .body_edicao-2023 #sessao-numeros .numero-wrapper .numer {
    font-size: 5rem;
  }
}

.body_home #sessao-numeros .numero-wrapper .text, .body_edicao-2023 #sessao-numeros .numero-wrapper .text {
  letter-spacing: -.36px;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 1.3;
}

.body_home #sessao-numeros .numero-wrapper .text-info, .body_edicao-2023 #sessao-numeros .numero-wrapper .text-info {
  text-transform: uppercase;
  font-size: 1rem;
}

.body_home #sessao-numeros .numero-wrapper .hash-tag, .body_edicao-2023 #sessao-numeros .numero-wrapper .hash-tag {
  color: var(--primary-01100, #3cc5b1);
  letter-spacing: 1.44px;
  text-transform: uppercase;
  text-align: right;
  margin-top: 2rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

@media screen and (max-width: 1024px) {
  .body_home #sessao-numeros .numero-wrapper .hash-tag, .body_edicao-2023 #sessao-numeros .numero-wrapper .hash-tag {
    text-align: left;
  }
}

.body_home #palestrantes .section-title h2, .body_edicao-2023 #palestrantes .section-title h2 {
  margin-bottom: 1rem;
  font-size: clamp(3rem, 7vw, 5rem);
}

.body_home #palestrantes .section-title p span, .body_edicao-2023 #palestrantes .section-title p span {
  display: block;
}

@media screen and (max-width: 768px) {
  .body_home #palestrantes .section-title p span, .body_edicao-2023 #palestrantes .section-title p span {
    display: inline;
  }
}

.body_home #palestrantes, .body_edicao-2023 #palestrantes {
  background-color: #fff;
}

.body_home #palestrantes .section-title, .body_edicao-2023 #palestrantes .section-title {
  margin-bottom: 3.5rem;
}

@media screen and (max-width: 768px) {
  .body_home #palestrantes .section-title, .body_edicao-2023 #palestrantes .section-title {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #palestrantes .swiper-palestrantes-mobile, .body_edicao-2023 #palestrantes .swiper-palestrantes-mobile {
    overflow: visible;
  }
}

@media (min-width: 1024px) {
  .body_home #palestrantes .swiper-palestrantes-mobile .swiper-wrapper, .body_edicao-2023 #palestrantes .swiper-palestrantes-mobile .swiper-wrapper {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    display: grid;
  }
}

@media (min-width: 1024px) and (min-width: 1440px) {
  .body_home #palestrantes .swiper-palestrantes-mobile .swiper-wrapper, .body_edicao-2023 #palestrantes .swiper-palestrantes-mobile .swiper-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

.body_home #inscricao, .body_edicao-2023 #inscricao {
  z-index: 2;
  background-color: #f7f8f9;
  margin-top: -50px;
  position: relative;
}

.body_home #inscricao .header-wrapper, .body_edicao-2023 #inscricao .header-wrapper {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.5rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .body_home #inscricao .header-wrapper .section-title, .body_edicao-2023 #inscricao .header-wrapper .section-title {
    margin-bottom: 1.5rem;
  }

  .body_home #inscricao .header-wrapper .section-title h2, .body_edicao-2023 #inscricao .header-wrapper .section-title h2 {
    max-width: 12ch;
    margin-bottom: 8px;
  }

  .body_home #inscricao .header-wrapper, .body_edicao-2023 #inscricao .header-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}

.body_home #inscricao .header-wrapper .data-wrapper .data-destaque, .body_edicao-2023 #inscricao .header-wrapper .data-wrapper .data-destaque {
  min-width: unset;
}

.body_home #inscricao .header-wrapper .data-wrapper .data-destaque, .body_home #inscricao .header-wrapper .data-wrapper p, .body_edicao-2023 #inscricao .header-wrapper .data-wrapper .data-destaque, .body_edicao-2023 #inscricao .header-wrapper .data-wrapper p {
  font-size: 1.5rem;
}

.body_home #inscricao .header-wrapper .data-wrapper .local, .body_edicao-2023 #inscricao .header-wrapper .data-wrapper .local {
  font-size: 1.375rem;
}

.body_home #inscricao .inscricao-tabs, .body_edicao-2023 #inscricao .inscricao-tabs {
  margin-bottom: 3rem;
}

@media screen and (max-width: 1024px) {
  .body_home #inscricao .inscricao-tabs, .body_edicao-2023 #inscricao .inscricao-tabs {
    margin-bottom: 1.5rem;
  }
}

.body_home #inscricao .inscricao-tabs:after, .body_edicao-2023 #inscricao .inscricao-tabs:after {
  content: "";
  background-color: #1b1d211a;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
}

.body_home #inscricao .inscricao-tabs .swiper-slide, .body_edicao-2023 #inscricao .inscricao-tabs .swiper-slide {
  height: auto;
}

@media screen and (max-width: 1024px) {
  .body_home #inscricao .inscricao-tabs .swiper-slide, .body_edicao-2023 #inscricao .inscricao-tabs .swiper-slide {
    max-width: max-content;
  }
}

.body_home #inscricao .inscricao-tabs .tab-inscricao, .body_edicao-2023 #inscricao .inscricao-tabs .tab-inscricao {
  cursor: pointer;
  padding: 1rem 0;
  position: relative;
}

.body_home #inscricao .inscricao-tabs .tab-inscricao:after, .body_edicao-2023 #inscricao .inscricao-tabs .tab-inscricao:after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
}

.body_home #inscricao .inscricao-tabs .tab-inscricao p, .body_edicao-2023 #inscricao .inscricao-tabs .tab-inscricao p {
  color: #7b7c7f;
  text-align: center;
  letter-spacing: -.22px;
  text-transform: uppercase;
  min-width: max-content;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
}

@media screen and (max-width: 768px) {
  .body_home #inscricao .inscricao-tabs .tab-inscricao p, .body_edicao-2023 #inscricao .inscricao-tabs .tab-inscricao p {
    font-size: 18px;
  }
}

.body_home #inscricao .inscricao-tabs .tab-inscricao.active p, .body_home #inscricao .inscricao-tabs .tab-inscricao:hover p, .body_edicao-2023 #inscricao .inscricao-tabs .tab-inscricao.active p, .body_edicao-2023 #inscricao .inscricao-tabs .tab-inscricao:hover p {
  color: #0d4da1;
  transition: all .2s ease-in-out;
}

.body_home #inscricao .inscricao-tabs .tab-inscricao.active:after, .body_home #inscricao .inscricao-tabs .tab-inscricao:hover:after, .body_edicao-2023 #inscricao .inscricao-tabs .tab-inscricao.active:after, .body_edicao-2023 #inscricao .inscricao-tabs .tab-inscricao:hover:after {
  background-color: #0d4da1;
  transition: all .2s ease-in-out;
}

.body_home #inscricao .inscricao-tab-content, .body_edicao-2023 #inscricao .inscricao-tab-content {
  display: none;
}

.body_home #inscricao .inscricao-tab-content.active, .body_edicao-2023 #inscricao .inscricao-tab-content.active {
  display: block;
}

.body_home #inscricao .grid-wrapper, .body_edicao-2023 #inscricao .grid-wrapper {
  grid-template-columns: 1.3fr 1fr;
  align-items: center;
  gap: 2rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_home #inscricao .grid-wrapper, .body_edicao-2023 #inscricao .grid-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_home #inscricao .grid-wrapper .col-2, .body_edicao-2023 #inscricao .grid-wrapper .col-2 {
  max-width: 512px;
  margin-left: auto;
  padding: 2rem 0;
}

@media screen and (max-width: 1024px) {
  .body_home #inscricao .grid-wrapper .col-2, .body_edicao-2023 #inscricao .grid-wrapper .col-2 {
    margin-left: unset;
    grid-row: 1;
    padding: 0;
  }
}

.body_home #inscricao .grid-wrapper .col-2 .titulo, .body_edicao-2023 #inscricao .grid-wrapper .col-2 .titulo {
  color: #1b1d21;
  letter-spacing: -.36px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
}

@media screen and (max-width: 768px) {
  .body_home #inscricao .grid-wrapper .col-2 .titulo, .body_edicao-2023 #inscricao .grid-wrapper .col-2 .titulo {
    margin-bottom: .75rem;
    font-size: 24px;
  }
}

.body_home #inscricao .grid-wrapper .col-2 .descricao, .body_edicao-2023 #inscricao .grid-wrapper .col-2 .descricao {
  color: #1b1d21;
  letter-spacing: -.18px;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.body_home #inscricao .grid-wrapper .col-2 .descricao span, .body_edicao-2023 #inscricao .grid-wrapper .col-2 .descricao span {
  font-weight: 600;
}

.body_home #inscricao .grid-wrapper .button-wrapper .button, .body_edicao-2023 #inscricao .grid-wrapper .button-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #3cc5b1;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

.body_home #inscricao .grid-wrapper .button-wrapper .button span, .body_edicao-2023 #inscricao .grid-wrapper .button-wrapper .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #inscricao .grid-wrapper .button-wrapper .button:active, .body_home #inscricao .grid-wrapper .button-wrapper .button:hover, .body_edicao-2023 #inscricao .grid-wrapper .button-wrapper .button:active, .body_edicao-2023 #inscricao .grid-wrapper .button-wrapper .button:hover {
  transform: scale(1.05);
}

.body_home #inscricao .grid-wrapper .button-wrapper .button svg, .body_home #inscricao .grid-wrapper .button-wrapper .button img, .body_edicao-2023 #inscricao .grid-wrapper .button-wrapper .button svg, .body_edicao-2023 #inscricao .grid-wrapper .button-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

.body_home #inscricao .grid-wrapper .button-wrapper .button svg, .body_edicao-2023 #inscricao .grid-wrapper .button-wrapper .button svg {
  width: 24px;
  height: 24px;
}

.body_home #inscricao .grid-wrapper .img-wrapper, .body_edicao-2023 #inscricao .grid-wrapper .img-wrapper {
  height: 100%;
}

.body_home #inscricao .grid-wrapper .img-wrapper img, .body_edicao-2023 #inscricao .grid-wrapper .img-wrapper img {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  height: 100%;
}

.body_home #inscricao .grid-wrapper .img-wrapper .button-wrapper, .body_edicao-2023 #inscricao .grid-wrapper .img-wrapper .button-wrapper {
  display: none;
}

@media screen and (max-width: 1024px) {
  .body_home #inscricao .grid-wrapper .img-wrapper .button-wrapper, .body_edicao-2023 #inscricao .grid-wrapper .img-wrapper .button-wrapper {
    margin-top: 2rem;
    display: block;
  }

  .body_home #inscricao .grid-wrapper .img-wrapper .button-wrapper .button, .body_edicao-2023 #inscricao .grid-wrapper .img-wrapper .button-wrapper .button {
    max-width: unset;
    text-align: center;
    justify-content: center;
  }

  .body_home #inscricao .grid-wrapper .col-2 .button-wrapper, .body_edicao-2023 #inscricao .grid-wrapper .col-2 .button-wrapper {
    display: none;
  }
}

.body_home #inovacao, .body_edicao-2023 #inovacao {
  position: relative;
}

.body_home #inovacao .header-wrapper, .body_edicao-2023 #inovacao .header-wrapper {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.5rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .body_home #inovacao .header-wrapper, .body_edicao-2023 #inovacao .header-wrapper {
    margin-bottom: 2rem;
  }
}

.body_home #inovacao .header-wrapper h2, .body_edicao-2023 #inovacao .header-wrapper h2 {
  color: #0d4da1;
  letter-spacing: -.48px;
  text-transform: uppercase;
  max-width: 32ch;
  font-family: Causten;
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
}

@media screen and (max-width: 768px) {
  .body_home #inovacao .header-wrapper h2, .body_edicao-2023 #inovacao .header-wrapper h2 {
    font-size: 36px;
  }
}

.body_home #inovacao .header-wrapper h2 span, .body_edicao-2023 #inovacao .header-wrapper h2 span {
  font-weight: 800;
}

@media screen and (max-width: 1024px) {
  .body_home #inovacao .header-wrapper .img-detalhe, .body_edicao-2023 #inovacao .header-wrapper .img-detalhe {
    display: none;
  }
}

.body_home #inovacao .header-wrapper .img-detalhe img, .body_edicao-2023 #inovacao .header-wrapper .img-detalhe img {
  max-width: 5rem;
  max-height: 5rem;
}

.body_home #inovacao .wrapper-for-bg-img, .body_edicao-2023 #inovacao .wrapper-for-bg-img {
  position: relative;
}

.body_home #inovacao .wrapper-for-bg-img .img-wrapper, .body_edicao-2023 #inovacao .wrapper-for-bg-img .img-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
}

@media screen and (max-width: 1024px) {
  .body_home #inovacao .wrapper-for-bg-img .img-wrapper, .body_edicao-2023 #inovacao .wrapper-for-bg-img .img-wrapper {
    position: unset;
  }
}

.body_home #inovacao .wrapper-for-bg-img .img-wrapper img, .body_edicao-2023 #inovacao .wrapper-for-bg-img .img-wrapper img {
  object-fit: cover;
  height: 100%;
}

.body_home #inovacao .inovacao-wrapper, .body_edicao-2023 #inovacao .inovacao-wrapper {
  z-index: 3;
  background: linear-gradient(90deg, #f7f8f900 0%, #f7f8f9 2%);
  max-width: calc(649px + 3rem);
  margin-left: auto;
  padding-left: 3rem;
  position: relative;
}

@media screen and (max-width: 1440px) {
  .body_home #inovacao .inovacao-wrapper, .body_edicao-2023 #inovacao .inovacao-wrapper {
    max-width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #inovacao .inovacao-wrapper, .body_edicao-2023 #inovacao .inovacao-wrapper {
    margin-left: unset;
    background: unset;
    max-width: 100%;
    padding-left: 0;
  }
}

.body_home #inovacao .card-inovacao, .body_edicao-2023 #inovacao .card-inovacao {
  border-bottom: 1px solid #1b1d211a;
  padding: 1.5rem 0;
}

@media screen and (max-width: 1024px) {
  .body_home #inovacao .card-inovacao:first-child, .body_edicao-2023 #inovacao .card-inovacao:first-child {
    padding-top: 0;
  }
}

.body_home #inovacao .card-inovacao:last-child, .body_edicao-2023 #inovacao .card-inovacao:last-child {
  border-bottom: none;
}

.body_home #inovacao .card-inovacao svg, .body_edicao-2023 #inovacao .card-inovacao svg {
  width: 40px;
  height: 40px;
  margin-bottom: 1.5rem;
}

.body_home #inovacao .card-inovacao p, .body_edicao-2023 #inovacao .card-inovacao p {
  font-size: 16px;
}

.body_home #inovacao .card-inovacao .titulo, .body_edicao-2023 #inovacao .card-inovacao .titulo {
  color: #1b1d21;
  letter-spacing: -.24px;
  margin-bottom: 8px;
  font-family: Causten;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.body_home #imprensa .section-title, .body_edicao-2023 #imprensa .section-title {
  max-width: 512px;
  margin-right: 1rem;
}

.body_home #imprensa .section-title h2, .body_edicao-2023 #imprensa .section-title h2 {
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .body_home #imprensa .section-title h2, .body_edicao-2023 #imprensa .section-title h2 {
    font-size: 36px;
  }
}

.body_home #imprensa .grid-wrapper, .body_edicao-2023 #imprensa .grid-wrapper {
  grid-template-columns: 1fr 1.3fr;
  align-items: center;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_home #imprensa .grid-wrapper, .body_edicao-2023 #imprensa .grid-wrapper {
    grid-template-columns: 1fr;
  }

  .body_home #imprensa .img-wrapper, .body_edicao-2023 #imprensa .img-wrapper {
    display: none;
  }
}

.body_home #imprensa .img-wrapper img, .body_edicao-2023 #imprensa .img-wrapper img {
  width: 100%;
}

.body_home #imprensa .button-wrapper, .body_edicao-2023 #imprensa .button-wrapper {
  margin-top: 3.5rem;
}

@media screen and (max-width: 1024px) {
  .body_home #imprensa .button-wrapper, .body_edicao-2023 #imprensa .button-wrapper {
    margin-top: 2rem;
  }
}

.body_home #imprensa .button-wrapper .button, .body_edicao-2023 #imprensa .button-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #3cc5b1;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

.body_home #imprensa .button-wrapper .button span, .body_edicao-2023 #imprensa .button-wrapper .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #imprensa .button-wrapper .button:active, .body_home #imprensa .button-wrapper .button:hover, .body_edicao-2023 #imprensa .button-wrapper .button:active, .body_edicao-2023 #imprensa .button-wrapper .button:hover {
  transform: scale(1.05);
}

.body_home #imprensa .button-wrapper .button svg, .body_home #imprensa .button-wrapper .button img, .body_edicao-2023 #imprensa .button-wrapper .button svg, .body_edicao-2023 #imprensa .button-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

.body_home #imprensa .button-wrapper .button svg, .body_edicao-2023 #imprensa .button-wrapper .button svg {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 1024px) {
  .body_home #imprensa .button-wrapper .button, .body_edicao-2023 #imprensa .button-wrapper .button {
    max-width: unset;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
}

.body_home #instagram .badge-for-ornamento, .body_edicao-2023 #instagram .badge-for-ornamento {
  max-width: max-content;
  margin: 0 auto;
}

.body_home #instagram .ornamento-camera, .body_edicao-2023 #instagram .ornamento-camera {
  z-index: -1;
  position: absolute;
  bottom: -23px;
  left: -53px;
}

.body_home #instagram .section-title, .body_edicao-2023 #instagram .section-title {
  max-width: max-content;
  margin: 0 auto 3.5rem;
}

.body_home #instagram .instagram-wrapper-new #instafeed, .body_edicao-2023 #instagram .instagram-wrapper-new #instafeed {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_home #instagram .instagram-wrapper-new #instafeed, .body_edicao-2023 #instagram .instagram-wrapper-new #instafeed {
    grid-template-columns: 1fr 1fr;
  }
}

.body_home #instagram .instagram-wrapper-new #instafeed a, .body_edicao-2023 #instagram .instagram-wrapper-new #instafeed a {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.body_home #instagram .instagram-wrapper-new #instafeed a:hover .content-hover, .body_edicao-2023 #instagram .instagram-wrapper-new #instafeed a:hover .content-hover {
  transform: translateY(0);
}

.body_home #instagram .instagram-wrapper-new #instafeed a .content-hover, .body_edicao-2023 #instagram .instagram-wrapper-new #instafeed a .content-hover {
  pointer-events: none;
  text-align: center;
  background-color: #0d4da1;
  place-content: center;
  gap: 10px;
  height: 100%;
  padding: 2rem;
  transition: all .2s;
  display: grid;
  position: absolute;
  inset: 0;
  overflow: hidden;
  transform: translateY(100%);
}

.body_home #instagram .instagram-wrapper-new #instafeed a .content-hover .see-insta, .body_edicao-2023 #instagram .instagram-wrapper-new #instafeed a .content-hover .see-insta {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .body_home #instagram .instagram-wrapper-new #instafeed a .content-hover .see-insta, .body_edicao-2023 #instagram .instagram-wrapper-new #instafeed a .content-hover .see-insta {
    font-size: 1rem;
  }
}

.body_home #instagram .instagram-wrapper-new #instafeed a .content-hover .click-icon, .body_edicao-2023 #instagram .instagram-wrapper-new #instafeed a .content-hover .click-icon {
  margin-right: 2rem;
}

.body_home #instagram .instagram-wrapper-new #instafeed a .content-hover svg, .body_edicao-2023 #instagram .instagram-wrapper-new #instafeed a .content-hover svg {
  filter: brightness(0) invert();
  width: 2.0625rem;
  height: 2rem;
  margin: 0 auto;
}

.body_home #instagram .banner-embaixo, .body_edicao-2023 #instagram .banner-embaixo {
  align-items: stretch;
  gap: 8px;
  margin-top: 8px;
  display: flex;
}

@media screen and (max-width: 600px) {
  .body_home #instagram .banner-embaixo, .body_edicao-2023 #instagram .banner-embaixo {
    flex-direction: column;
  }
}

.body_home #instagram .banner-embaixo .img-wrapper, .body_edicao-2023 #instagram .banner-embaixo .img-wrapper {
  flex: 1;
}

@media screen and (max-width: 768px) {
  .body_home #instagram .banner-embaixo .img-wrapper, .body_edicao-2023 #instagram .banner-embaixo .img-wrapper {
    display: none;
  }
}

.body_home #instagram .banner-embaixo .img-wrapper:first-child, .body_edicao-2023 #instagram .banner-embaixo .img-wrapper:first-child {
  flex: 1.33;
  display: block;
}

.body_home #instagram .instagram-wrapper, .body_edicao-2023 #instagram .instagram-wrapper {
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_home #instagram .instagram-wrapper, .body_edicao-2023 #instagram .instagram-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

.body_home #instagram .img-wrapper, .body_edicao-2023 #instagram .img-wrapper {
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.body_home #instagram .img-wrapper a, .body_edicao-2023 #instagram .img-wrapper a {
  height: 100%;
  display: block;
}

.body_home #instagram .img-wrapper a img, .body_edicao-2023 #instagram .img-wrapper a img {
  object-fit: cover;
  height: 100%;
}

.body_home #instagram .img-wrapper:hover .content-hover, .body_edicao-2023 #instagram .img-wrapper:hover .content-hover {
  transform: translateY(0);
}

.body_home #instagram .img-wrapper .content-hover, .body_edicao-2023 #instagram .img-wrapper .content-hover {
  pointer-events: none;
  text-align: center;
  background-color: #0d4da1;
  place-content: center;
  gap: 10px;
  height: 100%;
  padding: 2rem;
  transition: all .2s;
  display: grid;
  position: absolute;
  inset: 0;
  overflow: hidden;
  transform: translateY(100%);
}

.body_home #instagram .img-wrapper .content-hover .see-insta, .body_edicao-2023 #instagram .img-wrapper .content-hover .see-insta {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .body_home #instagram .img-wrapper .content-hover .see-insta, .body_edicao-2023 #instagram .img-wrapper .content-hover .see-insta {
    font-size: 1rem;
  }
}

.body_home #instagram .img-wrapper .content-hover .click-icon, .body_edicao-2023 #instagram .img-wrapper .content-hover .click-icon {
  margin-right: 2rem;
}

.body_home #instagram .img-wrapper .content-hover svg, .body_edicao-2023 #instagram .img-wrapper .content-hover svg {
  filter: brightness(0) invert();
  width: 2.0625rem;
  height: 2rem;
  margin: 0 auto;
}

.body_home #contato-footer, .body_edicao-2023 #contato-footer {
  background: #fff;
  padding-bottom: 9.375rem;
  scroll-margin-top: 130px;
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer, .body_edicao-2023 #contato-footer {
    background: #fff;
    padding-bottom: 0;
  }
}

.body_home #contato-footer .contato-grid-wide, .body_edicao-2023 #contato-footer .contato-grid-wide {
  grid-template-columns: .8fr 1.2fr;
  align-items: center;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .contato-grid-wide, .body_edicao-2023 #contato-footer .contato-grid-wide {
    grid-template-columns: 1fr;
  }
}

.body_home #contato-footer .col-1, .body_edicao-2023 #contato-footer .col-1 {
  align-self: stretch;
  position: relative;
}

.body_home #contato-footer .col-1 .orna, .body_edicao-2023 #contato-footer .col-1 .orna {
  z-index: 2;
  position: absolute;
}

.body_home #contato-footer .col-1 .orna-1, .body_edicao-2023 #contato-footer .col-1 .orna-1 {
  top: 9%;
  right: -2rem;
}

@media (max-width: 1400px) {
  .body_home #contato-footer .col-1 .orna-1, .body_edicao-2023 #contato-footer .col-1 .orna-1 {
    max-width: 60px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .col-1 .orna-1, .body_edicao-2023 #contato-footer .col-1 .orna-1 {
    max-width: 47px;
    top: -24px;
    left: 3.875rem;
  }
}

.body_home #contato-footer .col-1 .orna-2, .body_edicao-2023 #contato-footer .col-1 .orna-2 {
  bottom: 119px;
  left: 160px;
}

@media (max-width: 1400px) {
  .body_home #contato-footer .col-1 .orna-2, .body_edicao-2023 #contato-footer .col-1 .orna-2 {
    bottom: 89px;
    left: 120px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .col-1 .orna-2, .body_edicao-2023 #contato-footer .col-1 .orna-2 {
    bottom: 74px;
    left: 100px;
  }
}

.body_home #contato-footer .col-1 .orna-3, .body_edicao-2023 #contato-footer .col-1 .orna-3 {
  bottom: 0;
  left: 0;
}

@media (max-width: 1400px) {
  .body_home #contato-footer .col-1 .orna-3, .body_edicao-2023 #contato-footer .col-1 .orna-3 {
    max-width: 120px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .col-1 .orna-3, .body_edicao-2023 #contato-footer .col-1 .orna-3 {
    max-width: 100px;
  }
}

.body_home #contato-footer .col-1 .orna-4, .body_edicao-2023 #contato-footer .col-1 .orna-4 {
  bottom: -40px;
  right: 20%;
}

@media (max-width: 1400px) {
  .body_home #contato-footer .col-1 .orna-4, .body_edicao-2023 #contato-footer .col-1 .orna-4 {
    max-width: 120px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .col-1 .orna-4, .body_edicao-2023 #contato-footer .col-1 .orna-4 {
    display: none;
  }
}

.body_home #contato-footer .col-1 .img-wrapper, .body_edicao-2023 #contato-footer .col-1 .img-wrapper {
  height: 100%;
}

.body_home #contato-footer .col-1 .img-wrapper img, .body_edicao-2023 #contato-footer .col-1 .img-wrapper img {
  object-fit: cover;
  object-position: left;
  width: 100%;
  height: 100%;
}

.body_home #contato-footer .col-2, .body_edicao-2023 #contato-footer .col-2 {
  padding: 7.5rem 10rem 0;
  position: relative;
}

@media (max-width: 1600px) {
  .body_home #contato-footer .col-2, .body_edicao-2023 #contato-footer .col-2 {
    padding-top: 5rem;
    padding-left: 6rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #contato-footer .col-2, .body_edicao-2023 #contato-footer .col-2 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .col-2, .body_edicao-2023 #contato-footer .col-2 {
    grid-row: 1;
    padding: 4rem 1rem 2.625rem;
  }
}

.body_home #contato-footer .col-2 .limit-width, .body_edicao-2023 #contato-footer .col-2 .limit-width {
  z-index: 1;
  max-width: 635px;
  padding-right: 50px;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body_home #contato-footer .col-2 .limit-width, .body_edicao-2023 #contato-footer .col-2 .limit-width {
    padding-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .col-2 .limit-width, .body_edicao-2023 #contato-footer .col-2 .limit-width {
    max-width: 540px;
    margin: 0 auto;
  }
}

.body_home #contato-footer .contato-wrapper, .body_edicao-2023 #contato-footer .contato-wrapper {
  margin-bottom: 3.5rem;
}

.body_home #contato-footer .contato-wrapper .label-texto, .body_edicao-2023 #contato-footer .contato-wrapper .label-texto {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #3b9c8e;
  margin-bottom: .75rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 144%;
}

.body_home #contato-footer .contato-wrapper .contato-texto, .body_edicao-2023 #contato-footer .contato-wrapper .contato-texto {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0d4da1;
  margin-bottom: 1rem;
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 140%;
}

@media (max-width: 1360px) {
  .body_home #contato-footer .contato-wrapper .contato-texto, .body_edicao-2023 #contato-footer .contato-wrapper .contato-texto {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 1200px) {
  .body_home #contato-footer .contato-wrapper .contato-texto, .body_edicao-2023 #contato-footer .contato-wrapper .contato-texto {
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #contato-footer .contato-wrapper .contato-texto, .body_edicao-2023 #contato-footer .contato-wrapper .contato-texto {
    font-size: 2.2rem;
  }
}

.body_home #contato-footer .contato-wrapper .contato-descricao, .body_edicao-2023 #contato-footer .contato-wrapper .contato-descricao {
  letter-spacing: -.02em;
  color: #36363b;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
}

.body_home #contato-footer .form-wrapper .button-wrapper .button, .body_edicao-2023 #contato-footer .form-wrapper .button-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #3cc5b1;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

.body_home #contato-footer .form-wrapper .button-wrapper .button span, .body_edicao-2023 #contato-footer .form-wrapper .button-wrapper .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #contato-footer .form-wrapper .button-wrapper .button:active, .body_home #contato-footer .form-wrapper .button-wrapper .button:hover, .body_edicao-2023 #contato-footer .form-wrapper .button-wrapper .button:active, .body_edicao-2023 #contato-footer .form-wrapper .button-wrapper .button:hover {
  transform: scale(1.05);
}

.body_home #contato-footer .form-wrapper .button-wrapper .button svg, .body_home #contato-footer .form-wrapper .button-wrapper .button img, .body_edicao-2023 #contato-footer .form-wrapper .button-wrapper .button svg, .body_edicao-2023 #contato-footer .form-wrapper .button-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

.body_home #contato-footer .form-wrapper .button-wrapper .button svg, .body_edicao-2023 #contato-footer .form-wrapper .button-wrapper .button svg {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 1024px) {
  .body_home #contato-footer .form-wrapper .button-wrapper .button, .body_edicao-2023 #contato-footer .form-wrapper .button-wrapper .button {
    max-width: unset;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
}

.body_home #contato-footer .info-contato-container, .body_edicao-2023 #contato-footer .info-contato-container {
  margin-top: 2rem;
}

.body_home #contato-footer .info-contato-container .block-wrapper-contato, .body_edicao-2023 #contato-footer .info-contato-container .block-wrapper-contato {
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.125rem;
  display: flex;
}

.body_home #contato-footer .info-contato-container .block-wrapper-contato .block-title, .body_edicao-2023 #contato-footer .info-contato-container .block-wrapper-contato .block-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}

.body_home #contato-footer .info-contato-container .block-wrapper-contato .block-info, .body_edicao-2023 #contato-footer .info-contato-container .block-wrapper-contato .block-info {
  letter-spacing: -.02em;
  color: #36363b;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
  display: block;
}

.body_home #como-chegar, .body_edicao-2023 #como-chegar {
  background-color: #fff;
  border-top: 1px solid #1b1d211a;
  border-bottom: 1px solid #1b1d211a;
}

.body_home #como-chegar .como-chegar-wrapper, .body_edicao-2023 #como-chegar .como-chegar-wrapper {
  grid-template-columns: 1fr 1.1fr;
  align-items: center;
  gap: 10px;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_home #como-chegar .como-chegar-wrapper, .body_edicao-2023 #como-chegar .como-chegar-wrapper {
    grid-template-columns: 1fr;
    gap: 3.875rem;
  }
}

.body_home #como-chegar .section-title .icon, .body_edicao-2023 #como-chegar .section-title .icon {
  background-color: #fff;
  border-radius: 50%;
  width: max-content;
  margin-bottom: 1.5rem;
  padding: 16px;
  box-shadow: 0 8px 32px #3b9c8e14;
}

.body_home #como-chegar .section-title .icon svg, .body_edicao-2023 #como-chegar .section-title .icon svg {
  color: #3b9c8e;
  width: 32px;
  height: 32px;
}

.body_home #como-chegar .section-title h2, .body_edicao-2023 #como-chegar .section-title h2 {
  margin-bottom: 1rem;
}

.body_home #como-chegar .section-title .estado, .body_edicao-2023 #como-chegar .section-title .estado {
  color: #0d4da1;
  letter-spacing: -.24px;
  margin-bottom: 4px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
}

.body_home #como-chegar .section-title .button-wrapper, .body_edicao-2023 #como-chegar .section-title .button-wrapper {
  margin-top: 3.5rem;
}

@media screen and (max-width: 1024px) {
  .body_home #como-chegar .section-title .button-wrapper, .body_edicao-2023 #como-chegar .section-title .button-wrapper {
    margin-top: 2rem;
  }
}

.body_home #como-chegar .section-title .button-wrapper .button, .body_edicao-2023 #como-chegar .section-title .button-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #3cc5b1;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

.body_home #como-chegar .section-title .button-wrapper .button span, .body_edicao-2023 #como-chegar .section-title .button-wrapper .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #como-chegar .section-title .button-wrapper .button:active, .body_home #como-chegar .section-title .button-wrapper .button:hover, .body_edicao-2023 #como-chegar .section-title .button-wrapper .button:active, .body_edicao-2023 #como-chegar .section-title .button-wrapper .button:hover {
  transform: scale(1.05);
}

.body_home #como-chegar .section-title .button-wrapper .button svg, .body_home #como-chegar .section-title .button-wrapper .button img, .body_edicao-2023 #como-chegar .section-title .button-wrapper .button svg, .body_edicao-2023 #como-chegar .section-title .button-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

.body_home #como-chegar .section-title .button-wrapper .button svg, .body_edicao-2023 #como-chegar .section-title .button-wrapper .button svg {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 1024px) {
  .body_home #como-chegar .section-title .button-wrapper .button, .body_edicao-2023 #como-chegar .section-title .button-wrapper .button {
    max-width: unset;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
}

.body_home #como-chegar .mapa-wrapper iframe, .body_edicao-2023 #como-chegar .mapa-wrapper iframe {
  width: 100%;
  height: 412px;
}

.body_home .display-hidden, .body_edicao-2023 .display-hidden {
  display: none;
}

.body_home #o-evento, .body_edicao-2023 #o-evento {
  position: relative;
}

.body_home #o-evento .section-title .section-description, .body_edicao-2023 #o-evento .section-title .section-description {
  text-align: justify;
}

.body_home #o-evento .grid-wrapper, .body_edicao-2023 #o-evento .grid-wrapper {
  grid-template-columns: .8fr 1.2fr;
  align-items: center;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_home #o-evento .grid-wrapper, .body_edicao-2023 #o-evento .grid-wrapper {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

.body_home #o-evento .icon-laranja, .body_edicao-2023 #o-evento .icon-laranja {
  position: absolute;
  top: 4rem;
  right: 0;
}

.body_home #o-evento .badge-for-ornamento, .body_edicao-2023 #o-evento .badge-for-ornamento {
  width: max-content;
  position: relative;
}

.body_home #o-evento .badge-for-ornamento .icon-luneta, .body_edicao-2023 #o-evento .badge-for-ornamento .icon-luneta {
  z-index: 2;
  position: absolute;
  top: -60px;
  right: 0;
}

.body_home #o-evento .col-2, .body_edicao-2023 #o-evento .col-2 {
  position: relative;
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .col-2, .body_edicao-2023 #o-evento .col-2 {
    margin-top: 2rem;
  }
}

.body_home #o-evento .card-flutuante, .body_edicao-2023 #o-evento .card-flutuante {
  text-align: center;
  background: #fff;
  border: 1px solid #0f0f120d;
  border-radius: 6px;
  width: 100%;
  max-width: 200px;
  padding: 1rem 1.5rem;
  position: absolute;
  box-shadow: 0 7.93959px 31.7584px #35373814;
}

@media screen and (max-width: 1200px) {
  .body_home #o-evento .card-flutuante, .body_edicao-2023 #o-evento .card-flutuante {
    max-width: 180px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .card-flutuante, .body_edicao-2023 #o-evento .card-flutuante {
    min-width: 102px;
    max-width: max-content;
    padding: 10px 14px;
  }
}

.body_home #o-evento .card-flutuante:before, .body_edicao-2023 #o-evento .card-flutuante:before {
  content: "";
  z-index: 1;
  width: 63px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.body_home #o-evento .card-flutuante-1, .body_edicao-2023 #o-evento .card-flutuante-1 {
  color: #a3238e;
  top: 5rem;
  left: 0;
}

@media screen and (max-width: 1200px) {
  .body_home #o-evento .card-flutuante-1, .body_edicao-2023 #o-evento .card-flutuante-1 {
    top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .card-flutuante-1, .body_edicao-2023 #o-evento .card-flutuante-1 {
    top: -10px;
  }
}

.body_home #o-evento .card-flutuante-1:after, .body_edicao-2023 #o-evento .card-flutuante-1:after {
  background-color: #a3238e;
}

.body_home #o-evento .card-flutuante-2, .body_edicao-2023 #o-evento .card-flutuante-2 {
  color: #56c5d0;
  bottom: 3.125rem;
  left: 0;
}

@media screen and (max-width: 1200px) {
  .body_home #o-evento .card-flutuante-2, .body_edicao-2023 #o-evento .card-flutuante-2 {
    bottom: -40px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .card-flutuante-2, .body_edicao-2023 #o-evento .card-flutuante-2 {
    bottom: -40px;
    left: 40px;
  }
}

.body_home #o-evento .card-flutuante-2:after, .body_edicao-2023 #o-evento .card-flutuante-2:after {
  background-color: #56c5d0;
}

.body_home #o-evento .card-flutuante-3, .body_edicao-2023 #o-evento .card-flutuante-3 {
  color: #f58220;
  bottom: 3.75rem;
  right: 0;
}

@media screen and (max-width: 1200px) {
  .body_home #o-evento .card-flutuante-3, .body_edicao-2023 #o-evento .card-flutuante-3 {
    bottom: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .card-flutuante-3, .body_edicao-2023 #o-evento .card-flutuante-3 {
    bottom: 4rem;
  }
}

.body_home #o-evento .card-flutuante-3:after, .body_edicao-2023 #o-evento .card-flutuante-3:after {
  background-color: #f58220;
}

.body_home #o-evento .card-flutuante .number, .body_edicao-2023 #o-evento .card-flutuante .number {
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 800;
  line-height: 117%;
}

@media screen and (max-width: 1200px) {
  .body_home #o-evento .card-flutuante .number, .body_edicao-2023 #o-evento .card-flutuante .number {
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .card-flutuante .number, .body_edicao-2023 #o-evento .card-flutuante .number {
    font-size: 24px;
  }
}

.body_home #o-evento .card-flutuante .info, .body_edicao-2023 #o-evento .card-flutuante .info {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #36363b;
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .card-flutuante .info, .body_edicao-2023 #o-evento .card-flutuante .info {
    font-size: 12px;
  }
}

.body_home #o-evento .img-wrapper, .body_edicao-2023 #o-evento .img-wrapper {
  padding: 0 100px;
  position: relative;
}

@media screen and (max-width: 1440px) {
  .body_home #o-evento .img-wrapper, .body_edicao-2023 #o-evento .img-wrapper {
    padding: 0 60px;
  }
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .img-wrapper, .body_edicao-2023 #o-evento .img-wrapper {
    padding: 0 10px;
  }
}

.body_home #o-evento .button-wrapper a, .body_edicao-2023 #o-evento .button-wrapper a {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #d6df21;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

.body_home #o-evento .button-wrapper a span, .body_edicao-2023 #o-evento .button-wrapper a span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #o-evento .button-wrapper a:active, .body_home #o-evento .button-wrapper a:hover, .body_edicao-2023 #o-evento .button-wrapper a:active, .body_edicao-2023 #o-evento .button-wrapper a:hover {
  transform: scale(1.05);
}

.body_home #o-evento .button-wrapper a svg, .body_home #o-evento .button-wrapper a img, .body_edicao-2023 #o-evento .button-wrapper a svg, .body_edicao-2023 #o-evento .button-wrapper a img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

.body_home #o-evento .button-wrapper a svg, .body_edicao-2023 #o-evento .button-wrapper a svg {
  width: 24px;
  height: 24px;
}

.body_home #o-evento .alien-orna, .body_edicao-2023 #o-evento .alien-orna {
  z-index: 2;
  position: absolute;
  bottom: -70px;
  right: 20vw;
}

@media screen and (max-width: 1440px) {
  .body_home #o-evento .alien-orna, .body_edicao-2023 #o-evento .alien-orna {
    max-width: 200px;
    bottom: -50px;
    right: 20vw;
  }
}

@media screen and (max-width: 1024px) {
  .body_home #o-evento .alien-orna, .body_edicao-2023 #o-evento .alien-orna {
    max-width: 130px;
    bottom: -100px;
    right: 1.5rem;
  }
}

.body_home #o-evento .gatinho-orna, .body_edicao-2023 #o-evento .gatinho-orna {
  margin-bottom: -20px;
}

@media screen and (max-width: 768px) {
  .body_home #o-evento .gatinho-orna, .body_edicao-2023 #o-evento .gatinho-orna {
    max-width: 123px;
    margin-left: 21px;
  }
}

.body_home #o-evento .linear-orna, .body_edicao-2023 #o-evento .linear-orna {
  position: relative;
}

.body_home #o-evento .linear-orna img, .body_edicao-2023 #o-evento .linear-orna img {
  object-fit: cover;
  width: 100%;
  min-height: 18px;
}

.body_home #atracoes, .body_edicao-2023 #atracoes {
  background-color: var(--bienal-laranja);
  background-image: url("background-eventos.442df16b.svg");
  position: relative;
}

@media screen and (max-width: 768px) {
  .body_home #atracoes, .body_edicao-2023 #atracoes {
    background-size: 80px 80px;
  }
}

.body_home #atracoes .info-sujeita, .body_edicao-2023 #atracoes .info-sujeita {
  color: #fff;
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 800;
  line-height: 114%;
}

.body_home #atracoes .section-title h2, .body_edicao-2023 #atracoes .section-title h2 {
  color: #fff;
}

@media screen and (max-width: 600px) {
  .body_home #atracoes .section-title h2, .body_edicao-2023 #atracoes .section-title h2 {
    max-width: 15ch;
  }
}

.body_home #atracoes .info-atracoes-titulo, .body_edicao-2023 #atracoes .info-atracoes-titulo {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  margin-top: 4rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 800;
  line-height: 114%;
}

.body_home #atracoes .button-wrapper, .body_edicao-2023 #atracoes .button-wrapper {
  flex-shrink: 0;
}

.body_home #atracoes .button-wrapper a, .body_edicao-2023 #atracoes .button-wrapper a {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #d6df21;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

.body_home #atracoes .button-wrapper a span, .body_edicao-2023 #atracoes .button-wrapper a span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_home #atracoes .button-wrapper a:active, .body_home #atracoes .button-wrapper a:hover, .body_edicao-2023 #atracoes .button-wrapper a:active, .body_edicao-2023 #atracoes .button-wrapper a:hover {
  transform: scale(1.05);
}

.body_home #atracoes .button-wrapper a svg, .body_home #atracoes .button-wrapper a img, .body_edicao-2023 #atracoes .button-wrapper a svg, .body_edicao-2023 #atracoes .button-wrapper a img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

.body_home #atracoes .button-wrapper a svg, .body_edicao-2023 #atracoes .button-wrapper a svg {
  width: 24px;
  height: 24px;
}

@media (min-width: 768px) {
  .body_home #atracoes .button-wrapper-bottom, .body_edicao-2023 #atracoes .button-wrapper-bottom {
    display: none;
  }
}

.body_home #atracoes .button-wrapper-bottom .button, .body_edicao-2023 #atracoes .button-wrapper-bottom .button {
  justify-content: center;
  max-width: 100%;
}

.body_home #atracoes .linear-orna img, .body_edicao-2023 #atracoes .linear-orna img {
  object-fit: cover;
  width: 100%;
  min-height: 18px;
}

.body_home #programacao, .body_edicao-2023 #programacao {
  position: relative;
}

.body_home #programacao .section-title, .body_edicao-2023 #programacao .section-title {
  width: max-content;
  margin: 0 auto 3.125rem;
  position: relative;
}

.body_home #programacao .section-title .ornamento-peixe, .body_edicao-2023 #programacao .section-title .ornamento-peixe {
  position: absolute;
  top: 50%;
  right: -4rem;
  transform: translateY(-57%);
}

.body_home #programacao .section-title h2, .body_edicao-2023 #programacao .section-title h2 {
  z-index: 4;
  position: relative;
}

.body_home #programacao .swiper-programacao-tabs, .body_edicao-2023 #programacao .swiper-programacao-tabs {
  margin-bottom: 2.5rem;
}

.body_home #programacao .swiper-programacao-tabs .swiper-wrapper, .body_edicao-2023 #programacao .swiper-programacao-tabs .swiper-wrapper {
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .body_home #programacao .swiper-programacao-tabs .swiper-wrapper, .body_edicao-2023 #programacao .swiper-programacao-tabs .swiper-wrapper {
    justify-content: unset;
  }
}

.body_home #programacao .swiper-programacao-tabs .swiper-slide, .body_edicao-2023 #programacao .swiper-programacao-tabs .swiper-slide {
  max-width: max-content;
}

.body_home #programacao .tab-programacao, .body_edicao-2023 #programacao .tab-programacao {
  cursor: pointer;
  color: #fff9;
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  background-color: #f5872c;
  border: 1px solid #fff;
  border-radius: 4px;
  min-width: 140px;
  padding: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

@media screen and (max-width: 1440px) {
  .body_home #programacao .tab-programacao, .body_edicao-2023 #programacao .tab-programacao {
    min-width: 112px;
  }
}

.body_home #programacao .tab-programacao .programacao-data, .body_edicao-2023 #programacao .tab-programacao .programacao-data {
  font-size: 1.5rem;
  font-weight: 800;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .tab-programacao .programacao-data, .body_edicao-2023 #programacao .tab-programacao .programacao-data {
    line-height: 1;
  }
}

.body_home #programacao .tab-programacao.active, .body_edicao-2023 #programacao .tab-programacao.active {
  color: #fff;
  background: #a3238e;
}

.body_home #programacao .programacao-tab-content, .body_edicao-2023 #programacao .programacao-tab-content {
  min-height: max-content;
  display: none;
  position: relative;
}

.body_home #programacao .programacao-tab-content.active, .body_edicao-2023 #programacao .programacao-tab-content.active {
  opacity: 1;
  display: block;
}

.body_home #programacao .programacao-tab-content .gatinho-orna, .body_edicao-2023 #programacao .programacao-tab-content .gatinho-orna {
  position: absolute;
  top: -100px;
  left: 15px;
}

@media screen and (max-width: 1200px) {
  .body_home #programacao .programacao-tab-content .gatinho-orna, .body_edicao-2023 #programacao .programacao-tab-content .gatinho-orna {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .gatinho-orna, .body_edicao-2023 #programacao .programacao-tab-content .gatinho-orna {
    top: unset;
    transform-origin: 0;
    display: block;
    bottom: 0;
    transform: scale(.5);
  }
}

.body_home #programacao .programacao-tab-content .grid-wrapper, .body_edicao-2023 #programacao .programacao-tab-content .grid-wrapper {
  color: #fff;
  z-index: 2;
  grid-template-columns: .3fr 1fr;
  gap: 1rem;
  display: grid;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body_home #programacao .programacao-tab-content .grid-wrapper, .body_edicao-2023 #programacao .programacao-tab-content .grid-wrapper {
    grid-template-columns: 1fr;
    gap: 4px;
  }
}

.body_home #programacao .programacao-tab-content .side-info, .body_edicao-2023 #programacao .programacao-tab-content .side-info {
  background-color: var(--bienal-rosa);
  text-align: center;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body_home #programacao .programacao-tab-content .side-info, .body_edicao-2023 #programacao .programacao-tab-content .side-info {
    grid-row: 2;
  }
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .side-info, .body_edicao-2023 #programacao .programacao-tab-content .side-info {
    background: none;
  }
}

.body_home #programacao .programacao-tab-content .side-info .content-side, .body_edicao-2023 #programacao .programacao-tab-content .side-info .content-side {
  margin: auto;
  padding: 2rem;
}

@media screen and (max-width: 1440px) {
  .body_home #programacao .programacao-tab-content .side-info .content-side, .body_edicao-2023 #programacao .programacao-tab-content .side-info .content-side {
    padding: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .side-info .content-side, .body_edicao-2023 #programacao .programacao-tab-content .side-info .content-side {
    background-color: var(--bienal-rosa);
    margin: unset;
    border-radius: 4px;
    margin-bottom: 4px;
  }
}

.body_home #programacao .programacao-tab-content .side-info .img-detail, .body_edicao-2023 #programacao .programacao-tab-content .side-info .img-detail {
  width: max-content;
  margin: 0 auto 2rem;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .side-info .img-detail, .body_edicao-2023 #programacao .programacao-tab-content .side-info .img-detail {
    margin-bottom: 0;
    position: absolute;
    top: -20px;
    right: 0;
  }

  .body_home #programacao .programacao-tab-content .side-info .img-detail img, .body_edicao-2023 #programacao .programacao-tab-content .side-info .img-detail img {
    height: 40px;
  }
}

.body_home #programacao .programacao-tab-content .side-info .programacao-horario, .body_edicao-2023 #programacao .programacao-tab-content .side-info .programacao-horario {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 8px;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.17;
}

@media screen and (max-width: 1440px) {
  .body_home #programacao .programacao-tab-content .side-info .programacao-horario, .body_edicao-2023 #programacao .programacao-tab-content .side-info .programacao-horario {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .side-info .programacao-horario, .body_edicao-2023 #programacao .programacao-tab-content .side-info .programacao-horario {
    text-align: left;
  }
}

.body_home #programacao .programacao-tab-content .side-info .programacao-local, .body_edicao-2023 #programacao .programacao-tab-content .side-info .programacao-local {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .side-info .programacao-local, .body_edicao-2023 #programacao .programacao-tab-content .side-info .programacao-local {
    text-align: left;
  }
}

.body_home #programacao .programacao-tab-content .side-info .bottom-info, .body_edicao-2023 #programacao .programacao-tab-content .side-info .bottom-info {
  background: #56c5d0;
  padding: 12px 0;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .side-info .bottom-info, .body_edicao-2023 #programacao .programacao-tab-content .side-info .bottom-info {
    border-radius: 4px;
    margin-top: 2rem;
    padding: 14px;
  }
}

.body_home #programacao .programacao-tab-content .side-info .bottom-info .button-bottom-info, .body_edicao-2023 #programacao .programacao-tab-content .side-info .bottom-info .button-bottom-info {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  display: flex;
}

.body_home #programacao .programacao-tab-content .side-info .bottom-info .button-bottom-info svg, .body_edicao-2023 #programacao .programacao-tab-content .side-info .bottom-info .button-bottom-info svg {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.3125rem;
  margin-top: -3px;
  margin-left: 4px;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper {
  background-color: var(--bienal-rosa);
  border-radius: 4px;
  grid-template-columns: .4fr .6fr;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .programacao-dia-wrapper, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-programacao-atracoes, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-programacao-atracoes {
  width: 100%;
  display: grid;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons {
  align-items: center;
  gap: 4px;
  display: flex;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons .button, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons .button {
  background-color: var(--bienal-amarelo);
  border-radius: 50%;
  place-content: center;
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons .button svg path, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons .button svg path {
  stroke: #000;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons .button.swiper-button-disabled, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons .button.swiper-button-disabled {
  background-color: #0000;
  border: 1px solid #fff;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons .button.swiper-button-disabled svg path, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .buttons .button.swiper-button-disabled svg path {
  stroke: #fff;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .swiper-button-lock, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .swiper-controls-header .swiper-button-lock {
  display: none !important;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper {
  border-right: 1px solid #fff3;
  padding: 3.5rem;
}

@media screen and (max-width: 1440px) {
  .body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper {
    padding: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper {
    border-bottom: 1px solid #fff3;
    padding: 1.5rem;
  }
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .title, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.3;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .descricao, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .descricao {
  letter-spacing: -.02em;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .descricao, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .descricao {
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .content-for-p.open .descricao, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .content-for-p.open .descricao {
  display: block;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .continuar-lendo, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .continuar-lendo {
  cursor: pointer;
  letter-spacing: -.02em;
  color: #d6df21;
  align-items: center;
  margin-top: 1rem;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  display: none;
}

@media screen and (max-width: 768px) {
  .body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .continuar-lendo, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .continuar-lendo {
    display: flex;
  }
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .continuar-lendo svg, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .dia-info-wrapper .continuar-lendo svg {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-atracoes-wrapper, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .dia-atracoes-wrapper {
  align-items: flex-start;
  width: 100%;
  padding: 3.5rem;
  display: grid;
}

@media screen and (max-width: 1440px) {
  .body_home #programacao .programacao-tab-content .programacao-dia-wrapper .dia-atracoes-wrapper, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .dia-atracoes-wrapper {
    padding: 2rem;
  }
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .header-atracoes, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .header-atracoes {
  justify-content: space-between;
  align-self: flex-end;
  align-items: center;
  margin-bottom: 11px;
  display: flex;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .header-atracoes .header-atracoes-titulo, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .header-atracoes .header-atracoes-titulo {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao {
  cursor: pointer;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao .card-evento-img, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao .card-evento-img {
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao .card-evento-img img, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao .card-evento-img img {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao .card-evento-title, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao .card-evento-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #d6df21;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
  display: -webkit-box;
  overflow: hidden;
}

.body_home #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao .card-evento-role, .body_edicao-2023 #programacao .programacao-tab-content .programacao-dia-wrapper .card-programacao .card-evento-role {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: .875rem;
  font-weight: 500;
  line-height: 157%;
}

.body_home #midia .badge-for-ornamento, .body_edicao-2023 #midia .badge-for-ornamento {
  max-width: max-content;
  margin: 0 auto;
}

.body_home #midia .ornamento-camera, .body_edicao-2023 #midia .ornamento-camera {
  z-index: -1;
  position: absolute;
  bottom: -23px;
  left: -53px;
}

.body_home #midia .section-title, .body_edicao-2023 #midia .section-title {
  max-width: max-content;
  margin: 0 auto 3.5rem;
}

.body_home .bottom-info-blog, .body_edicao-2023 .bottom-info-blog {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_home .bottom-info-blog svg, .body_edicao-2023 .bottom-info-blog svg {
  filter: brightness(0) invert();
  width: 24px;
  height: 24px;
}

.body_home .blog-card, .body_edicao-2023 .blog-card {
  border-radius: 3px;
  overflow: hidden;
}

.body_home .blog-card.blog-destaque.half, .body_edicao-2023 .blog-card.blog-destaque.half {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-card.blog-destaque.half, .body_edicao-2023 .blog-card.blog-destaque.half {
    flex-direction: column;
    grid-template-rows: 60% 40%;
    grid-template-columns: 1fr;
    display: flex;
  }
}

.body_home .blog-card.blog-destaque.half-bottom, .body_edicao-2023 .blog-card.blog-destaque.half-bottom {
  grid-template-rows: 60% 40%;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-card.blog-destaque.half-bottom, .body_edicao-2023 .blog-card.blog-destaque.half-bottom {
    flex-direction: column;
    display: flex;
  }

  .body_home .blog-card.blog-destaque .img-wrapper, .body_edicao-2023 .blog-card.blog-destaque .img-wrapper {
    flex: 1;
    padding-top: 75%;
    position: relative;
    overflow: hidden;
  }
}

.body_home .blog-card.blog-destaque .img-wrapper img, .body_edicao-2023 .blog-card.blog-destaque .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-card.blog-destaque .img-wrapper img, .body_edicao-2023 .blog-card.blog-destaque .img-wrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
  }
}

.body_home .blog-card.blog-destaque .content-blog, .body_edicao-2023 .blog-card.blog-destaque .content-blog {
  color: #fff;
  background: #a3238e;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  display: flex;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-card.blog-destaque .content-blog, .body_edicao-2023 .blog-card.blog-destaque .content-blog {
    flex: 1;
    padding: 1rem;
  }
}

.body_home .blog-card.blog-destaque .content-blog h2, .body_edicao-2023 .blog-card.blog-destaque .content-blog h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1;
  display: flex;
}

@media screen and (max-width: 1440px) {
  .body_home .blog-card.blog-destaque .content-blog h2, .body_edicao-2023 .blog-card.blog-destaque .content-blog h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .body_home .blog-card.blog-destaque .content-blog h2, .body_edicao-2023 .blog-card.blog-destaque .content-blog h2 {
    font-size: 1.2rem;
  }
}

.body_home .blog-card.blog-destaque .content-blog .info-blog, .body_edicao-2023 .blog-card.blog-destaque .content-blog .info-blog {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fffc;
  flex-wrap: wrap;
  column-gap: 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

@media (min-width: 768px) {
  .body_home .swiper-midia, .body_edicao-2023 .swiper-midia {
    display: none;
  }
}

.body_home .swiper-midia, .body_edicao-2023 .swiper-midia {
  overflow: visible;
}

.body_home .swiper-midia .swiper-slide, .body_edicao-2023 .swiper-midia .swiper-slide {
  height: auto;
}

.body_home .swiper-midia .swiper-slide .blog-card, .body_edicao-2023 .swiper-midia .swiper-slide .blog-card {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.body_home .swiper-midia .swiper-slide .blog-card .img-wrapper, .body_edicao-2023 .swiper-midia .swiper-slide .blog-card .img-wrapper {
  flex: 1;
}

.body_home .swiper-midia .swiper-slide .blog-card .content-blog, .body_edicao-2023 .swiper-midia .swiper-slide .blog-card .content-blog {
  flex: unset;
}

.body_home .swiper-midia .swiper-slide:first-child .content-blog, .body_edicao-2023 .swiper-midia .swiper-slide:first-child .content-blog {
  background-color: var(--bienal-rosa);
}

.body_home .swiper-midia .swiper-slide:nth-child(2) .content-blog, .body_edicao-2023 .swiper-midia .swiper-slide:nth-child(2) .content-blog {
  background-color: #369aa3;
}

.body_home .swiper-midia .swiper-slide:nth-child(3) .content-blog, .body_edicao-2023 .swiper-midia .swiper-slide:nth-child(3) .content-blog {
  background-color: var(--bienal-laranja);
}

.body_home .swiper-midia .swiper-slide:nth-child(4) .content-blog, .body_edicao-2023 .swiper-midia .swiper-slide:nth-child(4) .content-blog {
  background-color: #369aa3;
}

.body_home .swiper-midia .todas-noticias, .body_edicao-2023 .swiper-midia .todas-noticias {
  border: 1px solid #0f0f12;
  border-radius: 3px;
  margin-top: 1rem;
  padding: 10px;
  display: block;
}

.body_home .swiper-midia .todas-noticias p, .body_edicao-2023 .swiper-midia .todas-noticias p {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  display: flex;
}

.body_home .swiper-midia .todas-noticias svg, .body_edicao-2023 .swiper-midia .todas-noticias svg {
  width: 20px;
  height: 20px;
}

.body_home .blog-grid-wrapper, .body_edicao-2023 .blog-grid-wrapper {
  grid-template-rows: 27.8125rem 27.8125rem;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  display: grid;
}

@media (max-width: 768px) {
  .body_home .blog-grid-wrapper, .body_edicao-2023 .blog-grid-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .body_home .blog-grid-wrapper, .body_edicao-2023 .blog-grid-wrapper {
    grid-template-rows: 25rem 25rem;
    gap: 1rem;
  }
}

@media screen and (max-width: 1200px) {
  .body_home .blog-grid-wrapper, .body_edicao-2023 .blog-grid-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }
}

@media screen and (max-width: 768px) {
  .body_home .blog-grid-wrapper, .body_edicao-2023 .blog-grid-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_home .blog-grid-wrapper .blog-card:first-child, .body_edicao-2023 .blog-grid-wrapper .blog-card:first-child {
  grid-column: 1 / 4;
  grid-template-columns: 1.6fr 1.4fr;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-grid-wrapper .blog-card:first-child, .body_edicao-2023 .blog-grid-wrapper .blog-card:first-child {
    grid-template-columns: 1fr;
    grid-column: unset;
  }
}

.body_home .blog-grid-wrapper .blog-card:first-child .content-blog, .body_edicao-2023 .blog-grid-wrapper .blog-card:first-child .content-blog {
  background-color: var(--bienal-rosa);
}

.body_home .blog-grid-wrapper .blog-card:first-child img, .body_edicao-2023 .blog-grid-wrapper .blog-card:first-child img {
  max-height: 445px;
}

.body_home .blog-grid-wrapper .blog-card:nth-child(2), .body_edicao-2023 .blog-grid-wrapper .blog-card:nth-child(2) {
  grid-column: 4 / 6;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-grid-wrapper .blog-card:nth-child(2), .body_edicao-2023 .blog-grid-wrapper .blog-card:nth-child(2) {
    grid-column: unset;
  }
}

.body_home .blog-grid-wrapper .blog-card:nth-child(2) .content-blog, .body_edicao-2023 .blog-grid-wrapper .blog-card:nth-child(2) .content-blog {
  background-color: #369aa3;
}

.body_home .blog-grid-wrapper .blog-card:nth-child(3), .body_edicao-2023 .blog-grid-wrapper .blog-card:nth-child(3) {
  grid-area: 2 / 1 / auto / 3;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-grid-wrapper .blog-card:nth-child(3), .body_edicao-2023 .blog-grid-wrapper .blog-card:nth-child(3) {
    grid-column: unset;
    grid-row: unset;
  }
}

.body_home .blog-grid-wrapper .blog-card:nth-child(3) .content-blog, .body_edicao-2023 .blog-grid-wrapper .blog-card:nth-child(3) .content-blog {
  background-color: var(--bienal-laranja);
}

.body_home .blog-grid-wrapper .blog-card:nth-child(4), .body_edicao-2023 .blog-grid-wrapper .blog-card:nth-child(4) {
  grid-area: 2 / 3 / auto / 5;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-grid-wrapper .blog-card:nth-child(4), .body_edicao-2023 .blog-grid-wrapper .blog-card:nth-child(4) {
    grid-column: unset;
    grid-row: unset;
  }
}

.body_home .blog-grid-wrapper .blog-card:nth-child(4) .content-blog, .body_edicao-2023 .blog-grid-wrapper .blog-card:nth-child(4) .content-blog {
  background-color: #369aa3;
}

.body_home .blog-grid-wrapper .todas-noticias, .body_edicao-2023 .blog-grid-wrapper .todas-noticias {
  background: #fff;
  border: 1px solid #0f0f12;
  border-radius: 3px;
  place-content: center;
  padding: 1rem;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_home .blog-grid-wrapper .todas-noticias, .body_edicao-2023 .blog-grid-wrapper .todas-noticias {
    grid-column: span 2;
  }
}

@media screen and (max-width: 768px) {
  .body_home .blog-grid-wrapper .todas-noticias, .body_edicao-2023 .blog-grid-wrapper .todas-noticias {
    grid-column: unset;
  }
}

.body_home .blog-grid-wrapper .todas-noticias p, .body_edicao-2023 .blog-grid-wrapper .todas-noticias p {
  letter-spacing: -.02em;
  text-transform: uppercase;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

.body_home .blog-grid-wrapper .todas-noticias svg, .body_edicao-2023 .blog-grid-wrapper .todas-noticias svg {
  width: 20px;
  height: 21px;
  margin-bottom: 5px;
}

.body_home #youtube, .body_edicao-2023 #youtube {
  background-color: var(--bienal-laranja);
  background-image: url("background-eventos.442df16b.svg");
  position: relative;
}

@media screen and (max-width: 768px) {
  .body_home #youtube, .body_edicao-2023 #youtube {
    background-size: 80px 80px;
  }
}

@media (min-width: 1024px) {
  .body_home #youtube .swiper-pagination, .body_edicao-2023 #youtube .swiper-pagination {
    position: unset;
    margin-right: 2rem;
    max-width: 80px !important;
  }
}

.body_home #youtube .swiper-pagination, .body_edicao-2023 #youtube .swiper-pagination {
  max-width: 80px !important;
}

.body_home #youtube .controls, .body_edicao-2023 #youtube .controls {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.body_home #youtube .controls .buttons, .body_edicao-2023 #youtube .controls .buttons {
  flex-shrink: 0;
  align-items: center;
  gap: 1rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .body_home #youtube .controls .buttons, .body_edicao-2023 #youtube .controls .buttons {
    display: none;
  }
}

.body_home #youtube .controls .buttons .button, .body_edicao-2023 #youtube .controls .buttons .button {
  background: #d6df21;
  border-radius: 3px;
  place-content: center;
  gap: 8px;
  width: 56px;
  height: 56px;
  padding: 8px 16px;
  display: grid;
}

.body_home #youtube .controls .buttons .button svg, .body_edicao-2023 #youtube .controls .buttons .button svg {
  width: 24px;
  height: 24px;
}

.body_home #youtube .controls .buttons .button.button-left svg, .body_edicao-2023 #youtube .controls .buttons .button.button-left svg {
  transform: rotate(180deg);
}

.body_home #youtube .controls .buttons .swiper-button-disabled, .body_edicao-2023 #youtube .controls .buttons .swiper-button-disabled {
  background: #e3e3e6;
}

@media screen and (max-width: 1024px) {
  .body_home #youtube .swiper-youtube, .body_edicao-2023 #youtube .swiper-youtube {
    overflow: visible;
  }

  .body_home #youtube .swiper-youtube .swiper-wrapper, .body_edicao-2023 #youtube .swiper-youtube .swiper-wrapper {
    padding-bottom: 4rem;
  }
}

.body_home #youtube .swiper-youtube .swiper-slide, .body_edicao-2023 #youtube .swiper-youtube .swiper-slide {
  height: auto;
}

.body_home #youtube .swiper-youtube .swiper-slide > a, .body_edicao-2023 #youtube .swiper-youtube .swiper-slide > a {
  height: 100%;
  display: grid;
}

.body_home #youtube .swiper-pagination .swiper-pagination-bullet, .body_edicao-2023 #youtube .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  background: #ffffff5c;
}

.body_home #youtube .swiper-pagination .swiper-pagination-bullet-active, .body_edicao-2023 #youtube .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--bienal-amarelo);
  border-radius: 8px;
  width: 16px;
}

.body_home #youtube .section-title h2, .body_edicao-2023 #youtube .section-title h2 {
  color: #fff;
}

.body_home #youtube .card-youtube, .body_edicao-2023 #youtube .card-youtube {
  color: #fff;
  flex-direction: column;
  display: flex;
}

.body_home #youtube .card-youtube .img-wrapper, .body_edicao-2023 #youtube .card-youtube .img-wrapper {
  margin-bottom: 1.5rem;
  padding-top: 56.25%;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body_home #youtube .card-youtube .img-wrapper, .body_edicao-2023 #youtube .card-youtube .img-wrapper {
    margin-bottom: 1rem;
  }
}

.body_home #youtube .card-youtube .img-wrapper img, .body_edicao-2023 #youtube .card-youtube .img-wrapper img {
  object-fit: cover;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_home #youtube .card-youtube .data, .body_edicao-2023 #youtube .card-youtube .data {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: var(--bienal-rosa);
  margin-bottom: 8px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
}

.body_home #youtube .card-youtube .video-title, .body_edicao-2023 #youtube .card-youtube .video-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  height: 3.5625rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  display: -webkit-box;
  overflow: hidden;
}

.body_blog #categorias {
  background: #fff;
  border-bottom: 1px solid #0f0f121a;
}

.body_blog #categorias .container {
  overflow: hidden;
}

.body_blog #categorias .tabs-wrapper {
  justify-content: space-between;
  display: flex;
}

.body_blog #categorias .tab {
  flex-basis: 33%;
}

.body_blog #categorias .tab span {
  border-radius: 20px;
  padding: .4375rem 1.25rem;
  transition: all .2s;
}

.body_blog #categorias .tab.active span {
  color: #000;
  background: #d6df2133;
}

.body_blog #categorias .tab:hover span {
  color: #000;
  background: #d6df2133;
  border-radius: 20px;
  padding: .4375rem 1.25rem;
}

.body_blog #categorias .tab a {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f1266;
  padding: 1.1875rem 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
  display: block;
}

.body_blog #destaque {
  padding-top: 6rem;
}

.body_blog #destaque .title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 2.5rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
}

.body_blog #destaque .bottom-info-blog {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_blog #destaque .bottom-info-blog svg {
  filter: brightness(0) invert();
  width: 24px;
  height: 24px;
}

.body_blog #destaque .swiper-destaque {
  margin-top: -104px;
  padding-top: 104px;
}

.body_blog #destaque .swiper-destaque .gatinho {
  z-index: 2;
  position: absolute;
  top: 8px;
  right: 140px;
}

@media screen and (max-width: 768px) {
  .body_blog #destaque .swiper-destaque .gatinho {
    display: none;
  }
}

.body_blog #destaque .swiper-destaque .livro-laranja {
  max-width: 122px;
  position: absolute;
  top: 48px;
  right: 5px;
}

@media screen and (max-width: 768px) {
  .body_blog #destaque .swiper-destaque .livro-laranja {
    display: none;
  }
}

.body_blog #destaque .swiper-destaque .swiper-wrapper {
  z-index: 2;
  position: relative;
}

.body_blog #destaque .swiper-destaque .swiper-slide {
  height: auto;
}

.body_blog #destaque .swiper-destaque .blog-card {
  border-radius: 3px;
  flex-direction: column;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.body_blog #destaque .swiper-destaque .content-blog {
  color: #fff;
  background: #a3238e;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  display: flex;
}

@media screen and (max-width: 1200px) {
  .body_blog #destaque .swiper-destaque .content-blog {
    flex: 1;
    padding: 1rem;
  }
}

.body_blog #destaque .swiper-destaque .content-blog .title-wrapper {
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.body_blog #destaque .swiper-destaque .content-blog h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1;
  display: -webkit-box;
  overflow: hidden;
}

@media screen and (max-width: 1440px) {
  .body_blog #destaque .swiper-destaque .content-blog h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .body_blog #destaque .swiper-destaque .content-blog h2 {
    font-size: 1.2rem;
  }
}

.body_blog #destaque .swiper-destaque .content-blog .info-blog {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fffc;
  flex-wrap: wrap;
  column-gap: 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

.body_blog #destaque .swiper-destaque .img-wrapper {
  flex: 1;
  padding-top: 52.15%;
  position: relative;
  overflow: hidden;
}

.body_blog #destaque .swiper-destaque .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_blog #destaque .swiper-destaque .swiper-slide:first-child .blog-card {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_blog #destaque .swiper-destaque .swiper-slide:first-child .blog-card {
    display: flex;
  }
}

.body_blog #destaque .swiper-destaque .swiper-slide:first-child .blog-card .content-blog {
  background-color: #a3238e;
}

.body_blog #destaque .swiper-destaque .swiper-slide:nth-child(2) .content-blog {
  background-color: #369aa3;
}

.body_blog #todos-posts {
  margin-bottom: 7.5rem;
  padding-top: 4.75rem;
}

.body_blog #todos-posts .header-wrapper {
  background: url("footer-linear.f1b78cf0.webp") 0 0 / cover no-repeat;
  margin-bottom: 4rem;
  padding: 1.5rem 2rem;
  position: relative;
}

.body_blog #todos-posts .header-wrapper h2 {
  color: #fff;
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.33;
}

.body_blog #todos-posts .header-wrapper .ornamente-peixe {
  position: absolute;
  top: calc(50% - 20px);
  right: 10px;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .body_blog #todos-posts .header-wrapper .ornamente-peixe {
    max-width: 120px;
    top: calc(50% - 10px);
  }
}

.body_blog #todos-posts .blog-wrapper {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin-bottom: 4rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_blog #todos-posts .blog-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .body_blog #todos-posts .blog-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_blog #todos-posts .card-blog {
  border-radius: 3px;
  max-width: 600px;
  overflow: hidden;
}

.body_blog #todos-posts .card-blog .cat {
  background-color: var(--bienal-rosa);
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  border-radius: 16px;
  margin-bottom: .75rem;
  padding: 3px 1rem 0;
  font-size: .875rem;
  font-weight: 700;
  line-height: 22px;
  display: inline-block;
}

.body_blog #todos-posts .card-blog .cat.rosa {
  background-color: var(--bienal-rosa);
}

.body_blog #todos-posts .card-blog .cat.roxo {
  background-color: #2e3192;
}

.body_blog #todos-posts .card-blog .img-wrapper {
  padding-top: 52.15%;
  position: relative;
  overflow: hidden;
}

.body_blog #todos-posts .card-blog .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_blog #todos-posts .card-blog .content-blog {
  background-color: #f3f3f5;
  padding: 2rem;
}

@media screen and (max-width: 1440px) {
  .body_blog #todos-posts .card-blog .content-blog {
    padding: 1.5rem;
  }
}

.body_blog #todos-posts .card-blog .content-blog h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.3;
  display: -webkit-box;
  overflow: hidden;
}

.body_blog #todos-posts .card-blog .bottom-info-blog {
  color: #59595c;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_blog #todos-posts .card-blog .bottom-info-blog span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.body_blog #todos-posts .card-blog .bottom-info-blog svg {
  width: 24px;
  height: 24px;
}

.body_blog #todos-posts .pagination .page-list {
  justify-content: center;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.body_blog #todos-posts .pagination .page-list a {
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  padding: .375rem .875rem;
  display: block;
}

.body_blog #todos-posts .pagination li {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.body_blog #todos-posts .pagination li.current {
  background-color: #000;
}

.body_blog #todos-posts .pagination li.current a {
  color: #fff;
}

.body_blog #todos-posts .pagination .on-edges {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  background-color: var(--bienal-amarelo);
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.body_blog #todos-posts .pagination .on-edges.left svg {
  align-self: flex-start;
  margin-right: 8px;
  transform: rotate(180deg);
}

.body_blog #todos-posts .pagination .on-edges.right svg {
  align-self: flex-start;
  margin-left: 8px;
}

.body_blog #todos-posts .pagination .on-edges a {
  align-items: center;
  display: flex;
}

.body_blog #todos-posts .pagination .on-edges svg {
  width: 20px;
  height: 20px;
}

@media (min-width: 1024px) {
  .body_single_blog, .body_single_blog main {
    overflow: unset;
  }

  .body_single_blog .side-bar {
    position: sticky;
    top: 120px;
  }
}

.body_single_blog #single-post {
  margin-bottom: 10rem;
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post {
    margin-bottom: 6rem;
  }
}

.body_single_blog #single-post .container {
  padding-top: 4rem;
}

.body_single_blog #single-post .breadcrumbs {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-left: 8.5rem;
  display: flex;
}

@media screen and (max-width: 1440px) {
  .body_single_blog #single-post .breadcrumbs {
    grid-template-columns: 325px 1fr;
    padding-left: 4.5rem;
  }
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .breadcrumbs {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.body_single_blog #single-post .breadcrumbs ul {
  align-items: center;
  display: flex;
}

.body_single_blog #single-post .breadcrumbs ul .info-bread {
  margin-left: 5px;
}

.body_single_blog #single-post .breadcrumbs ul a {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #59595c;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5;
}

.body_single_blog #single-post .breadcrumbs .icon-mail svg, .body_single_blog #single-post .breadcrumbs .right svg {
  width: 20px;
  height: 20px;
}

.body_single_blog #single-post .breadcrumbs .go-back {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  align-items: center;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.5;
  display: flex;
}

.body_single_blog #single-post .breadcrumbs .go-back svg {
  width: 24px;
  height: 24px;
  margin-right: 2px;
  transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .breadcrumbs .go-back {
    display: none;
  }
}

.body_single_blog #single-post .single-title-wrapper {
  margin-bottom: 3.75rem;
  padding-left: 8.5rem;
}

@media screen and (max-width: 1440px) {
  .body_single_blog #single-post .single-title-wrapper {
    grid-template-columns: 325px 1fr;
    padding-left: 4.5rem;
  }
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .single-title-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.body_single_blog #single-post .single-title-wrapper .cat {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  border-radius: 16px;
  max-width: max-content;
  margin-bottom: 12px;
  padding: 5px 16px;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.5;
}

.body_single_blog #single-post .single-title-wrapper .cat.rosa {
  background-color: var(--bienal-rosa);
}

.body_single_blog #single-post .single-title-wrapper .post-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0c1428;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
}

.body_single_blog #single-post .single-title-wrapper .bottom-info-blog {
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.body_single_blog #single-post .single-title-wrapper .bottom-info-blog span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #59595c;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.body_single_blog #single-post .single-title-wrapper .bottom-info-blog svg {
  filter: brightness(0) invert();
  width: 24px;
  height: 24px;
}

.body_single_blog #single-post .main-img-wrapper {
  margin-bottom: 4rem;
  position: relative;
}

.body_single_blog #single-post .main-img-wrapper:before {
  content: "";
  background-color: #f3f3f5;
  border-radius: 3px;
  width: 300px;
  height: 123px;
  position: absolute;
  bottom: -1.75rem;
  left: 0;
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .main-img-wrapper:before {
    display: none;
  }
}

.body_single_blog #single-post .main-img-wrapper .destaque-img-wrapper {
  z-index: 2;
  border-radius: 3px;
  padding-top: 1.5rem;
  padding-left: 8.5rem;
  padding-right: 8.5rem;
  position: relative;
}

@media screen and (max-width: 1440px) {
  .body_single_blog #single-post .main-img-wrapper .destaque-img-wrapper {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .main-img-wrapper .destaque-img-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.body_single_blog #single-post .main-img-wrapper .destaque-img-wrapper img {
  object-fit: cover;
  border-radius: 3px;
  width: 100%;
  max-height: 473px;
}

.body_single_blog #single-post .degrade-bg {
  border-radius: 3px;
  max-width: 60%;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .degrade-bg {
    left: 0;
    right: unset;
    max-width: 70%;
  }
}

.body_single_blog #single-post .degrade-bg img {
  border-radius: 3px;
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .degrade-bg img {
    object-fit: cover;
    height: 140px;
  }
}

.body_single_blog #single-post .blog-content-wrapper {
  grid-template-columns: 376px 1fr;
  align-items: flex-start;
  gap: 2rem;
  padding-left: 8.5rem;
  display: grid;
}

@media screen and (max-width: 1440px) {
  .body_single_blog #single-post .blog-content-wrapper {
    grid-template-columns: 325px 1fr;
    padding-left: 4.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_single_blog #single-post .blog-content-wrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .body_single_blog #single-post .blog-content-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.body_single_blog #single-post .side-bar {
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  padding: 16px;
}

.body_single_blog #single-post .side-bar .title-block {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
}

.body_single_blog #single-post .side-bar .destaque-side {
  background-color: var(--bienal-rosa);
  color: #fff;
  border: 4px solid #ffcd29;
  margin-bottom: 1rem;
  padding: 12px;
}

.body_single_blog #single-post .side-bar .destaque-side .title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.4;
}

.body_single_blog #single-post .side-bar .destaque-side p {
  letter-spacing: -.02em;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.body_single_blog #single-post .side-bar .categorias {
  border-top: 1px solid #0f0f121a;
  border-bottom: 1px solid #0f0f121a;
  padding: 12px;
}

.body_single_blog #single-post .side-bar .categorias li {
  margin-bottom: 5px;
}

.body_single_blog #single-post .side-bar .categorias a {
  letter-spacing: -.02em;
  color: #36363b;
  padding: 5px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.body_single_blog #single-post .side-bar .escrito-wrapper {
  padding: 12px;
}

.body_single_blog #single-post .side-bar .escrito-wrapper .autor-wrapper {
  align-items: center;
  gap: 8px;
  display: flex;
}

.body_single_blog #single-post .side-bar .escrito-wrapper .autor-wrapper .autor-img-wrapper {
  border-radius: 50%;
  overflow: hidden;
}

.body_single_blog #single-post .side-bar .escrito-wrapper .autor-wrapper .autor-img-wrapper img {
  width: 56px;
  height: 56px;
}

.body_single_blog #single-post .side-bar .escrito-wrapper .autor-wrapper .autor-info .nome {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.body_single_blog #single-post .side-bar .escrito-wrapper .autor-wrapper .autor-info p {
  letter-spacing: -.02em;
  color: #36363b;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.body_single_blog #single-post .side-bar .compartilhar-wrapper {
  border-top: 1px solid #0f0f121a;
  padding: 12px;
}

.body_single_blog #single-post .side-bar .compartilhar-wrapper .sharer-icons {
  align-items: center;
  gap: 8px;
  display: flex;
}

.body_single_blog #single-post .side-bar .compartilhar-wrapper .sharer-icons li {
  background-color: var(--bienal-amarelo);
  cursor: pointer;
}

.body_single_blog #single-post .side-bar .compartilhar-wrapper .sharer-icons li svg {
  width: 1.5rem;
  height: 1.5rem;
}

.body_single_blog #single-post .side-bar .compartilhar-wrapper .sharer-icons li span {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-left: 6px;
  font-size: 12px;
  font-weight: 500;
}

.body_single_blog #single-post .side-bar .compartilhar-wrapper .sharer-icons li a {
  align-items: center;
  padding: 8px;
  display: flex;
}

.body_single_blog #single-post .blog-content {
  width: 100%;
  max-width: 984px;
  margin-left: auto;
  padding-right: 8.5rem;
}

@media screen and (max-width: 1440px) {
  .body_single_blog #single-post .blog-content {
    padding-right: 4.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_single_blog #single-post .blog-content {
    grid-row: 1;
    padding-right: 0;
  }
}

.body_single_blog #veja-mais {
  margin-bottom: 4rem;
}

@media screen and (max-width: 768px) {
  .body_single_blog #veja-mais {
    margin-bottom: 2rem;
  }
}

.body_single_blog #veja-mais .header-wrapper {
  background: url("footer-linear.f1b78cf0.webp") 0 0 / cover no-repeat;
  margin-bottom: 4rem;
  padding: 1.5rem 2rem;
  position: relative;
}

.body_single_blog #veja-mais .header-wrapper h2 {
  color: #fff;
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.33;
}

.body_single_blog #veja-mais .header-wrapper .ornamente-peixe {
  position: absolute;
  top: calc(50% - 20px);
  right: 10px;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .body_single_blog #veja-mais .header-wrapper .ornamente-peixe {
    max-width: 120px;
    display: none;
    top: calc(50% - 10px);
  }
}

.body_single_blog #veja-mais .bottom-info-blog {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_single_blog #veja-mais .bottom-info-blog svg {
  filter: brightness(0) invert();
  width: 24px;
  height: 24px;
}

.body_single_blog #veja-mais .blog-card {
  border-radius: 3px;
  overflow: hidden;
}

.body_single_blog #veja-mais .blog-card.blog-destaque.half {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-card.blog-destaque.half {
    flex-direction: column;
    grid-template-rows: 60% 40%;
    grid-template-columns: 1fr;
    display: flex;
  }
}

.body_single_blog #veja-mais .blog-card.blog-destaque.half-bottom {
  grid-template-rows: 60% 40%;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-card.blog-destaque.half-bottom {
    flex-direction: column;
    display: flex;
  }

  .body_single_blog #veja-mais .blog-card.blog-destaque .img-wrapper {
    flex: 1;
    padding-top: 75%;
    position: relative;
    overflow: hidden;
  }
}

.body_single_blog #veja-mais .blog-card.blog-destaque .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-card.blog-destaque .img-wrapper img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
  }
}

.body_single_blog #veja-mais .blog-card.blog-destaque .content-blog {
  color: #fff;
  background: #a3238e;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  display: flex;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-card.blog-destaque .content-blog {
    padding: 1rem;
  }
}

.body_single_blog #veja-mais .blog-card.blog-destaque .content-blog h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1;
  display: flex;
}

@media screen and (max-width: 1440px) {
  .body_single_blog #veja-mais .blog-card.blog-destaque .content-blog h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-card.blog-destaque .content-blog h2 {
    font-size: 1.2rem;
  }
}

.body_single_blog #veja-mais .blog-card.blog-destaque .content-blog .info-blog {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fffc;
  flex-wrap: wrap;
  column-gap: 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

@media (min-width: 769px) {
  .body_single_blog #veja-mais .swiper-midia {
    display: none;
  }
}

.body_single_blog #veja-mais .swiper-midia {
  overflow: visible;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide {
  height: auto;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide .blog-card {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide .blog-card .img-wrapper {
  flex: 1;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide .blog-card .content-blog {
  flex: unset;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide:first-child .content-blog {
  background-color: var(--bienal-rosa);
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide:nth-child(2) .content-blog {
  background-color: #369aa3;
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide:nth-child(3) .content-blog {
  background-color: var(--bienal-laranja);
}

.body_single_blog #veja-mais .swiper-midia .swiper-slide:nth-child(4) .content-blog {
  background-color: #369aa3;
}

.body_single_blog #veja-mais .blog-grid-wrapper {
  grid-template-rows: 27.8125rem 27.8125rem;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  display: grid;
}

@media (max-width: 768px) {
  .body_single_blog #veja-mais .blog-grid-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .body_single_blog #veja-mais .blog-grid-wrapper {
    grid-template-rows: 25rem 25rem;
    gap: 1rem;
  }
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-grid-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }
}

@media screen and (max-width: 768px) {
  .body_single_blog #veja-mais .blog-grid-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:first-child {
  grid-column: 1 / 4;
  grid-template-columns: 1.6fr 1.4fr;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-grid-wrapper .blog-card:first-child {
    grid-template-columns: 1fr;
    grid-column: unset;
  }
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:first-child .content-blog {
  background-color: var(--bienal-rosa);
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:first-child img {
  max-height: 445px;
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(2) {
  grid-column: 4 / 6;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(2) {
    grid-column: unset;
  }
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(2) .content-blog {
  background-color: #369aa3;
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(3) {
  grid-area: 2 / 1 / auto / 3;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(3) {
    grid-column: unset;
    grid-row: unset;
  }
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(3) .content-blog {
  background-color: var(--bienal-laranja);
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(4) {
  grid-area: 2 / 3 / auto / 5;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(4) {
    grid-column: unset;
    grid-row: unset;
  }
}

.body_single_blog #veja-mais .blog-grid-wrapper .blog-card:nth-child(4) .content-blog {
  background-color: #369aa3;
}

.body_single_blog #veja-mais .blog-grid-wrapper .todas-noticias {
  background: #fff;
  border: 1px solid #0f0f12;
  border-radius: 3px;
  place-content: center;
  padding: 1rem;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_single_blog #veja-mais .blog-grid-wrapper .todas-noticias {
    grid-column: span 2;
  }
}

@media screen and (max-width: 768px) {
  .body_single_blog #veja-mais .blog-grid-wrapper .todas-noticias {
    grid-column: unset;
  }
}

.body_single_blog #veja-mais .blog-grid-wrapper .todas-noticias p {
  letter-spacing: -.02em;
  text-transform: uppercase;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

.body_single_blog #veja-mais .blog-grid-wrapper .todas-noticias svg {
  width: 20px;
  height: 21px;
  margin-bottom: 5px;
}

.body_eventos #palestrantes {
  padding: 6rem 0;
}

.body_eventos #palestrantes .grid-palestrantes {
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  display: grid;
}

@media (max-width: 1440px) {
  .body_eventos #palestrantes .grid-palestrantes {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .body_eventos #palestrantes .grid-palestrantes {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .body_eventos #palestrantes .grid-palestrantes {
    grid-template-columns: 1fr;
  }
}

.body_eventos .pagination {
  margin-top: 2rem;
}

.body_eventos .pagination .page-list {
  justify-content: center;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.body_eventos .pagination .page-list a {
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  padding: .375rem .875rem;
  display: block;
}

.body_eventos .pagination li {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.body_eventos .pagination li.current {
  background-color: #3b9c8e;
}

.body_eventos .pagination li.current a {
  color: #fff;
}

.body_eventos .pagination .on-edges {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #7b7c7f;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.body_eventos .pagination .on-edges.left svg {
  align-self: flex-start;
  transform: rotate(180deg);
}

.body_eventos .pagination .on-edges.right svg {
  align-self: flex-start;
}

.body_eventos .pagination .on-edges a {
  align-items: center;
  display: flex;
}

.body_eventos .pagination .on-edges svg {
  width: 20px;
  height: 20px;
}

.body-o-evento #o-evento {
  position: relative;
}

.body-o-evento #o-evento .header-linear-mobile {
  background-image: url("linear-mobile-evento.2b3f44ed.webp");
  background-size: cover;
  height: 62px;
  display: none;
  position: relative;
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .header-linear-mobile {
    display: block;
  }
}

.body-o-evento #o-evento .header-linear-mobile .alien-mobile {
  max-width: 69px;
  position: absolute;
  bottom: -10px;
  right: 16px;
}

.body-o-evento #o-evento .header-linear-mobile .livro-rosa {
  max-width: 83px;
  position: absolute;
  bottom: 0;
  right: 95px;
}

.body-o-evento #o-evento .vertical-wrapper {
  width: 100%;
  max-width: 200px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1rem;
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .vertical-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1440px) {
  .body-o-evento #o-evento .vertical-wrapper {
    left: -13px;
  }
}

.body-o-evento #o-evento .vertical-wrapper .linear {
  height: 100%;
}

.body-o-evento #o-evento .vertical-wrapper .linear img {
  object-fit: cover;
  width: 100%;
  max-width: 130px;
  height: 100%;
  margin: 0 auto;
}

.body-o-evento #o-evento .vertical-wrapper .vertical-items-wrapper {
  z-index: 3;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4.375rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

.body-o-evento #o-evento .vertical-wrapper .vertical-items-wrapper .alienigena-orna {
  max-width: 105px;
  margin-left: auto;
}

.body-o-evento #o-evento .vertical-wrapper .vertical-items-wrapper .menina-voando {
  margin-left: auto;
}

.body-o-evento #o-evento .vertical-wrapper .vertical-items-wrapper .peixe-wrapper {
  max-width: 140px;
}

.body-o-evento #o-evento .vertical-wrapper .vertical-items-wrapper .menina-livro {
  margin-left: auto;
}

@media (max-width: 1800px) {
  .body-o-evento #o-evento .for-space {
    margin-left: 200px;
  }
}

@media screen and (max-width: 1440px) {
  .body-o-evento #o-evento .for-space {
    margin-left: 190px;
  }
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .for-space {
    margin-left: 0;
  }
}

.body-o-evento #o-evento .mao-wrapper {
  position: absolute;
  top: 6rem;
  right: 0;
}

@media (max-width: 1800px) {
  .body-o-evento #o-evento .mao-wrapper {
    display: none;
  }
}

.body-o-evento #o-evento .container-o-evento {
  max-width: calc(1300px + 3rem);
  margin: 0 auto;
}

.body-o-evento #o-evento .header-wrapper {
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 4.5rem;
  padding-top: 6rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #o-evento .header-wrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .header-wrapper {
    margin-top: -9px;
    padding-top: 0;
  }
}

.body-o-evento #o-evento .header-wrapper .info-evento {
  letter-spacing: -.02em;
  color: #36363b;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
}

.body-o-evento #o-evento .header-wrapper .info-evento p {
  text-align: justify;
  margin-bottom: 1rem;
}

.body-o-evento #o-evento .info-list-grid {
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding-bottom: 8.75rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #o-evento .info-list-grid {
    grid-template-columns: 1fr;
  }
}

.body-o-evento #o-evento .info-list-grid .col-1 {
  max-width: 512px;
  margin-right: 3rem;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #o-evento .info-list-grid .col-1 {
    margin-right: 0;
    max-width: unset !important;
  }
}

@media screen and (max-width: 768px) {
  .body-o-evento #o-evento .info-list-grid .col-1 {
    max-width: unset !important;
  }
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .info-list-grid .col-1 {
    margin-right: 0;
  }
}

.body-o-evento #o-evento .info-list-grid .col-1 .title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.06;
}

.body-o-evento #o-evento .info-list-grid .col-1 .sub-title {
  text-align: justify;
  letter-spacing: -.02em;
  color: #36363b;
  font-size: 1.125rem;
  line-height: 1.4;
}

.body-o-evento #o-evento .info-list-grid .col-1 .info-list-wrapper {
  margin-top: 2rem;
}

.body-o-evento #o-evento .info-list-grid .col-1 .info-list-wrapper li {
  align-items: flex-start;
  margin-bottom: 1rem;
  display: flex;
}

.body-o-evento #o-evento .info-list-grid .col-1 .info-list-wrapper li svg {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.body-o-evento #o-evento .info-list-grid .col-1 .info-list-wrapper li p {
  letter-spacing: -.02em;
  color: #0f0f12;
  font-size: 1rem;
  line-height: 1.5;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #o-evento .info-list-grid .col-2 {
    grid-row-start: 1;
  }
}

.body-o-evento #o-evento .info-list-grid .col-2 .container-img {
  margin-left: auto;
  margin-right: 25px;
  padding-left: 70px;
  position: relative;
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .info-list-grid .col-2 .container-img {
    margin-right: 0;
    padding-left: 0;
  }
}

.body-o-evento #o-evento .info-list-grid .col-2 .container-img .gatinho-orna {
  position: absolute;
  left: -27px;
}

@media screen and (max-width: 1440px) {
  .body-o-evento #o-evento .info-list-grid .col-2 .container-img .gatinho-orna {
    transform-origin: top;
    transform: scale(.7);
  }
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .info-list-grid .col-2 .container-img .gatinho-orna {
    top: -32px;
    left: 5px;
    transform: rotate(30deg);
  }

  .body-o-evento #o-evento .info-list-grid .col-2 .container-img .gatinho-orna img {
    max-width: 47px;
  }
}

.body-o-evento #o-evento .info-list-grid .col-2 .container-img .orna-chaves {
  z-index: 3;
  position: absolute;
  bottom: 4.375rem;
  right: -26px;
}

@media screen and (max-width: 1440px) {
  .body-o-evento #o-evento .info-list-grid .col-2 .container-img .orna-chaves {
    transform-origin: center;
    transform: scale(.7);
  }
}

@media screen and (max-width: 600px) {
  .body-o-evento #o-evento .info-list-grid .col-2 .container-img .orna-chaves {
    transform-origin: 100% 100%;
    bottom: 1rem;
    right: 0;
    transform: scale(.6);
  }
}

.body-o-evento #o-evento .info-list-grid .col-2 .img-wrapper {
  z-index: 2;
  background-image: url("linear-o-evento-img.93104d5e.webp");
  background-repeat: no-repeat;
  background-size: calc(100% - 55px);
  padding: 1rem 0 1rem 1rem;
  position: relative;
}

.body-o-evento #o-evento .info-list-grid .col-2 .img-wrapper img {
  width: 100%;
}

.body-o-evento #o-evento .info-list-grid .sub-title-2 {
  text-align: justify;
  letter-spacing: -.02em;
  color: #36363b;
  grid-column: span 2;
  font-size: 1.125rem;
  line-height: 1.4;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #o-evento .info-list-grid .sub-title-2 {
    grid-column: 1;
  }
}

.body-o-evento #numeros {
  background: #fef3e9;
  border-top: 1px solid #0f0f1266;
  border-bottom: 1px solid #0f0f1266;
}

.body-o-evento #numeros .swiper-wrapper {
  transition-timing-function: linear;
}

.body-o-evento #numeros .swiper-slide {
  max-width: max-content;
}

.body-o-evento #numeros .numero-item {
  border-right: 1px solid #0f0f1266;
  align-items: center;
  padding: 2.5rem;
  display: flex;
}

@media screen and (max-width: 768px) {
  .body-o-evento #numeros .numero-item {
    padding: 2rem 1.5rem;
  }
}

.body-o-evento #numeros .numero-item .badge-cor {
  letter-spacing: -.02em;
  text-transform: uppercase;
  border-radius: 36px;
  padding: 4px 2rem;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.14;
}

@media screen and (max-width: 768px) {
  .body-o-evento #numeros .numero-item .badge-cor {
    font-size: 2rem;
  }
}

.body-o-evento #numeros .numero-item .badge-cor-1 {
  background-color: var(--bienal-rosa);
  color: #fff;
}

.body-o-evento #numeros .numero-item .badge-cor-2 {
  background-color: var(--bienal-amarelo);
  color: #000;
}

.body-o-evento #numeros .numero-item .badge-cor-3 {
  color: #000;
  background-color: #56c5d0;
}

.body-o-evento #numeros .numero-item .badge-cor-4 {
  color: #fff;
  background-color: #2e3192;
}

.body-o-evento #numeros .numero-item .badge-cor-5 {
  color: #fff;
  background-color: #f58220;
}

.body-o-evento #numeros .numero-item p {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0c1428;
  margin-left: 1rem;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.06;
}

@media screen and (max-width: 768px) {
  .body-o-evento #numeros .numero-item p {
    font-size: 1.5rem;
  }
}

.body-o-evento #cta-programacao {
  padding: 10rem 0;
}

@media screen and (max-width: 768px) {
  .body-o-evento #cta-programacao {
    padding: 4rem 0;
  }
}

.body-o-evento #cta-programacao .container {
  max-width: calc(1328px + 3rem);
  position: relative;
}

.body-o-evento #cta-programacao .flor-dali {
  position: absolute;
  top: -10px;
  left: -80px;
}

@media screen and (max-width: 768px) {
  .body-o-evento #cta-programacao .flor-dali {
    display: none;
  }
}

.body-o-evento #cta-programacao .flor-dali img {
  max-width: 68px;
}

.body-o-evento #cta-programacao .title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 3.5rem;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1.14;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #cta-programacao .title {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .body-o-evento #cta-programacao .title {
    font-size: 20px;
  }
}

.body-o-evento #cta-programacao .buttons-cta {
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  display: grid;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #cta-programacao .buttons-cta {
    grid-template-columns: 1fr;
  }
}

.body-o-evento #cta-programacao .buttons-cta .gatinho-orna {
  position: absolute;
  top: -65px;
  right: 0;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #cta-programacao .buttons-cta .gatinho-orna {
    display: none;
  }
}

.body-o-evento #cta-programacao .buttons-cta .gatinho-orna img {
  max-width: 73px;
}

.body-o-evento #cta-programacao .buttons-cta .button-wrapper {
  z-index: 2;
  position: relative;
}

.body-o-evento #cta-programacao .buttons-cta .button-wrapper .button {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  background: #fbfce9;
  border: 1px solid #0c1428;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.33;
  display: flex;
}

@media screen and (max-width: 600px) {
  .body-o-evento #cta-programacao .buttons-cta .button-wrapper .button {
    font-size: 16px;
  }
}

.body-o-evento #cta-programacao .buttons-cta .button-wrapper .button .icon-wrapper {
  background-color: var(--bienal-rosa);
  border-radius: 50%;
  place-content: center;
  width: 3.5rem;
  height: 3.5rem;
  display: grid;
}

@media screen and (max-width: 600px) {
  .body-o-evento #cta-programacao .buttons-cta .button-wrapper .button .icon-wrapper {
    width: 32px;
    height: 32px;
  }
}

.body-o-evento #cta-programacao .buttons-cta .button-wrapper .button .icon-wrapper svg {
  filter: brightness(0) invert();
  width: 24px;
  height: 24px;
}

.body-o-evento #faq {
  color: #fff;
  margin-bottom: 3.25rem;
  position: relative;
}

.body-o-evento #faq .grid-faq {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .grid-faq {
    display: block;
  }
}

.body-o-evento #faq .col-1 {
  z-index: 2;
  min-height: 710px;
  padding-top: 120px;
  padding-left: 6vw;
  position: relative;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-1 {
    min-height: unset;
    padding-top: 64px;
    padding-bottom: 150px;
    padding-right: 1.5rem;
  }
}

.body-o-evento #faq .col-1 .img-wrapper-mulher {
  width: 100%;
  max-width: 50vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-1 .img-wrapper-mulher {
    max-width: 100%;
  }
}

.body-o-evento #faq .col-1 .img-wrapper-mulher img {
  object-fit: cover;
  object-position: bottom right;
  width: 100%;
  max-width: 50vw;
  height: 100%;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-1 .img-wrapper-mulher img {
    object-position: bottom right;
    max-width: 100%;
  }
}

.body-o-evento #faq .col-1 .section-title {
  max-width: 511px;
  margin-right: 4.375rem;
  position: relative;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-1 .section-title {
    margin-right: 0;
  }
}

.body-o-evento #faq .col-1 .section-title .alien-orna {
  position: absolute;
  top: 0;
  right: -75px;
}

@media screen and (max-width: 1024px) {
  .body-o-evento #faq .col-1 .section-title .alien-orna {
    display: none;
  }
}

.body-o-evento #faq .col-1 .section-title h2 {
  color: #fff;
  max-width: 15ch;
}

.body-o-evento #faq .col-1 .section-title .section-description {
  color: #fff;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.body-o-evento #faq .col-1 .section-title a {
  color: #fff;
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.33;
}

@media screen and (max-width: 768px) {
  .body-o-evento #faq .col-1 .section-title a {
    font-size: 1.25rem;
  }
}

.body-o-evento #faq .col-1 .section-title .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #d6df21;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

.body-o-evento #faq .col-1 .section-title .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body-o-evento #faq .col-1 .section-title .button:active, .body-o-evento #faq .col-1 .section-title .button:hover {
  transform: scale(1.05);
}

.body-o-evento #faq .col-1 .section-title .button svg, .body-o-evento #faq .col-1 .section-title .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

.body-o-evento #faq .col-1 .section-title .button svg {
  width: 24px;
  height: 24px;
  margin-left: 5px;
  margin-right: 0;
}

.body-o-evento #faq .col-2 {
  background-color: var(--bienal-rosa);
  padding-top: 120px;
  padding-left: 7.5rem;
  padding-right: 1.5rem;
  position: relative;
}

@media (min-width: 1800px) {
  .body-o-evento #faq .col-2 {
    padding-right: 7.5rem;
  }
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-2 {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 6vw;
  }
}

.body-o-evento #faq .col-2 .robo-wrapper-faq {
  position: absolute;
  top: 0;
  left: -72px;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-2 .robo-wrapper-faq {
    z-index: 3;
    max-width: 105px;
    top: -55px;
    left: -15px;
  }
}

.body-o-evento #faq .col-2 .robo-wrapper-faq img {
  transform: rotate(-1deg);
}

.body-o-evento #faq .col-2 .content-faq {
  max-width: 697px;
}

.body-o-evento #faq .col-2 .section-title {
  margin-bottom: 6.25rem;
  position: relative;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .body-o-evento #faq .col-2 .section-title {
    margin-bottom: 2rem;
  }
}

.body-o-evento #faq .col-2 .section-title:after {
  content: "";
  background-color: #fff3;
  width: 100%;
  height: 1px;
  display: block;
  position: absolute;
  top: 50%;
  left: 129px;
  right: 0;
}

.body-o-evento #faq .col-2 .section-title .badge-wrapper {
  margin-bottom: 0;
}

.body-o-evento #faq .col-2 .item-faq {
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
  overflow: hidden;
}

.body-o-evento #faq .col-2 .item-faq:after {
  content: "";
  background-color: var(--bienal-amarelo);
  width: 0;
  height: 4px;
  transition: width .2s ease-out .1s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.body-o-evento #faq .col-2 .item-faq .header {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.body-o-evento #faq .col-2 .item-faq .header .titulo {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
}

.body-o-evento #faq .col-2 .item-faq .header .icon-wrapper {
  background-color: var(--bienal-amarelo);
  border-radius: 50%;
  flex-shrink: 0;
  place-content: center;
  width: 40px;
  height: 40px;
  display: grid;
}

.body-o-evento #faq .col-2 .item-faq .header .icon-wrapper svg {
  width: 1.5rem;
  height: 1.5rem;
}

.body-o-evento #faq .col-2 .item-faq .faq-content {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  -o-transition: max-height .3s ease-in-out;
  max-height: 0;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.44;
  transition: max-height .3s ease-in-out;
  overflow: hidden;
}

.body-o-evento #faq .col-2 .item-faq.active .faq-content {
  max-height: 300px;
  padding-top: 11px;
}

.body-o-evento #faq .col-2 .item-faq.active .icon-wrapper {
  transition: all .2s;
  transform: rotate(180deg);
}

.body-o-evento #faq .col-2 .item-faq.active:after {
  width: 100%;
}

.body-o-evento #banner-patrocinio .balao-wrapper {
  left: 1.5rem;
  right: unset;
}

.body_quemsomos #quem-somos {
  position: relative;
}

@media screen and (max-width: 768px) {
  .body_quemsomos #quem-somos {
    padding-top: 2rem;
  }
}

.body_quemsomos #quem-somos .container-quem-somos {
  max-width: calc(1300px + 3rem);
  margin: 0 auto;
}

.body_quemsomos #quem-somos .mao-wrapper {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

@media (max-width: 1700px) {
  .body_quemsomos #quem-somos .mao-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .body_quemsomos #quem-somos .mao-wrapper {
    max-width: 140px;
    display: block;
    top: 140px;
  }
}

@media screen and (max-width: 768px) {
  .body_quemsomos #quem-somos .mao-wrapper {
    top: 100px;
  }
}

.body_quemsomos #quem-somos .header-wrapper {
  grid-template-columns: .6fr 2fr;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 5.75rem;
  padding-top: 6rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_quemsomos #quem-somos .header-wrapper {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 600px) {
  .body_quemsomos #quem-somos .header-wrapper {
    margin-top: -9px;
    padding-top: 3rem;
  }
}

.body_quemsomos #quem-somos .header-wrapper .alien-balao {
  max-width: 230px;
  margin-top: 1.875rem;
  transform: rotate(12.93deg);
}

@media screen and (max-width: 1024px) {
  .body_quemsomos #quem-somos .header-wrapper .alien-balao {
    display: none;
  }
}

.body_quemsomos #quem-somos .header-wrapper .balao-wrapper {
  max-width: 70px;
  display: block;
  position: absolute;
  top: 50%;
  left: -163px;
  transform: translateY(-50%);
}

@media (max-width: 1800px) {
  .body_quemsomos #quem-somos .header-wrapper .balao-wrapper {
    display: none;
  }
}

.body_quemsomos #quem-somos .header-wrapper .info-evento {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.body_quemsomos #quem-somos .header-wrapper .info-evento .chaves-detalhe-left {
  flex-shrink: 0;
  align-self: stretch;
}

.body_quemsomos #quem-somos .header-wrapper .info-evento .chaves-detalhe-left img {
  object-fit: fill;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .body_quemsomos #quem-somos .header-wrapper .info-evento .chaves-detalhe-left {
    display: none;
  }
}

.body_quemsomos #quem-somos .header-wrapper .info-evento .chaves-detalhe-right {
  flex-shrink: 0;
  align-self: stretch;
  position: relative;
  top: 4px;
  transform: rotate(-180deg);
}

.body_quemsomos #quem-somos .header-wrapper .info-evento .chaves-detalhe-right img {
  object-fit: fill;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .body_quemsomos #quem-somos .header-wrapper .info-evento .chaves-detalhe-right {
    display: none;
  }
}

.body_quemsomos #quem-somos .header-wrapper .info-evento .content-for-sobre {
  letter-spacing: -.02em;
  color: #36363b;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4;
}

.body_quemsomos #quem-somos .header-wrapper .info-evento .content-for-sobre p {
  margin-bottom: 1rem;
}

.body_quemsomos #fotos .swiper.swiper-fotos .swiper-slide {
  width: max-content;
  height: 392px;
}

@media screen and (max-width: 768px) {
  .body_quemsomos #fotos .swiper.swiper-fotos .swiper-slide {
    height: 320px;
  }
}

.body_quemsomos #fotos .swiper.swiper-fotos .swiper-slide .foto-item {
  height: 100%;
}

.body_quemsomos #fotos .swiper.swiper-fotos .swiper-slide .foto-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body_quemsomos #nossa-historia {
  padding: 10rem 0 5.5rem;
}

@media screen and (max-width: 768px) {
  .body_quemsomos #nossa-historia {
    padding: 4rem 0 2.5rem;
  }
}

.body_quemsomos #nossa-historia .header-wrapper {
  background: url("footer-linear.f1b78cf0.webp") 0 0 / cover no-repeat;
  margin-bottom: 4rem;
  padding: 1.5rem 2rem;
  position: relative;
}

@media screen and (max-width: 600px) {
  .body_quemsomos #nossa-historia .header-wrapper {
    padding: 16px;
  }
}

.body_quemsomos #nossa-historia .header-wrapper h2 {
  color: #fff;
  letter-spacing: -.02em;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 1.33;
}

@media screen and (max-width: 600px) {
  .body_quemsomos #nossa-historia .header-wrapper h2 {
    max-width: 21ch;
  }
}

.body_quemsomos #nossa-historia .header-wrapper .ornamente-peixe {
  position: absolute;
  top: calc(50% - 20px);
  right: 10px;
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .body_quemsomos #nossa-historia .header-wrapper .ornamente-peixe {
    max-width: 120px;
    display: none;
    top: calc(50% - 10px);
  }
}

.body_quemsomos #nossa-historia .swiper-controls-header {
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  display: flex;
}

@media screen and (max-width: 600px) {
  .body_quemsomos #nossa-historia .swiper-controls-header {
    margin-top: 1rem;
  }
}

.body_quemsomos #nossa-historia .swiper-controls-header .pagination {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.body_quemsomos #nossa-historia .swiper-controls-header .buttons {
  align-items: center;
  gap: 4px;
  display: flex;
}

.body_quemsomos #nossa-historia .swiper-controls-header .buttons .button {
  background-color: var(--bienal-amarelo);
  border-radius: 50%;
  place-content: center;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
}

.body_quemsomos #nossa-historia .swiper-controls-header .buttons .button svg path {
  stroke: #000;
}

.body_quemsomos #nossa-historia .swiper-controls-header .buttons .button.swiper-button-disabled {
  background-color: #e3e3e6;
}

.body_quemsomos #nossa-historia .swiper-controls-header .buttons .button.swiper-button-disabled svg path {
  stroke: #000;
}

.body_quemsomos #nossa-historia .nossa-historia-wrapper .slide-item-wrapper {
  padding-top: 86.72%;
  position: relative;
  overflow: hidden;
}

.body_quemsomos #nossa-historia .nossa-historia-wrapper .slide-item-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

@media (min-width: 1024px) {
  .body_programacao {
    overflow-x: initial;
  }

  .body_programacao main {
    overflow: initial;
  }
}

.body_programacao #programacao .grid-programacao {
  grid-template-columns: 23.4375rem 1fr;
  align-items: flex-start;
  gap: 2rem;
  display: grid;
}

@media screen and (max-width: 1440px) {
  .body_programacao #programacao .grid-programacao {
    grid-template-columns: 19rem 1fr;
  }
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .grid-programacao {
    grid-template-columns: 1fr;
  }
}

.body_programacao #programacao .div-to-sticky {
  position: sticky;
  top: 140px;
}

.body_programacao #programacao .div-to-sticky .img-menina {
  margin-top: 100px;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .div-to-sticky .img-menina {
    display: none;
  }
}

.body_programacao #programacao .filtrar-por-data {
  cursor: pointer;
  background: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  margin-bottom: 1rem;
  padding: 12px 16px;
  display: none;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .filtrar-por-data {
    display: block;
  }
}

.body_programacao #programacao .filtrar-por-data .flex-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body_programacao #programacao .filtrar-por-data p {
  letter-spacing: -.02em;
  color: #0f0f1266;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}

.body_programacao #programacao .filtrar-por-data svg {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.body_programacao #programacao .info-download-wrapper {
  margin-top: 2rem;
}

.body_programacao #programacao .info-download-wrapper p {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}

.body_programacao #programacao .info-download-wrapper .button {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #3cc5b1;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
}

.body_programacao #programacao .info-download-wrapper .button span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_programacao #programacao .info-download-wrapper .button:active, .body_programacao #programacao .info-download-wrapper .button:hover {
  transform: scale(1.05);
}

.body_programacao #programacao .info-download-wrapper .button svg, .body_programacao #programacao .info-download-wrapper .button img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

.body_programacao #programacao .info-download-wrapper .button svg {
  width: 24px;
  height: 24px;
}

.body_programacao #programacao .info-download-wrapper .button span {
  font-size: 12px;
}

.body_programacao #programacao .info-download-wrapper .button svg {
  width: 18px;
  height: 18px;
  margin-left: 0;
  margin-right: 8px;
}

.body_programacao #programacao .sidebar {
  background-color: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  padding: 1rem;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .sidebar {
    border-width: 0;
    max-height: 0;
    padding: 0;
    transition: all .2s ease-in;
    overflow: hidden;
  }

  .body_programacao #programacao .sidebar.open {
    border-width: 1px;
    max-height: 1000px;
  }
}

.body_programacao #programacao .sidebar .dias-wrapper li {
  cursor: pointer;
  padding: 12px;
}

.body_programacao #programacao .sidebar .dias-wrapper li .dia-semana {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #7b7c7f;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
}

.body_programacao #programacao .sidebar .dias-wrapper li .dia-data {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0d4da1;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.body_programacao #programacao .sidebar .dias-wrapper li.active {
  color: #fff;
  background-color: #0d4da1;
  border: 4px solid #3cc5b1;
  padding: 12px;
}

.body_programacao #programacao .sidebar .dias-wrapper li.active .dia-semana {
  color: #ffffffb3;
  font-weight: 700;
}

.body_programacao #programacao .sidebar .dias-wrapper li.active .dia-data {
  color: #fff;
}

.body_programacao #programacao .programacoes {
  width: 100%;
  max-width: 1050px;
  margin-left: auto;
  display: grid;
}

.body_programacao #programacao .programacao {
  margin-bottom: 2rem;
}

.body_programacao #programacao .horario-wrapper {
  background: linear-gradient(133deg, #e7eef6c4 0%, #e7eef6d9 51.3%, #8fd9d33d 99.99%, #3cc5b10f 100%), #fff;
  margin-bottom: 2rem;
  padding: 1.5rem;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .horario-wrapper {
    padding: 1rem;
  }
}

.body_programacao #programacao .horario-wrapper p {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0d4da1;
  font-size: 24px;
  font-weight: 600;
  line-height: 132%;
}

.body_programacao #programacao .dia-atracoes-wrapper {
  color: #1b1d21;
  background-color: #fff;
  border: 1px solid #1b1d211a;
  border-radius: 3px;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 3px;
  padding: 1.5rem;
  display: grid;
}

.body_programacao #programacao .dia-atracoes-wrapper .mesa-redonda {
  color: #3b9c8e;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.body_programacao #programacao .dia-atracoes-wrapper .jornada-wrapper {
  border-bottom: 1px solid #1b1d211a;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
}

.body_programacao #programacao .dia-atracoes-wrapper .jornada-title {
  color: #1b1d21;
  letter-spacing: -.26px;
  margin-bottom: 16px;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
}

.body_programacao #programacao .dia-atracoes-wrapper .content-for-p p:not(.continuar-lendo) {
  letter-spacing: -.02em;
  color: #1b1d21;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.44;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .dia-atracoes-wrapper .content-for-p p:not(.continuar-lendo) {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.body_programacao #programacao .dia-atracoes-wrapper .content-for-p.open p:not(.continuar-lendo) {
  display: block;
}

.body_programacao #programacao .dia-atracoes-wrapper .debatedores-grid .debate-text {
  color: #000;
  letter-spacing: -.16px;
  margin-bottom: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.body_programacao #programacao .dia-atracoes-wrapper .debatedores-grid .debatedores-wrapper {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 2rem;
  display: flex;
}

.body_programacao #programacao .line-grey {
  align-self: stretch;
  width: 1px;
  position: relative;
}

.body_programacao #programacao .line-grey:after {
  content: "";
  background-color: #d7dadb;
  height: 68%;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.body_programacao #programacao .infos-sala-wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  display: flex;
}

@media screen and (max-width: 1024px) {
  .body_programacao #programacao .infos-sala-wrapper .line-grey {
    display: none;
  }
}

.body_programacao #programacao .mod-text {
  color: #000;
  letter-spacing: -.16px;
  margin-bottom: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.body_programacao #programacao .tags {
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 1rem;
  display: flex;
}

.body_programacao #programacao .tags span {
  letter-spacing: .96px;
  text-transform: uppercase;
  border: 1px solid #1b1d21;
  border-radius: 12px;
  padding: 6px 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  display: inline-block;
}

.body_programacao #programacao .local-wrapper {
  flex-wrap: wrap;
  align-items: center;
  gap: 10px 2rem;
  display: flex;
}

.body_programacao #programacao .local-wrapper .local {
  align-items: center;
  display: flex;
}

.body_programacao #programacao .local-wrapper .local p {
  color: #1b1d21;
  letter-spacing: -.16px;
  font-family: Causten;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.body_programacao #programacao .local-wrapper .local p span {
  color: #000;
  font-weight: 600;
}

.body_programacao #programacao .local-wrapper .local svg {
  filter: brightness(0) saturate() invert(20%) sepia(80%) saturate(1721%) hue-rotate(199deg) brightness(99%) contrast(99%);
  width: 24px;
  height: 24px;
}

.body_programacao #programacao .debate-item {
  align-items: center;
  gap: 8px;
  display: flex;
}

.body_programacao #programacao .debate-item .debate-img {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
}

.body_programacao #programacao .debate-item .debate-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body_programacao #programacao .debate-item .debate-infos .debate-nome {
  color: #1b1d21;
  letter-spacing: -.14px;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
}

.body_programacao #programacao .debate-item .debate-infos .debate-info {
  color: #3b4042;
  letter-spacing: -.14px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

@media (min-width: 1024px) {
  .body_infos, .body_infos main {
    overflow: unset;
  }

  .body_infos .side-bar {
    position: sticky;
    top: 140px;
  }
}

.body_infos #base-banner .detail-bottom {
  background-color: #a3238e;
}

.body_infos #informacoes {
  padding: 5.375rem 0;
  position: relative;
}

.body_infos #informacoes .all-ornas-wrapper {
  flex-direction: column;
  gap: 12.5rem;
  display: flex;
  position: absolute;
  top: 490px;
}

@media screen and (max-width: 1024px) {
  .body_infos #informacoes .all-ornas-wrapper {
    display: none;
  }
}

.body_infos #informacoes .all-ornas-wrapper .img-orna-1 {
  max-width: 204px;
}

@media (max-width: 1700px) {
  .body_infos #informacoes .all-ornas-wrapper img {
    transform-origin: 0;
    transform: scale(.8);
  }
}

.body_infos #informacoes .hand-orna-right {
  flex-direction: row-reverse;
  display: flex;
  position: absolute;
  right: 0;
}

@media (max-width: 1700px) {
  .body_infos #informacoes .hand-orna-right {
    transform-origin: 100%;
    right: -30px;
    transform: scale3d(.78, .78, .78);
  }
}

@media (max-width: 1440px) {
  .body_infos #informacoes .hand-orna-right {
    display: none;
  }
}

.body_infos #informacoes .hand-orna-right .hand-orna-img {
  padding-bottom: 3rem;
}

.body_infos #informacoes .hand-orna-right .alien-orna-hand {
  align-self: flex-end;
  max-width: 108px;
}

.body_infos #informacoes .grid-wrapper {
  grid-template-columns: 374px 1fr;
  align-items: flex-start;
  gap: 10rem;
  display: grid;
}

@media (max-width: 1700px) {
  .body_infos #informacoes .grid-wrapper {
    gap: 6rem;
  }
}

@media screen and (max-width: 1440px) {
  .body_infos #informacoes .grid-wrapper {
    grid-template-columns: 274px 1fr;
    gap: 4rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_infos #informacoes .grid-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_infos #informacoes .side-bar {
  background: #fff;
  border: 1px solid #0f0f120d;
  padding: 1rem;
}

@media screen and (max-width: 1024px) {
  .body_infos #informacoes .side-bar {
    display: none;
  }
}

.body_infos #informacoes .side-bar .destaque {
  margin-bottom: 1.5rem;
}

.body_infos #informacoes .side-bar h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f1280;
  cursor: pointer;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
}

.body_infos #informacoes .side-bar h2.active {
  color: #0f0f12;
}

.body_infos #informacoes .side-bar h2:last-child {
  margin-bottom: 0;
}

.body_infos #informacoes .editor {
  max-width: 784px;
}

.body_infos #informacoes .editor h2 {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  margin-bottom: 1rem;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 136%;
}

@media screen and (max-width: 768px) {
  .body_infos #informacoes .editor h2 {
    font-size: 1.5rem;
  }
}

.body_infos #informacoes .editor p {
  letter-spacing: -.02em;
  color: #36363b;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.44;
}

.body_infos #informacoes .editor li {
  letter-spacing: -.02em;
  color: #36363b;
  margin-bottom: .75rem;
  margin-left: 2rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 144%;
  list-style: disc;
}

.body_infos #informacoes .block-item {
  margin-bottom: 2.5rem;
  scroll-margin-top: 140px;
}

.body_infos #informacoes p.destaque {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 144%;
}

.body_infos #informacoes .ingresso-wrapper {
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_infos #informacoes .ingresso-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_infos #informacoes .ingresso-item {
  background-image: url("price-bg.7ec78014.png");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px 0 0 3px;
  flex-direction: column;
  justify-content: center;
  max-width: 380px;
  min-height: 90px;
  padding: 12px 80px 12px 12px;
  display: flex;
}

.body_infos #informacoes .ingresso-item .info {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.body_infos #informacoes .ingresso-item .preco {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #d6df21;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 133%;
}

.body_infos #informacoes .table-container {
  border-radius: 3px;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.body_infos #informacoes .table-container .table-head {
  background-color: #a3238e;
  padding: .875rem 1.5rem;
}

.body_infos #informacoes .table-container .table-head .head-title {
  color: #fff;
  letter-spacing: -.02em;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 144%;
}

.body_infos #informacoes .table-container .table-body {
  background-color: #fff;
  border: 1px solid #0f0f120d;
  border-bottom: none;
}

.body_infos #informacoes .table-container .table-body .row {
  border-bottom: 1px solid #0f0f120d;
  justify-content: space-between;
  padding: 1rem 24px;
  display: flex;
}

.body_infos #informacoes .table-container .table-body .row p {
  margin-bottom: 0;
}

.body_infos #informacoes .table-container .table-body .row .label {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
}

.body_infos #informacoes .table-container .table-body .row .preco {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  font-size: 1rem;
  font-weight: 700;
  line-height: 150%;
}

.body_contato #contato-rc .section-title {
  margin-bottom: 3.5rem;
}

.body_contato #contato-rc .section-title h2 {
  font-size: 5rem;
}

@media screen and (max-width: 768px) {
  .body_contato #contato-rc .section-title h2 {
    font-size: 2.5rem;
  }
}

.body_contato #contato-rc .section-title .description {
  letter-spacing: -.02em;
  color: #3b4042;
  max-width: 54ch;
  margin-top: 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.body_contato #contato-rc .info-contato-container {
  margin-top: 2rem;
}

.body_contato #contato-rc .info-contato-container .block-wrapper-contato {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  margin-bottom: 1.125rem;
  display: flex;
}

.body_contato #contato-rc .info-contato-container .block-wrapper-contato .block-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  background: linear-gradient(89deg, #0d4da1 0%, #42b6a7 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
}

.body_contato #contato-rc .info-contato-container .block-wrapper-contato .block-info {
  letter-spacing: -.02em;
  color: #0d4da1;
  border: 1px solid #1b1d210d;
  border-radius: 3px;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.3;
  transition: all .2s ease-in;
  display: flex;
}

.body_contato #contato-rc .info-contato-container .block-wrapper-contato .block-info svg {
  color: #1b1d21;
  opacity: .4;
  width: 24px;
  height: 24px;
}

.body_contato #contato-rc .info-contato-container .block-wrapper-contato .block-info:hover {
  border-color: #3cc5b1;
  transform: scale(1.05);
}

.body_contato #contato-rc .grid-wrapper {
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_contato #contato-rc .grid-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_contato #contato-rc .col-1 {
  max-width: 650px;
  margin-right: 2rem;
  padding: 120px 0;
}

@media screen and (max-width: 1024px) {
  .body_contato #contato-rc .col-1 {
    padding: 5rem 0;
  }
}

.body_contato #contato-rc .col-2 {
  flex-direction: column;
  justify-content: center;
  padding: 120px 0;
  display: flex;
  position: relative;
}

@media screen and (max-width: 600px) {
  .body_contato #contato-rc .col-2 {
    padding: 1.5rem 0;
  }
}

.body_contato #contato-rc .col-2:before {
  content: "";
  background-image: url("bg-contato-new.2c20d3b8.webp");
  background-position: 25%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 40vw;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 185px;
}

@media screen and (max-width: 1024px) {
  .body_contato #contato-rc .col-2:before {
    width: 120vw;
    left: -20vw;
  }
}

.body_contato #contato-rc .col-2 .form-wrapper {
  z-index: 2;
  background: #fff;
  border: 1px solid #0f0f121a;
  border-radius: 6px;
  width: 100%;
  max-width: 445px;
  padding: 3rem 2.5rem;
  position: relative;
  box-shadow: 0 8px 32px #d2d8df14;
}

@media screen and (max-width: 600px) {
  .body_contato #contato-rc .col-2 .form-wrapper {
    padding: 2.5rem 2rem;
  }
}

.body_contato #contato-rc .col-2 .form-wrapper .form-title {
  letter-spacing: -.02em;
  color: #0d4da1;
  margin-bottom: 2.5rem;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.33;
}

.body_contato #programacao-rolling {
  background-color: #f7f8f9;
  border-top: 1px solid #1b1d211a;
  border-bottom: 1px solid #1b1d211a;
}

.body_contato #programacao-rolling .swiper-infinite-programacao .swiper-wrapper {
  transition-timing-function: linear;
}

.body_contato #programacao-rolling .swiper-infinite-programacao .swiper-slide {
  width: max-content;
  min-width: max-content;
  max-width: 100%;
}

.body_contato #programacao-rolling .item-infinite {
  align-items: center;
  min-width: max-content;
  display: flex;
}

.body_contato #programacao-rolling .item-infinite h2 {
  letter-spacing: -.03em;
  text-transform: uppercase;
  color: #1b1d211a;
  min-width: max-content;
  font-size: 5rem;
  font-weight: 700;
  line-height: 1.2;
}

@media screen and (max-width: 1440px) {
  .body_contato #programacao-rolling .item-infinite h2 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_contato #programacao-rolling .item-infinite h2 {
    font-size: 3rem;
  }
}

.body_contato #programacao-rolling .item-infinite .dot-right {
  background-color: #e3e3e5;
  border-radius: 50%;
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  min-height: 1rem;
  margin-left: 64px;
}

@media screen and (max-width: 1024px) {
  .body_contato #programacao-rolling .item-infinite .dot-right {
    margin-left: 20px;
  }
}

.body_contato .ornamentos-faq {
  position: relative;
  overflow: hidden;
}

.body_contato .ornamentos-faq:after {
  content: "";
  filter: blur(112px);
  z-index: -1;
  background: radial-gradient(68.5% 68.5% at 42% 39.06%, #3cc5b133 0%, #3cc5b100 100%);
  border-radius: 50%;
  width: 814px;
  height: 814px;
  display: block;
  position: absolute;
  top: -10vh;
  left: -20vw;
}

@media screen and (max-width: 1200px) {
  .body_contato .ornamentos-faq:after {
    display: none;
  }
}

.body_contato .ornamentos-faq:before {
  content: "";
  filter: blur(112px);
  z-index: -1;
  background: radial-gradient(68.5% 68.5% at 42% 39.06%, #3cc5b133 0%, #3cc5b100 100%);
  border-radius: 50%;
  width: 814px;
  height: 814px;
  display: block;
  position: absolute;
  bottom: 0;
  right: -20vw;
}

@media screen and (max-width: 1200px) {
  .body_contato .ornamentos-faq:before {
    display: none;
  }
}

.body_contato #faq-rc {
  max-width: 1056px;
  margin: 0 auto;
  padding: 6rem 0;
  position: relative;
}

.body_credenciamento #banner-credenciamento {
  color: #fff;
  background: #a3238e;
  padding: 6rem 0 0;
}

.body_credenciamento #banner-credenciamento .linha-amarela {
  background-color: #d6df21;
  height: 16px;
}

.body_credenciamento #banner-credenciamento .container {
  position: relative;
}

.body_credenciamento #banner-credenciamento .grid-wrappper {
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 7rem;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_credenciamento #banner-credenciamento .grid-wrappper {
    grid-template-columns: 1fr;
  }
}

.body_credenciamento #banner-credenciamento .section-title h2 {
  color: #fff;
  max-width: 15ch;
}

.body_credenciamento #banner-credenciamento .button-wrapper a {
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;
  background-color: #d6df21;
  border-radius: 3px;
  align-items: center;
  max-width: max-content;
  padding: 12px;
  transition: background-color .2s ease-in-out, transform .2s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: 2.5px 2.5px #ef7d00;
}

.body_credenciamento #banner-credenciamento .button-wrapper a span {
  color: #000;
  letter-spacing: -.02em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  font-smoothing: antialiased;
  backface-visibility: hidden;
  will-change: transform;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  transition: transform .2s;
}

.body_credenciamento #banner-credenciamento .button-wrapper a:active, .body_credenciamento #banner-credenciamento .button-wrapper a:hover {
  transform: scale(1.05);
}

.body_credenciamento #banner-credenciamento .button-wrapper a svg, .body_credenciamento #banner-credenciamento .button-wrapper a img {
  flex-shrink: 0;
  max-width: 30px;
  margin-left: 8px;
}

.body_credenciamento #banner-credenciamento .button-wrapper a svg {
  width: 24px;
  height: 24px;
}

.body_credenciamento #banner-credenciamento .col-2 {
  max-width: 514px;
}

.body_credenciamento #banner-credenciamento .col-2 .text-description {
  letter-spacing: -.02em;
  color: #fff;
  margin-bottom: 2rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
}

.body_credenciamento #banner-credenciamento .orna-alien {
  max-width: 233px;
  position: absolute;
  top: -177px;
  right: -30px;
  transform: scaleX(-1);
}

@media screen and (max-width: 1440px) {
  .body_credenciamento #banner-credenciamento .orna-alien {
    max-width: 190px;
  }
}

@media screen and (max-width: 1024px) {
  .body_credenciamento #banner-credenciamento .orna-alien {
    top: -150px;
  }
}

.body_credenciamento #banner-credenciamento .main-img-wrapper {
  max-width: max-content;
  margin: 0 auto;
  padding-right: 63px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1024px) {
  .body_credenciamento #banner-credenciamento .main-img-wrapper {
    display: none;
  }
}

.body_credenciamento #banner-credenciamento .main-img-wrapper .orna-luneta {
  position: absolute;
  top: -77px;
  left: 7px;
}

@media screen and (max-width: 768px) {
  .body_credenciamento #banner-credenciamento .main-img-wrapper .orna-luneta {
    top: -2px;
  }
}

.body_credenciamento #banner-credenciamento .main-img-wrapper .main-img {
  margin: 0 auto;
}

.body_credenciamento .grid-cards-wrapper {
  z-index: 3;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-end;
  gap: 2rem;
  display: grid;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .body_credenciamento .grid-cards-wrapper {
    grid-template-columns: 1fr;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item {
  background-color: #a3238e;
  border: 1px solid #fff;
  padding: 1.5rem;
}

.body_credenciamento .grid-cards-wrapper .card-item .title {
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 2rem;
}

@media screen and (max-width: 1440px) {
  .body_credenciamento .grid-cards-wrapper .card-item .title {
    font-size: 1.5rem;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item .descricao {
  margin-bottom: 1rem;
}

.body_credenciamento .grid-cards-wrapper .card-item .descricao p {
  letter-spacing: -.44px;
  font-size: 1.375rem;
  line-height: 1.4;
}

@media screen and (max-width: 1440px) {
  .body_credenciamento .grid-cards-wrapper .card-item .descricao p {
    font-size: 1rem;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item .button-wrapper {
  transition: all .2s ease-in;
}

.body_credenciamento .grid-cards-wrapper .card-item .button-wrapper:hover {
  transform: scale(1.02);
}

.body_credenciamento .grid-cards-wrapper .card-item .button-wrapper .button {
  background-color: var(--bienal-amarelo);
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 13px 16px;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
}

@media screen and (max-width: 768px) {
  .body_credenciamento .grid-cards-wrapper .card-item .button-wrapper .button {
    justify-content: center;
    width: 100%;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item .button-wrapper svg {
  width: 24px;
  height: 24px;
  margin-left: 1.5rem;
}

.body_credenciamento .grid-cards-wrapper .card-item:first-child {
  background-color: #db761d;
  max-width: 425px;
  margin-left: 7px;
}

@media screen and (max-width: 1024px) {
  .body_credenciamento .grid-cards-wrapper .card-item:first-child {
    max-width: 490px;
    margin-left: 0;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item:nth-child(2) {
  max-width: 409px;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  .body_credenciamento .grid-cards-wrapper .card-item:nth-child(2) {
    max-width: 490px;
    margin-left: 0;
  }
}

.body_credenciamento .grid-cards-wrapper .card-item:nth-child(3) {
  background: #369aa3;
  max-width: 495px;
  margin-left: auto;
}

@media screen and (max-width: 1024px) {
  .body_credenciamento .grid-cards-wrapper .card-item:nth-child(3) {
    max-width: 490px;
    margin-left: 0;
  }
}

.body_espacos #categorias {
  background: #fff;
  border-bottom: 1px solid #0f0f121a;
}

.body_espacos #categorias .container {
  overflow: hidden;
}

.body_espacos #categorias .swiper-slide {
  flex: 25%;
  min-width: max-content;
}

.body_espacos #categorias .tab {
  cursor: pointer;
  text-align: center;
}

.body_espacos #categorias .tab span {
  border-radius: 20px;
  padding: .4375rem 1.25rem;
  transition: all .2s;
}

.body_espacos #categorias .tab.active span {
  color: #000;
  background: #d6df2133;
}

.body_espacos #categorias .tab:hover span {
  color: #000;
  background: #d6df2133;
  border-radius: 20px;
  padding: .4375rem 1.25rem;
}

.body_espacos #categorias .tab a {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f1266;
  padding: 1.1875rem 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
  display: block;
}

.body_espacos #todos-espacos {
  background-color: #f3f3f5;
}

.body_espacos #todos-espacos .espaco {
  display: none;
}

.body_espacos #todos-espacos .espaco .grid-espaco {
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 2rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_espacos #todos-espacos .espaco .grid-espaco {
    grid-template-columns: 1fr;
  }
}

.body_espacos #todos-espacos .espaco .col-1 {
  width: 100%;
  max-width: calc(528px + 4rem);
  margin: 0 auto;
  padding: 4rem 2rem;
}

@media screen and (max-width: 768px) {
  .body_espacos #todos-espacos .espaco .col-1 {
    padding: 3rem 1.5rem 0;
  }
}

.body_espacos #todos-espacos .espaco .col-1 .espaco-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 2rem;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 114%;
}

@media screen and (max-width: 1024px) {
  .body_espacos #todos-espacos .espaco .col-1 .espaco-title {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
  }
}

.body_espacos #todos-espacos .espaco .col-1 .content {
  letter-spacing: -.02em;
  color: #36363b;
  margin-bottom: 2rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 144%;
}

.body_espacos #todos-espacos .espaco .col-1 .content p {
  margin-bottom: 1rem;
}

.body_espacos #todos-espacos .espaco .col-1 .curadores, .body_espacos #todos-espacos .espaco .col-1 .curadores .swiper-curadores {
  width: 100%;
  display: grid;
}

.body_espacos #todos-espacos .espaco .col-1 .curadores .title-curador {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  margin-bottom: .75rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 144%;
}

.body_espacos #todos-espacos .espaco .col-1 .curadores .curador-item .img-wrapper {
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.body_espacos #todos-espacos .espaco .col-1 .curadores .curador-item .img-wrapper img {
  object-fit: cover;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_espacos #todos-espacos .espaco .col-1 .curadores .curador-item .curador-nome {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  margin-top: .5rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 150%;
}

.body_espacos #todos-espacos .espaco .col-2, .body_espacos #todos-espacos .espaco .col-2 .swiper-espaco {
  width: 100%;
  display: grid;
}

.body_espacos #todos-espacos .espaco .col-2 .swiper-espaco .swiper-wrapper {
  position: unset;
}

.body_espacos #todos-espacos .espaco .col-2 .swiper-espaco .swiper-slide {
  height: auto;
}

.body_espacos #todos-espacos .espaco .col-2 .swiper-controls-custom {
  background-color: #a3238e;
  align-items: center;
  padding: 1rem 1.5rem;
  display: flex;
}

.body_espacos #todos-espacos .espaco .col-2 .swiper-controls-custom .pagination {
  color: #fff;
}

.body_espacos #todos-espacos .espaco .col-2 .espaco-slider .img-wrapper {
  padding-top: 79.86%;
  position: relative;
  overflow: hidden;
}

.body_espacos #todos-espacos .espaco .col-2 .espaco-slider .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons {
  align-items: center;
  gap: 7px;
  display: flex;
  position: absolute;
  right: 1rem;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .button {
  background-color: var(--bienal-amarelo);
  border-radius: 50%;
  place-content: center;
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .button svg path {
  stroke: #000;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .button.swiper-button-disabled {
  background-color: #0000;
  border: 1px solid #fff;
}

.body_espacos #todos-espacos .espaco .col-2 .buttons .button.swiper-button-disabled svg path {
  stroke: #fff;
}

.body_espacos #todos-espacos .espaco.show {
  display: block;
}

.body_espacos #mosaico-info {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.body_espacos #mosaico-info .grid-mosaico {
  grid-template-columns: 3fr 1fr;
  display: grid;
}

@media screen and (max-width: 768px) {
  .body_espacos #mosaico-info .grid-mosaico {
    grid-template-columns: 1fr;
  }
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item {
  display: flex;
}

@media screen and (max-width: 768px) {
  .body_espacos #mosaico-info .grid-mosaico .mosaico-item {
    display: block;
  }
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item .text-wrapper {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #fff;
  background-color: #a3238e;
  place-content: center;
  padding: 2rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 106%;
  display: grid;
}

@media screen and (max-width: 1200px) {
  .body_espacos #mosaico-info .grid-mosaico .mosaico-item .text-wrapper {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 1024px) {
  .body_espacos #mosaico-info .grid-mosaico .mosaico-item .text-wrapper {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .body_espacos #mosaico-info .grid-mosaico .mosaico-item .text-wrapper {
    min-height: 200px;
  }
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item:first-child .img-wrapper {
  flex: 0 0 66%;
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item:nth-child(3) .img-wrapper {
  flex: 0 0 57%;
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item:nth-child(3) .text-wrapper {
  background-color: #db761d;
}

.body_espacos #mosaico-info .grid-mosaico .mosaico-item:nth-child(4) .text-wrapper {
  background-color: #369aa3;
}

.body_servicos #base-banner .detail-bottom {
  background-color: #f58220;
}

.body_servicos #categorias {
  background: #fff;
  border-bottom: 1px solid #0f0f121a;
}

.body_servicos #categorias .container {
  overflow: hidden;
}

.body_servicos #categorias .swiper-slide {
  flex: 25%;
  min-width: max-content;
}

.body_servicos #categorias .tab {
  text-align: center;
}

.body_servicos #categorias .tab span {
  border-radius: 20px;
  padding: .4375rem 1.25rem;
  transition: all .2s;
}

.body_servicos #categorias .tab.active span {
  color: #000;
  background: #d6df2133;
}

.body_servicos #categorias .tab:hover span {
  color: #000;
  background: #d6df2133;
  border-radius: 20px;
  padding: .4375rem 1.25rem;
}

.body_servicos #categorias .tab a {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f1266;
  padding: 1.1875rem 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.4;
  display: block;
}

.body_servicos #todos-espacos {
  margin-bottom: 7.5rem;
}

@media screen and (max-width: 768px) {
  .body_servicos #todos-espacos {
    margin-bottom: 4rem;
  }
}

@media (min-width: 1024px) {
  .body_servicos #todos-espacos .container .espaco:nth-child(2n) .grid-espaco .col-2 {
    grid-area: 1 / 1;
  }
}

.body_servicos #todos-espacos .espaco {
  margin-top: 6rem;
  scroll-margin-top: 140px;
}

@media screen and (max-width: 768px) {
  .body_servicos #todos-espacos .espaco {
    margin-top: 1.5rem;
  }
}

.body_servicos #todos-espacos .espaco .grid-espaco {
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  gap: 1.5rem;
  display: grid;
}

@media screen and (max-width: 1024px) {
  .body_servicos #todos-espacos .espaco .grid-espaco {
    grid-template-columns: 1fr;
  }
}

.body_servicos #todos-espacos .espaco .col-1 {
  background-color: #f3f3f5;
  align-self: stretch;
  padding: 5rem;
}

@media screen and (max-width: 1440px) {
  .body_servicos #todos-espacos .espaco .col-1 {
    padding: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .body_servicos #todos-espacos .espaco .col-1 {
    padding: 1.5rem;
  }
}

.body_servicos #todos-espacos .espaco .col-1 .espaco-title {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 2rem;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 800;
  line-height: 114%;
}

@media screen and (max-width: 1024px) {
  .body_servicos #todos-espacos .espaco .col-1 .espaco-title {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
  }
}

.body_servicos #todos-espacos .espaco .col-1 .content {
  letter-spacing: -.02em;
  color: #36363b;
  margin-bottom: 2rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 144%;
}

.body_servicos #todos-espacos .espaco .col-1 .content p {
  margin-bottom: 1rem;
}

.body_servicos #todos-espacos .espaco .col-1 .curadores, .body_servicos #todos-espacos .espaco .col-1 .curadores .swiper-curadores {
  width: 100%;
  display: grid;
}

.body_servicos #todos-espacos .espaco .col-1 .curadores .title-curador {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #0f0f12;
  margin-bottom: .75rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 144%;
}

.body_servicos #todos-espacos .espaco .col-1 .curadores .curador-item .img-wrapper {
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.body_servicos #todos-espacos .espaco .col-1 .curadores .curador-item .img-wrapper img {
  position: absolute;
  inset: 0;
}

.body_servicos #todos-espacos .espaco .col-1 .curadores .curador-item .curador-nome {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #a3238e;
  margin-top: .5rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 150%;
}

.body_servicos #todos-espacos .espaco .col-2, .body_servicos #todos-espacos .espaco .col-2 .swiper-espaco {
  width: 100%;
  display: grid;
}

.body_servicos #todos-espacos .espaco .col-2 .swiper-espaco .swiper-wrapper {
  position: unset;
}

.body_servicos #todos-espacos .espaco .col-2 .swiper-espaco .swiper-slide {
  height: auto;
}

.body_servicos #todos-espacos .espaco .col-2 .swiper-controls-custom {
  background-color: #a3238e;
  align-items: center;
  padding: 1rem 1.5rem;
  display: flex;
}

.body_servicos #todos-espacos .espaco .col-2 .swiper-controls-custom .pagination {
  color: #fff;
}

.body_servicos #todos-espacos .espaco .col-2 .espaco-slider .img-wrapper {
  padding-top: 79.86%;
  position: relative;
  overflow: hidden;
}

.body_servicos #todos-espacos .espaco .col-2 .espaco-slider .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.body_servicos #todos-espacos .espaco .col-2 .buttons {
  align-items: center;
  gap: 7px;
  display: flex;
  position: absolute;
  right: 1rem;
}

.body_servicos #todos-espacos .espaco .col-2 .buttons .button {
  background-color: var(--bienal-amarelo);
  border-radius: 50%;
  place-content: center;
  width: 1.5rem;
  height: 1.5rem;
  display: grid;
}

.body_servicos #todos-espacos .espaco .col-2 .buttons .button svg path {
  stroke: #000;
}

.body_servicos #todos-espacos .espaco .col-2 .buttons .button.swiper-button-disabled {
  background-color: #0000;
  border: 1px solid #fff;
}

.body_servicos #todos-espacos .espaco .col-2 .buttons .button.swiper-button-disabled svg path {
  stroke: #fff;
}

.body_servicos #todos-espacos .espaco .col-2 .buttons .swiper-button-lock {
  display: none;
}

.body_expositores #palestrantes {
  padding: 6rem 0;
}

.body_expositores #palestrantes .grid-palestrantes {
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  display: grid;
}

@media (max-width: 1440px) {
  .body_expositores #palestrantes .grid-palestrantes {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .body_expositores #palestrantes .grid-palestrantes {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .body_expositores #palestrantes .grid-palestrantes {
    grid-template-columns: 1fr;
  }
}

.body_expositores .pagination .page-list {
  justify-content: center;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.body_expositores .pagination .page-list a {
  border: 1px solid #0f0f121a;
  border-radius: 3px;
  padding: .375rem .875rem;
  display: block;
}

.body_expositores .pagination li {
  letter-spacing: -.02em;
  text-transform: uppercase;
  color: #000;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.body_expositores .pagination li.current {
  background-color: #000;
}

.body_expositores .pagination li.current a {
  color: #fff;
}

.body_expositores .pagination .on-edges {
  text-align: center;
  letter-spacing: -.02em;
  text-transform: uppercase;
  background-color: var(--bienal-amarelo);
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.body_expositores .pagination .on-edges.left svg {
  align-self: flex-start;
  margin-right: 8px;
  transform: rotate(180deg);
}

.body_expositores .pagination .on-edges.right svg {
  align-self: flex-start;
  margin-left: 8px;
}

.body_expositores .pagination .on-edges a {
  align-items: center;
  display: flex;
}

.body_expositores .pagination .on-edges svg {
  width: 20px;
  height: 20px;
}

.dot-contato {
  background-color: #a3238e;
  border-radius: 5px;
  width: 8px;
  height: 8px;
  margin-right: 2px;
  display: inline-block;
}

.body-coming-soon #footer-bienal {
  display: none;
}

.menu-space-top {
  margin-top: 83px;
}

@media (max-width: 1520px) {
  .menu-space-top {
    margin-top: 121px;
  }
}

@media (max-width: 1024px) {
  .menu-space-top {
    margin-top: 77px;
  }
}

.button-wrapper.floating-button {
  display: none;
}

.card-palestrante-wrapper .bottom-infos .role.text-lower {
  text-transform: initial;
}

.card-palestrante-wrapper .card-palestrante .infos-wrapper {
  display: none;
}

#inovacao {
  padding-top: 5rem;
  scroll-margin-top: 120px;
}

.turismo-agencia span {
  font-weight: 600;
}

.turismo-agencia a {
  color: #3b4042;
}

#gt_float_wrapper {
  position: static !important;
}

body .gt_float_switcher img {
  width: 16px;
}

body .gt_float_switcher {
  font-size: 14px;
}

body .gt_float_switcher .gt-selected .gt-current-lang span.gt_float_switcher-arrow {
  width: 13px;
  height: 22px;
}

body .gt_float_switcher .gt-selected .gt-current-lang {
  padding: 4px 11px;
}

body .flags-mobile {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  body .flags-mobile {
    margin-top: 1rem;
    margin-left: 1rem;
    display: block !important;
  }
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc, .VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none !important;
}

.glink.nturl.notranslate img {
  opacity: .5;
}

.glink.nturl.notranslate.gt-current-lang img {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .body_home #evento .grid-wrapper .section-title h2 {
    margin-bottom: 0;
  }
}

.scale-1-1 img {
  transform-origin: 100%;
  transform: scale(1.1);
}

.descricao-new {
  margin-top: 1rem;
}

.richText-info {
  letter-spacing: -.18px;
  color: #1b1d21;
  font-size: 1.125rem;
  line-height: 1.6;
}

.richText-info .ql-align-center {
  text-align: center;
}

.swiper-parceiros .swiper-wrapper, .swiper-parceiros2 .swiper-wrapper, .swiper-parceiros3 .swiper-wrapper {
  transition-timing-function: linear;
}

.swiper-parceiros .swiper-wrapper .swiper-slide, .swiper-parceiros2 .swiper-wrapper .swiper-slide, .swiper-parceiros3 .swiper-wrapper .swiper-slide {
  height: auto;
}

/*# sourceMappingURL=style.css.map */
