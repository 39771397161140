#login{
    --hue-primary: 214;
    --white-10-op: hsla(0, 0%, 100%, 0.1);
    --white: #ffffff;
    --neutral-10: #D7DADB;
    --primary-light: #1E66C3;
    --primary-light-10-op: hsla(214, 73%, 44%, 0.1);
    --white-70-op: hsla(0, 0%, 100%, 0.7);
    --primary-pure: hsla(var(--hue-primary), 85%, 34%, 1);

    height: 100vh;
    position: relative;
    background: linear-gradient(90deg, hsla(var(--hue-primary), 100%, 3%, 1) 0%, hsla(var(--hue-primary), 100%, 3%, 1) 100%);
    padding-top: 3.75rem;
    overflow: hidden;
    height: 100vh;
    min-height: 900px;

    .ornamento{
        max-width: 1800px;
        max-height: 394px;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        background: linear-gradient(92.74deg, hsla(var(--hue-primary), 68%, 32%, 1) 0%, hsla(var(--hue-primary), 100%, 73%, 1) 100%);
        border-radius: 11.875rem;
        @media (max-width: 1660px){
            padding: 3rem;
            width: 90%;
        }
        @include d(lg){
            height: 40%;
        }
        @include d(sm){
            width: 100%;
            height: 349px;
            margin: unset;
            border-radius: 0px 0px 16px 16px;
        }

        .logo-cliente{
            width: fit-content;
            margin: 0 auto;
            padding-top: 4.6875rem;
            @media (max-width: 1660px){
                padding-top: 2.75rem;
            }
            @include d(lg){
                padding-top: 2rem;
            }
        }
    }

    .orn-sections{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .logo-ndt{
        bottom: 72px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        @include d(lg){
            bottom: 64px;
        }
    }
    .form-wrapper{
        max-width: 428px;
        width: 100%;
        height: fit-content;
        padding: 2rem;
        background: rgba(0, 0, 0, 0.5);
        border: 1px solid var(--white-10-op);
        backdrop-filter: blur(40px);
        border-radius: 8px;
        margin: 0 auto;
        margin-top: -210px;
        @include d(lg){
            margin-top: -150px;
        }
        @include d(sm){
            width: 327px;
        }

        .content{
            .title{
                font-weight: 400;
                font-size: 1.5rem;
                line-height: 133%;
                letter-spacing: -0.02em;
                color: var(--white);
                margin-bottom: .5rem;
            }
            .description{
                font-weight: 300;
                font-size: 1rem;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: var(--white-70-op);
            }
        }
        .inputs-wrapper{
            margin-top: 2rem;
            .input-item{
                &:first-child{
                    margin-bottom: 1.5rem;
                }
                .input{
                    font-family: $font-1;
                    width: 100%;
                    height: 56px;
                    padding: .5rem;
                    border: unset;
                    background: transparent;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    font-weight: 300;
                    font-size: 1rem;
                    line-height: 150%;
                    letter-spacing: -0.02em;
                    color: var(--white);

                    @include d(sm){
                        font-size: .875rem;
                        line-height: 171%;
                    }
    
                    &::placeholder{
                        font-family: $font-1;
                        font-weight: 300;
                        font-size: 1rem;
                        line-height: 150%;
                        letter-spacing: -0.02em;
                        color: var(--white);

                        @include d(sm){
                            font-size: .875rem;
                            line-height: 171%;
                        }
                    }
                }
                .show-password{
                    display: flex;
                    position: relative;
                    .password{
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        right: .5rem;
                        top: 50%;
                        transform: translateY(-50%);
                        svg{
                            width: 24px;
                            height: 24px;
                        }
                        .hidden{
                            display: none;
                        }
                        &.mostra{
                            .hidden{
                                display: block;
                            }
                            .show{
                                display: none;
                            }
                        }
                    }
                }
            }
            .recuperar-senha{
                margin-top: .5rem;
                a{
                    font-weight: 300;
                    font-size: .75rem;
                    line-height: 167%;
                    letter-spacing: -0.02em;
                    color: var(--white-70-op);
                    text-decoration: none;
                    &:hover{
                        color: var(--white);
                        text-decoration: underline;
                    }
                }
            }
        }
        // .checkbox-wrapper{
        //     margin-top: 1.5rem;
        //     .check-label{
        //         font-weight: 300;
        //         font-size: .875rem;
        //         line-height: 157%;
        //         letter-spacing: -0.02em;
        //         color: var(--generic-white-70-op);
        //         display: flex;
        //         align-items: center;
        //         gap: .375rem;
        //         input{
        //             width: 20px;
        //             height: 20px;
        //             border: 1px solid rgba(255, 255, 255, 0.2);
        //             border-radius: .1875rem;
        //             background: transparent;
        //         }
        //     }
        // }
        .btn-wrapper{
            margin-top: 3.5rem;
            .btn{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 56px;
                font-weight: 400;
                font-size: 1rem;
                line-height: 150%;
                letter-spacing: -0.02em;
                background-color: var(--primary-light-10-op);
                border: 1px solid var(--primary-light);
                border-radius: 1.75rem;
                color: var(--primary-light);
                cursor: pointer;
                transition: background-color .2s ease-in, transform .1s ease-in;
                @include d(sm){
                    font-size: .875rem;
                    padding: .5rem 2rem;
                }
                &:hover{
                    box-shadow: 0 0.5em 0.5em -0.4em var(--primary-pure);
                    transform: translateY(-0.25em) !important;
                    color: var(--neutral-10);
                    border-color: var(--primary-pure);
                }
            }
        }
        .voltar{
            width: fit-content;
            margin: 0 auto;
            margin-top: 1rem;
            a{
                font-weight: 300;
                font-size: .875rem;
                line-height: 157%;
                letter-spacing: -0.02em;
                color: var(--white-70-op);
                &:hover{
                    color: var(--white);
                    text-decoration: underline;
                }
            }
        }
        .checkbox-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1.5rem;
            .radio-container {
                display: flex;
                align-items: center;
                .check-label {
                    display: inline-flex;
                    align-items: center;
                    gap: .375rem;
                    cursor: pointer;
                    font-weight: 300;
                    font-size: .875rem;
                    line-height: 150%;
                    user-select: none;
                    letter-spacing: -0.02em;
                    color: var(--white-70-op);
                    .checkbox{
                        position: absolute;
                        width: 1px;
                        height: 1px;
                        padding: 0px;
                        margin: -1px;
                        overflow: hidden;
                        clip: rect(0px, 0px, 0px, 0px);
                        white-space: nowrap;
                        border-width: 0px;
                    }
                    &:hover{
                        color: var(--white);
                        font-weight: 400;
                        .radio-circle {
                            border-color: var(--primary-pure);
                            border-width: 2px;
                        }
                    }
                }
                .radio-circle {
                    display: inline-flex;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 1px solid rgba(255, 255, 255, 0.2);
                    position: relative;
                    flex-shrink: 0;
                }
                .radio-circle-inner {
                    position: absolute;
                    background: var(--primary-pure);
                    width: 0px;
                    height: 0px;
                    border-radius: 50%;
                    inset: 0;
                    margin: auto;
                    flex-shrink: 0;
                    transition: 0.2s ease-out;
                    transition-property: width height;
                }
                input:checked {
                    ~ span {
                        border-color: var(--primary-pure);
                        i {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            
                // &.size-16 {
                //     .check-label {
                //         font-size: .875rem;
                //         line-height: 100%;
                //         color: var(--generic-white);
                //     }
                //     .radio-circle {
                //         width: 16px;
                //         height: 16px;
                //     }
                //     input:checked {
                //         ~ span {
                //             i {
                //                 width: 8px;
                //                 height: 8px;
                //             }
                //         }
                //     }
                // }
            
                // &.size-24 {
                //     .radio-circle {
                //         width: 24px;
                //         height: 24px;
                //     }
                //     input:checked {
                //         ~ span {
                //             i {
                //                 width: 16px;
                //                 height: 16px;
                //             }
                //         }
                //     }
                // }
                &.round {
                    .radio-circle {
                        border-radius: .25rem;
                        &-inner {
                            border-radius: initial !important;
                            transition: initial;
                        }
                    }
            
                    input:checked {
                        ~ span {
                            i {
                                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxOSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDJMNy42MjY4MSAxMkwyIDUuOTk2OTIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
                                background-size: contain;
                                background-repeat: no-repeat;
                                width: 100%;
                                height: auto;
                                background-position: 100%;
                                border-radius: initial;
                            }
                        }
                    }
                }
                &.disabled {
                    user-select: none;
                    .check-label {
                        pointer-events: none;
                        color: var(--neutral-60);
                    }
                    .radio-circle {
                        pointer-events: none;
                        opacity: 0.2;
                    }
                }
            }
        }
    }
}